.contact-modal {
  border-radius: 3px;
  border: 3px solid rgba($blue, .5);
  padding: 0;
  background: #fff;
  background-size: 500px;
  
  .srv-validation-message {
    margin-top: -.5rem;
  }

  .icon {
    i {
      color: $blue !important;
    }
  }

  .btn-confirm {
    background: $blue !important;

    &:hover {
      background: darken($blue, 20%) !important;
    }
  }

  p {
    font-size: .9rem;
    line-height: 1.4rem;
  }

  .inner {
    padding: 1rem;
    background: rgb(255, 255, 255);
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 1) 44%,
      rgba(255, 255, 255, 0) 100%
    );

    display: flex;
    flex-direction: row;

    .icon {
      margin-right: 1rem;

      @include max-width(320px) {
        display: none;
      }
      
      i {
        color: $green;
        font-size: 2rem;
      }
    }

    .content {
      flex: 1;  
    }
  }

  .input-fields {
    .form-control {
      margin-bottom: .75rem;
    }
  }

  h3 {
    color: darken($blue, 10%);
    font-weight: 600;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-bottom: 0.3rem;
    margin-bottom: 1rem;
  }

  p {
    color: black;
    font-size: 1rem;
    line-height: 1.2rem;
    margin-bottom: 1rem;
  }

  .buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .btn {
      text-transform: none;
      border-radius: 3px;
      border: none;
      color: grey;
      display: flex;
      width: 120px;
      position: relative;
      align-items: center;
      justify-content: center;

      svg {
        width: 1rem;
        height: 1rem;
        margin-right: .5rem;
      }

      &.btn-default {
        background: rgba(0,0,0,.1);
      }

      &.btn-confirm {
        background-color: $green;
        color: white;

        &:hover {
          background-color: darken($green, 10%);
        }
      }
    }
  }
}