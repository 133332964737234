.cms-single-page {
  margin-top: 2rem;

  .site-content {
    padding: .5rem;
  }

  header {
    background: none;
  }

  h1 {
    &.post-title {
      text-transform: none;
      font-weight: 600;
      font-size: 2rem;
      margin-top: 0;
      margin-bottom: 1rem;
      text-align: left;
    }
  }

  article {
    background-color: #fff;
    border-radius: 3px;
    padding: 1rem;

    .content {
      display: flex;

      @include max-width(500px) {
        flex-direction: column;
      }

      .post-thumbnail {
        margin-right: 1rem !important;

        @include max-width(500px) {
          margin-right: 0 !important;
          margin-bottom: 1rem;
        }

        img {
          width: 200px;

          @include max-width(500px) {
            width: 100%;
          }
        }
      }
    }
    
    .post-subtitle {
      font-size: 1.2rem;
      color: #000;
      margin-bottom: 1rem;
    }

    p {
      line-height: 1.6;
    }
  }

  .faq-block {
    padding: 0;

    h2 {
      text-transform: none;
      font-weight: 600;
      font-size: 2rem;
      margin-top: 0;
      color: #227fc0;
      margin-bottom: 1rem;
      text-align: left;
    }

    p {
      line-height: 1.6;
    }

    .accordion-header {
      margin-top: 0;
    }

    .accordion-panel {
      margin-bottom: .7rem;
    }

    .faq-accordion {
      .accordion-icon {
        display: none;
      }
    }
  }
}