.map-with-controls {
  display: flex;

  .ais-InstantSearch__root {
    display: flex;

    @include max-width($md) {
      flex-direction: column;
    }
  }
  .ais-GeoSearch {
    height: 500px;
    position: relative;

    @include max-width($md) {
      height: 350px;
    }
  
    .ais-GeoSearch-map {
      height: 100%;
    }

    .ais-GeoSearch-control {
      position: absolute;
      bottom: 0;
      left: 0;
      background: #fff;
      padding: .7rem;
      box-shadow: 0 0 7px -2px rgba(0, 0, 0, 0.4);

      .ais-GeoSearch-redo {
        background-color: $green !important;
        border: none;
        color: white;
        outline: none;
        padding: .3rem 1rem;
        font-weight: 600;
        border-radius: 5px;
      }

      .ais-GeoSearch-label {
        margin-bottom: 0;

        input {
          margin-right: .7rem;
        }
      }
    }
  }

  .controls-container {
    margin-right: 1rem;
    width: 400px;
    max-width: 100%;
    display: flex;
    flex-direction: column;

    @include max-width($md) {
      margin-bottom: .5rem;
    }

   .searchbox-container {
     position: relative;
     margin-bottom: 2rem;

     .info {
       color: rgba(#000, .3);
       margin-top: .2rem;
       font-size: .8rem;
     }

    .ais-SearchBox {
      .ais-SearchBox-form {
        button {
          &::after {
            display: inline-block;
            font-style: normal;
            font-variant: normal;
            text-rendering: auto;
            -webkit-font-smoothing: antialiased;
            font-weight: 400;
            font-family: "Font Awesome 5 Pro";
          }
        }

        .ais-SearchBox-submit {
          background-color: #c7c7c7;

          &::after {
            content: "\f002";
          }

          @include max-width($md) {
            display: none
          }
        }
  
        .ais-SearchBox-reset {
          position: absolute;
          right: 4.5rem;
          width: 2rem;
          padding: 0;
          background: none;

          @include max-width($md) {
            right: .5rem;
          }

          &::after {
            content: "\f057";
            color: $grey5;
          }

          &:hover {
            background-color: rgba(0, 0, 0, .2);
          }
        }

        svg {
          display: none;
        }
      }
    }
   }

    .item {
      margin-bottom: 1rem;
      width: 100%;
      position: relative;
    
      .loading-container {
        position: absolute;
        border-radius: 5px;
        z-index: 999;
        background: rgba(255,255,255, .5);
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        i {
          color: $green;
        }
      }

      &:last-child {
        margin-bottom: 0;
        flex: 1;
      }

      h4 {
        font-size: 1.0rem;
        line-height: 1.2rem;
      }
    }

    .ais-SearchBox {
      .ais-SearchBox-form {
        display: flex;
  
        input {
          flex: 1;
          padding-right: 10px;
          padding-left: 16px;
          font: inherit;
          height: 40px;
          border: none;
          background-color: #fff;
          border-radius: 3px;
          outline: none;
          margin-right: .5rem;
          box-shadow: 0 0 7px -2px rgba(0, 0, 0, 0.4);
        }
  
        button {
          width: 3rem;
          height: 40px;
          color: #fff;
          border: none;
          border-radius: 3px;
          display: flex;
          align-items: center;
          justify-content: center;
  
          svg {
            width: 2rem;
          }
        }
      }
    }
  }

  .map-container {
    flex: 1;
  }

  .search-results-item {
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 7px -2px rgba(0, 0, 0, 0.1);
    padding: .7rem;
    display: flex;
    align-items: center;
    transition: .2s;

    &:hover {
      cursor: pointer;
      box-shadow: 0 0 7px -2px rgba(0, 0, 0, 0.4);
      //transform: scale(1.02);

      h6 {
        color: $primary;

        @include themify($themes) {
          color: themed('colorPrimary');
        }
      }

      p {
        color: black;
      }
    }

    .image-container {
      margin-right: 1rem;
      height: 50px;
      display: flex;
      align-items: center;
      overflow: hidden;

      img {
        width: 90px;
        height: auto;
      }

      i {
        text-align: center;
        width: 90px;
        font-size: 3rem;
        color: rgba(0,0,0,.2);
      }
    }

    .text-content {
      flex: 1;
      display: flex;
      flex-direction: column;


      h6 {
        font-size: 1rem;
        font-weight: 600;
        margin-top: 0;
        margin-bottom: .2rem;
      }

      p {
        margin-bottom: .1rem;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .ais-MultiIndex__root {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  .ais-Hits {
    max-height: 420px;
    margin-bottom: .5rem;
    overflow-y: auto;

    .ais-Hits-list {
      padding-bottom: 100px;
      padding-left: 0;

      .ais-Hits-item {
        width: 100%;
        display: block;
        margin-bottom: .2rem;
      }
    }
  }

  .ais-Pagination {
    position: absolute;
    bottom: 0;
    width: 100%;
  }

  .gradient {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100px;
    background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(243,243,243,.9) 48%);
    //  border-bottom: 1px solid #c7c7c7;
  }
}

.custom-marker {
  position: relative;
  
  &.bounced {
    transform: scale(1.3);
  }

  &:hover {
    transition: .2s;
    transform: scale(1.1);
  }

  i {
    color: #E04A3A;

    &.generic-icon {
      color: rgba(0, 0, 0, .2);
      font-size: 2rem;
      margin-right: .4rem;
    }
  }

  .marker-details {
    display: none;
    position: absolute;
    z-index: 9999;
    left: 25px;
    bottom: -16%;
    overflow: hidden;
    flex-direction: row;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 7px -2px rgba(0, 0, 0, 0.1);
    align-items: center;
    justify-content: left;
    padding: .5rem;
    opacity: 0;
    transition: .2s;

    h6 {
      font-weight: 600;
      margin-bottom: .2rem;
      margin-top: 0;
    }

    p {
      margin-bottom: .3rem;
      font-size: .8rem;

      &:last-child {
        margin-bottom: 0
      }

      &.cta {
        background-color: rgba(0,0,0,.1);
        display: inline-block;
        border-radius: 5rem;
        padding: .2rem 1rem;
      }
    }

    img {
      width: 70px;
      margin-right: .4rem;
    }

    &:hover {
      cursor: pointer;
      box-shadow: 0 0 7px -2px rgba(0, 0, 0, 0.4);
      transform: scale(1.02);

      h6 {
        color: $green;
      }
    }
  }

  &:hover {
    cursor: pointer;

    .marker-details {
      display: flex;
      opacity: 1;

    }
  }
}