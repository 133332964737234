.aaa-card {
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 0 10px 1px rgba(0,0,0,0.2);

  .header {
    display: flex;
    justify-content: space-between;

    .logo-container {
      display: flex;
      align-items: center;

      img {
        width: 130px;
        margin-left: 10px;

        @include max-width($md) {
          width: 90px;
        }
      }
    }

    .contact-info {
      background-color: #00529B;
      color: white;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 1rem;

      @include max-width($md) {
        padding: .5rem;
      }

      h6 {
        color: white;
        font-size: .80rem;
        margin-bottom: 0;

        @include max-width($md) {
          display: none;
        }
      }

      p {
        color: white;
        margin-bottom: 0;
        font-size: .9rem;
        font-weight: 600;

        @include max-width($md) {
          font-size: .8rem;
          font-weight: 400;
        }
      }
    }
  }

  .body {
    padding: 1rem;
    display: flex;
    justify-content: flex-end;

    .forms-container {
      flex: 1;

      .form-row {
        margin-bottom: .5rem;
        input {
          display: block;
          width: 100%;
          padding: 0.2rem 0;
          font-size: 1rem;
          line-height: 1.5;
          color: #00529b;
          background-color: #fff;
          background-clip: padding-box;
          border: none;
          border-bottom: 2px solid rgba(#00529b, .2);
          border-radius: 0;
          transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
          font-weight: 600;
          text-transform: uppercase;

          @include placeholder {
            font-size: .85rem;
            text-transform: uppercase;
          }

          &:focus {
            outline: 0;
            border-bottom: 2px solid rgba(#00529b, .4);
            box-shadow: none;
          }
        }

        .control-label {
          font-size: .7rem;
          text-transform: uppercase;
          color: #00539bd5;
          margin-bottom: 0;
        }
      }
    }

    .rewards-logo {
      width: 70px;
      display: flex;
      align-items: flex-end;
      margin-left: 20px;

      @include max-width($md) {
        display: none;
      }
    }
  }
}