.update-order {
  background-color: rgb(236, 236, 236);
  padding: .5rem 1rem;
  border: 2px solid rgb(236, 236, 236);

  &.open {
    border: 2px dashed orange;

    .form-container {
      display: block;
    }
  }

  .header.btn {
    background: none;
    color: #000;
    margin-bottom: 0;
    text-transform: uppercase;
    font-size: 1rem;
    padding: 0;

    i {

    }
  }

  input {

  }

  .form-container {
    border-top: 1px solid rgb(201, 201, 201);
    padding-top: .5rem;
    margin-top: .5rem;
    display: none;

    input, textarea {
      border-radius: 2px;
      border-color: #dcdcdc;
    }
    
    .btn {
      width: 200px;
      border-radius: 2px;
      background-color: rgb(43, 43, 43);
      border: none;
    }
  }
}