/**
 * Custom Icons
 * ----------------------------------------------------------------------------
 */
.icon-menu,
.icon-close {
  background: currentColor;
  border-radius: 1px;
  color: inherit;
  height: 2px;
  margin-top: -1px;
  margin-left: -12px;
  position: absolute;
  right: 0;
  top: 50%;
  width: 30px;

  &:before,
  &:after {
    background: currentColor;
    border-radius: 1px;
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    width: 100%;
  }
}

.icon-menu:before {
  top: -8px;
}

.icon-menu:after {
  bottom: -8px;
}

.icon-close {
  background: 0;
  margin-left: -17px;
  width: 34px;

  &:before {
    top: 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  &:after {
    top: 0;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
}

.icon-arrow-left,
.icon-arrow-right {
  background: currentColor;
  color: inherit;
  height: 2px;
  left: 50%;
  margin-left: -7px;
  margin-top: -1px;
  position: absolute;
  top: 50%;
  width: 14px;

  &:before {
    content: "";
    height: 4px;
    position: absolute;
    width: 4px;
  }
}

.icon-arrow-left:before {
  border-left: 2px solid currentColor;
  border-top: 2px solid currentColor;
  left: -1px;
  top: 1px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transform-origin: left top;
  transform-origin: left top;
}

.icon-arrow-right:before {
  border-right: 2px solid currentColor;
  border-top: 2px solid currentColor;
  right: -1px;
  top: 1px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transform-origin: right top;
  transform-origin: right top;
}

.icon-plus {
  background: _palette(accent);
  border-radius: 50%;
  color: #fff;
  height: 24px;
  position: relative;
  width: 24px;

  &:before,
  &:after {
    background: currentColor;
    border-radius: 1px;
    content: "";
    height: 2px;
    left: 50%;
    margin-top: -1px;
    margin-left: -8px;
    position: absolute;
    top: 50%;
    width: 16px;
  }

  &:after {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }
}
