.styles_modal__gNwvD {
}

.location-permission-modal {
  background-color: #fff;
  border-radius: 3px;
  border: 3px solid #64afe4;
  padding: 0;
  overflow-y: visible !important;
  width: 500px;
  max-width: 90% !important;
  background-image: url('../../static/images/location-preview.jpg') !important;
  background-size: 100% auto !important;

  .inner {
    background: rgb(255, 255, 255);
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 1) 44%,
      rgba(255, 255, 255, 0) 100%
    );
  }

  .close-btn {
    display: none;
  }

  .place-selection {
    &.extended {
      input {
        width: 100% !important;
      }
    }
  }

  h3 {
    color: darken(#64afe4, 10%);
    font-weight: 600;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-bottom: 0.3rem;
    margin-bottom: 1rem;
  }

  p {
    color: black;
    font-size: 1rem;
    line-height: 1.2rem;
    margin-bottom: 1rem;
  }

  .buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .btn {
      width: 200px;
      text-transform: none;
      border-radius: 3px;
      border: none;
      color: grey;
      display: flex;
      align-items: center;
      font-size: .85rem;

      i {
        margin-right: .5rem;
      }

      &.btn-success {
        background-color: $green !important;
        color: white !important;

        &:hover {
          background-color: darken($green, 10%) !important;
        }
      }
    }
  }
}
