.business-registration-form {
  border-radius: 3px;
  padding: 1rem;
  box-shadow: 0 0 7px -2px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  border: 3px solid rgba(#237fc0, .7);
  
  .intro {
    p {
      font-size: .99rem;
      color: #424242;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .form {
    padding-top: 1rem;

    @include max-width($sm) {
      .mt-3 {
        margin-top: 0 !important;
      }

      .css-2b097c-container, .form-control {
        margin-bottom: 1rem;
      }
      

      small {
        display: block;
        margin-bottom: 1rem;
      }
    }
    
    .css-yk16xz-control {
      border-radius: 3px;
    }

    .css-1hwfws3 {
      .css-1rhbuit-multiValue {
        &:first-child {
          background: none;
          font-weight: 600;
          text-transform: uppercase;

          svg {
            display: none;
          }

          &>div {
            padding-left: 0;
          }
        }
      }
    }

    h3 {
      font-size: 1.15rem;
      color: rgb(56, 56, 56);
      margin-bottom: 0;
    }

    input {
      border-radius: 3px;
    }

    label {
      display: none;
    }

    .group {
      border-top: 1px solid rgb(216, 216, 216);
      padding: 1.5rem 0;
    }

    .btn {
      background-color: $green;
      color: white;
      text-transform: uppercase;
      border-radius: 3px;
      width: 200px;

      @include themify($themes) {
        background-color: themed('buttonBackgroundColor');
        color: themed('buttonColor');
      }

      &:hover {
        background: darken($green, 10%);

        @include themify($themes) {
          background-color: darken(themed('buttonBackgroundColor'), 10%);
          color: themed('buttonColor');
        }
      }
    }

    .validator-message-container {
      .srv-validation-message {
        display: block;
        margin-top: .5rem;
        background-color: #ffedef;
        padding: .2rem .5rem;
        border-color: #f5c6cb;
        border: none;
        border-radius: .25rem;
        margin-top: .5rem;
        margin-bottom: .5rem;
        border-bottom: 2px solid #ce868e;
        border-radius: 0 !important;

        @include max-width($sm) {
          margin-top: -.5rem;
        }
      }
    }
  }
}