/**
 * Structure
 * ----------------------------------------------------------------------------
 */
.outer {
  padding: 4.16667rem 4vw 3.33333rem;
}

.block {
  .inner {
    margin-left: auto;
    margin-right: auto;
    max-width: 1140px;
  }
  
  .inner-large {
    margin-left: auto;
    margin-right: auto;
    max-width: 945px;
  }
  
  .inner-medium {
    margin-left: auto;
    margin-right: auto;
    max-width: 750px;
  }
  
  .inner-small {
    margin-left: auto;
    margin-right: auto;
    max-width: 555px;
  }
}
