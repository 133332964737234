.bio {
  display: flex;
  margin-bottom: 1rem;

  @include max-width($lg) {
    flex-direction: column;
  }

  .item {
    padding: 1rem;
  }

  .bio-details {
    display: flex;
    background-color: #fff;
    margin-right: .5rem;
    border-radius: 5px;

    @include max-width($lg) {
      margin-right: 0;
      margin-bottom: 1rem;
    }

    h4 {
      font-size: 1.6rem;
      font-weight: 400;

      span {
        font-weight: 600;
      }
    }

    .thumb-container {

      @include max-width($md) {
        display: none !important;
      }

      img {
        border-radius: 5px;
        width: 200px;
      }
    }

    .text-container {
      padding-left: 1rem;

      @include max-width($md) {
        padding-left: 0;
      }

      blockquote {
        font-size: 1rem;
        border: none;
        padding: 0;
        background-color: #F3F3F3;
        padding: .5rem;
        display: flex;
        border-radius: 5px;

        i {
          font-size: 1.4rem;
          margin-right: .5rem;

          @include max-width($md) {
            display: none;
          }
        }

        p {
          line-height: 1.3rem;

          img {
            float: left;
            width: 100px;
            padding-right: .5rem;
            display: none !important;

            @include max-width($md) {
              display: block !important;
            }
          }
        }
      }

      p {
        font-size: 1rem;
        color: #000;
        line-height: 1.3rem;



        &:last-child {
          margin-bottom: 0;
        }
      }

      .disclaimer {
        text-transform: uppercase;
        font-size: .8rem;
      }
    }
  }

  .fitment-guarantee  {
    background-color: #fff;
    margin-left: .5rem;
    border-radius: 5px;
    display: flex;

    @include max-width($lg) {
      margin-left: 0;
    }

    .icon-container {
      i {
        font-size: 3rem;
        color: $green;
      }
    }

    .text-container {
      padding-left: 1rem;

      h4 {
        font-size: 1.6rem;
      }
  
      p {
        font-size: 1rem;
        color: #000;
        line-height: 1.3rem;
  
        &:last-child {
          margin-bottom: 0;
          color: grey;
        }
      }
    }
  }
}