.why-1800-battery {
  &.v2 {
    padding: 1rem 0 2rem 0;
    border: none;
    background: linear-gradient(273deg, rgba(233, 236, 239, 0) 0%, white 50%, rgba(255, 255, 255, 0) 96%);
    box-shadow: 0 0 7px -2px rgba(0, 0, 0, 0.1);
    padding: 1rem 1rem .5rem 1rem;
    margin-bottom: 2rem;

    .row {
      @include max-width($md) {
        flex-direction: column;
        
        .col {
          flex-basis: auto;
        }
      }
    }
  
    h2 {
      text-align: center;
      color: #064A5E;
      font-size: 2rem;
      font-weight: 600;
      text-transform: none;
      margin-top: 1rem;
      margin-bottom: 1.5rem;

      @include max-width($md) {
        font-size: 1.5rem;
      }
    }
  
    .item {
      margin-bottom: 1rem;
      background-color: #fff;
      padding: .7rem;
      height: 100%;
      text-align: center;
      border-radius: 4px;
      box-shadow: 0 0 7px -2px rgba(0,0,0,.1);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      @include max-width($md) {
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
      }
  
      h5 {
        margin-top: .8rem;
        color: #064a5e;
        font-size: 1rem;

        @include max-width($md) {
          text-align: left;
          margin: 0;
          flex: 1;
        }
      }
  
      p {
        margin-bottom: 0;
        font-size: .9em;
      }

      .icon-container {
        display: block;
        width: 100px;
      }
  
      img {
        width: 64px;
        opacity: .8;

        @include max-width($md) {
          margin-right: .75rem;
          width: 40px;
        }
      }
    }
  
    .divider {
      margin: .9rem 0;

      @include max-width($md) {
        display: none;
      }
    }
  }

  &.vertical {
    padding: .5rem 0;
    box-shadow: none;

    h3 {
      color: #000;
      font-size: 1.4rem;
      margin-bottom: 1.5rem;
    }

    .item {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      background: none;
      border-bottom: 2px solid #f3f3f3;
      border-radius: 0;
      padding: .3rem .7rem;
      padding-left: 0;
      box-shadow: none;

      i {
        color: $green;
        font-size: 1.2rem;
        margin-right: .7rem;
      }

      h5 {
        font-weight: 400;
        margin: 0;
        text-align: left;
      }
    }
  }
}

.old {
  background-color: rgb(255, 255, 255);
  padding: 1rem;
  display: flex;
  align-items: center;
  box-shadow: 0 0 7px -2px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  background: linear-gradient(270deg, rgba(255,255,255,1) 0%, rgba(207,255,135,0.1) 100%);
  border: 2px solid $green;

  @include max-width($sm) {
    align-items: flex-start;
  }

  .badge {
    margin-right: 1.5rem;
    padding-right: 1.5rem;
    border-right: 1px solid rgb(218, 218, 218);
    border-radius: 0;

    @include max-width($sm) {
     display: none;
    }

    i {
      font-size: 4rem;
      color: $green;
    }
  }

  .content {
    h4 {
      font-size: 1.7rem;
      font-weight: 600;
      margin-bottom: 1rem;
      color: black;
    }
  
    ul {
      display: flex;
      flex-wrap: wrap;
  
      li {
        width: 50%;
        display: flex;
        font-size: 1rem;
        align-items: center;
        color: black;
        
        @include max-width($lg) {
          width: 100%;
          margin-bottom: .5rem;
        }

        @include max-width($sm) {
          align-items: flex-start;

          &:last-child {
            margin-bottom: 0;
          }
        }
  
        i {
          font-size: 1rem;
          color: $green;
          margin-right: .5rem ;
        }
      }
    }
  }
}