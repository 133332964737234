section.provider-section {
  padding: 0;

  &.cover {
    background-size: 50% auto !important;
    background-repeat: no-repeat !important;
    background-position: center left !important;

    .cover-inner {
      background-size: 50% auto !important;
      background-repeat: no-repeat !important;
      background-position: center right !important;

      display: flex;
      align-items: center;
      justify-content: center;

      .shade {
        padding: 6rem 0 !important;
        background-color: rgba(#1E1F21, .5);
        width: 100%;
      }

      .content {
        text-align: center;
        width: 600px;
        margin: 0 auto;
        max-width: 100%;

        h2 {
          color: white;
          text-transform: none;
          margin-bottom: 1rem;
          font-size: 3rem;
        }

        p {
          line-height: 1.6rem;
          color: white;
          font-size: 1.5rem;
          margin-bottom: 1rem;
        }

        .btn {
          background-color: $green;
          color: white;
          border-radius: 100px;
          width: 200px;
        }

        .border-line {
          height: 2px;
          background-color: #494847; 
          width: 100%;
          margin-bottom: 1rem;
          width: 50%;
          margin: 0 auto 1rem auto;
        }
      }
    }
  }

  &.features {
    border-top: 2px solid rgba(245, 222, 179, 0.288);
    // background: #1E1F21;
    padding: 4rem 0;

    .header {
      text-align: left;

      h2 {
        font-size: 1.5rem;
      }
    }

    .features-inner {
      display: flex;

      @include max-width($md) {
        flex-direction: column;
      }

      .item {
        flex: 1;
        background-color: #fff;
        width: 32%;
        margin-right: 1%;
        text-align: center;

        @include max-width($md) {
          width: 100%;
          margin-bottom: 1rem;
        }
        
        .icon {
          img {
            width: 200px;
            height: 150px;
          }
        }
      }
    }
  }
}