/**
 * Palettes
 * ----------------------------------------------------------------------------
 */
.palette-purple {
  a {
    &:hover,
    &:focus {
      border-color: _palette(accent-purple);
      color: _palette(accent-purple);
    }
  }

  blockquote {
    border-color: _palette(accent-purple);
  }

  button,
  input[type="submit"],
  input[type="button"],
  input[type="reset"],
  a.button {
    background: _palette(accent-purple);
    border-color: _palette(accent-purple);
    color: #fff;
  }

  .button.secondary {
    background: transparent;
    color: _palette(accent-purple);

    &:hover,
    &:focus,
    &:active {
      background: _palette(accent-purple);
      color: #fff;
    }
  }

  .button.white {
    background: #fff;
    border-color: transparent;
    color: _palette(primary);
  }

  .underline {
    &:after {
      border-color: _palette(accent-purple);
    }
  }

  .icon-plus {
    background: _palette(accent-purple);
  }

  .review-text {
    &:before {
      color: _palette(accent-purple);
    }
  }

  .plan {
    &.highlight {
      .plan-inside {
        border-color: _palette(accent-purple);
      }

      .button {
        background: _palette(accent-purple);
        color: #fff;
      }
    }
  }

  .block {
    &.bg-accent {
      background: _palette(accent-purple);
      background: -webkit-linear-gradient(left,_palette(accent-purple), _palette(accent-purple-alt));
      background: linear-gradient(to right,_palette(accent-purple), _palette(accent-purple-alt));
    }
  }
}

.palette-green {
  a {
    &:hover,
    &:focus {
      border-color: _palette(accent-green);
      color: _palette(accent-green);
    }
  }

  blockquote {
    border-color: _palette(accent-green);
  }

  button,
  input[type="submit"],
  input[type="button"],
  input[type="reset"],
  a.button {
    background: _palette(accent-green);
    border-color: _palette(accent-green);
    color: #fff;
  }

  .button.secondary {
    background: transparent;
    color: _palette(accent-green);

    &:hover,
    &:focus,
    &:active {
      background: _palette(accent-green);
      color: #fff;
    }
  }

  .button.white {
    background: #fff;
    border-color: transparent;
    color: _palette(primary);
  }

  .underline {
    &:after {
      border-color: _palette(accent-green);
    }
  }

  .icon-plus {
    background: _palette(accent-green);
  }

  .review-text {
    &:before {
      color: _palette(accent-green);
    }
  }

  .plan {
    &.highlight {
      .plan-inside {
        border-color: _palette(accent-green);
      }

      .button {
        background: _palette(accent-green);
        color: #fff;
      }
    }
  }

  .block {
    &.bg-accent {
      background: _palette(accent-green);
      background: -webkit-linear-gradient(left,_palette(accent-green), _palette(accent-green-alt));
      background: linear-gradient(to right,_palette(accent-green), _palette(accent-green-alt));
    }
  }
}

.palette-orange {
  a {
    &:hover,
    &:focus {
      border-color: _palette(accent-orange);
      color: _palette(accent-orange);
    }
  }

  blockquote {
    border-color: _palette(accent-orange);
  }

  button,
  input[type="submit"],
  input[type="button"],
  input[type="reset"],
  a.button {
    background: _palette(accent-orange);
    border-color: _palette(accent-orange);
    color: #fff;
  }

  .button.secondary {
    background: transparent;
    color: _palette(accent-orange);

    &:hover,
    &:focus,
    &:active {
      background: _palette(accent-orange);
      color: #fff;
    }
  }

  .button.white {
    background: #fff;
    border-color: transparent;
    color: _palette(primary);
  }

  .underline {
    &:after {
      border-color: _palette(accent-orange);
    }
  }

  .icon-plus {
    background: _palette(accent-orange);
  }

  .review-text {
    &:before {
      color: _palette(accent-orange);
    }
  }

  .plan {
    &.highlight {
      .plan-inside {
        border-color: _palette(accent-orange);
      }

      .button {
        background: _palette(accent-orange);
        color: #fff;
      }
    }
  }

  .block {
    &.bg-accent {
      background: _palette(accent-orange);
      background: -webkit-linear-gradient(left,_palette(accent-orange), _palette(accent-orange-alt));
      background: linear-gradient(to right,_palette(accent-orange), _palette(accent-orange-alt));
    }
  }
}
