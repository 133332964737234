.category-section {
  .section-header {
    display: flex;
    border-bottom: 2px solid rgba($blue, .3);
    justify-content: space-between;
    margin-bottom: 1rem;
    padding: .5rem 0;
    align-items: flex-end;

    h2 {
      font-size: 1.0rem;
      display: block;
      background: #F3F3F3;
      color: $blue;
      font-weight: 600;
      margin-bottom: 0;

      .subtitle {
        text-transform: none;
        display: block;
        margin-top: .5rem;
        font-weight: 400;
        color: rgb(43, 43, 43);
        line-height: 1.3rem;

        a {
          color: #000;
          font-weight: 600;
        }
      }
    }
  }

  &>.inner {
    // border: 1px solid rgb(214, 214, 214);
    // padding: 1rem;
    // border-radius: 3px;
  }
}

.category-section-selection {
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: .5rem 1rem;
  margin-bottom: .6rem;
  flex-wrap: wrap;

  @include max-width($md) {
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    background: none;

    &>div {
      width: 100%;
    }
  }

  h5 {
    margin: 0;
    margin-right: 1rem;

    @include max-width($md) {
      margin-right: 0;
      margin-bottom: .7rem;
    }
  }

  .btn {
    margin-right: 1rem;
    background-color: #bbe3f23d;
    font-size: .7rem;
    color: #0b7a9a;
    transition: .2s;
    padding: .2rem 1rem; 
    border: 2px solid #bbe3f23d;
    border-radius: 2px;
    white-space: normal;
    text-align: left;

    i {
      margin-right: .4rem;
    }

    &:first-child {
      background: #e8f8ff;
      border: 2px solid #b6ecff;
    }

    @include max-width($md) {
      margin-right: 0;
      width: 100%;
      margin-bottom: .5rem;
    }

    &:hover {
      color: #074050;
      border-color: #0EC8F0;;
    }
  }

  .css-yk16xz-control {
    border-color: #0765802e;
    border-width: 2px;
  }
}