.location-details {
  border-radius: 5px;
  box-shadow: 0 0 7px -2px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  padding: 0.7rem;

  &.spacious {
    display: flex;

    @include max-width($lg) {
      display: block;
    }

    .intro {
      flex-direction: column;
      flex: 1;
      margin-right: 1rem;
      padding-right: 1rem;
      border-right: 1px solid rgb(233, 233, 233);

      @include max-width($lg) {
        flex-direction: row;
        flex: none;
        margin-right: 0;
        padding-right: 0;
        border: none;
      }

      .text-container {
        flex: 1;
      }

      .image-container {
        width: 100%;
        overflow: hidden;
        max-height: 200px;
        margin-right: 1rem;

        @include max-width($lg) {
          width: 150px;
        }

        @include max-width($sm) {
          width: 100px;
        }

        img {
          width: 100%;
        }
      }
    }

    .right-side {
      flex: 1;
    }

    .hours-container {
      margin-top: 0;

      h6 {
        background: none;
        color: rgb(99, 99, 99);
      }

      .hours {
        li {
          span {
            &.time {
              font-weight: 400;
            }
          }
        }
      }
    }

    .pickup-time {
      // margin-top: 1rem;
      // border-top: 1px solid #004085;
      // padding-top: .5rem;

      h4 {
        color: #004085;
        font-size: 1rem;
      }

      .option-selection {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        
        .btn {
          background: white;
          display: flex;
          width: 100%;
          justify-content: space-between;
          padding: .3rem .7rem;
          margin-bottom: .7rem;
          border-radius: 3px;
          font-size: .8rem;
          font-weight: 400;
          background-color: rgba(0,0,0,.05);
          align-items: center;
          margin-right: .75rem;
          transition: 0;

          &:last-child {
            margin-right: 0;
          }
    
          &:hover {
            color: #000;
          }
    
          &.active {
            font-weight: 600;
            color: darken($green, 15%);
            background-color: rgba($green, .2);
            border-color: $green;
      
            .status-container {
              i {
                &.checked {
                  display: block;
                }
        
                &.unchecked {
                  display: none;
                }
              }
            }
          }

          &:hover {
            background-color: rgba($green, .2);
            border-color: $green;
          }
      
          .status-container {
            margin-right: .7rem;
      
            i {
              font-size: 1.2rem;
      
              &.checked {
                display: none;
              }
      
              &.unchecked {
                display: block;
                color: rgb(134, 134, 134);
              }
            }
          }
      
          .text-container {
            flex: 1;
            text-align: left;
          }
        }
      }

      .input {
        .react-datepicker-wrapper {
          width: 100%;
          margin-bottom: .7rem;

          input {
            display: block;
            width: 100%;
            padding: 0.375rem 0.75rem;
            font-size: 1rem;
            line-height: 1.5;
            color: #495057;
            background-color: #fff;
            background-clip: padding-box;
            border: 1px solid #ced4da;
            border-radius: 3px;
          }
        }

        .react-datepicker {
          border-radius: 3px;
          border-color: #e2e0e0 !important;
          box-shadow: 0 0 7px -2px rgba(0, 0, 0, 0.2);
        }

        .react-datepicker__header {
          border: none;
          border-top-left-radius: 3px;
          border-top-right-radius: 3px;
        }

        .react-datepicker__time-list-item--disabled {
          display: none;
        }
      }
    }

    .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
      border-radius: 2px !important;
    }
  }

  .back-to-results {
    margin-bottom: 0.5rem;

    .btn {
      font-size: 0.8rem;
      padding: 0.1rem 1rem;
      border: none;
      border-radius: 100px;
      background-color: rgb(230, 230, 230);
      color: #3878c7
    }
  }

  .intro {
    display: flex;
    margin-bottom: 0.5rem;

    .image-container {
      margin-right: 0.5rem;

      img {
        width: 120px;
      }
      i {
        margin-right: 0.5rem;
        text-align: left;
        width: 50px;
        font-size: 3rem;
        color: rgba(0, 0, 0, 0.2);
      }
    }

    .text-container {
      h4 {
        margin-top: 0;
        margin-bottom: 0.2rem;
      }

      h6 {
        margin-bottom: .1rem;
      }

      p {
        margin-bottom: 0;
      }

      .open-status {
        background-color: rgba(0, 0, 0, 0.1);
        border-radius: 5rem;
        padding: 0.1rem .5rem;
        display: inline-block;
        text-align: center;
        font-size: .8rem;
      }

      span {
        font-size: .9rem;
      }
    }

    .rating {
      margin-bottom: 0;

      i {
        color: $orange;
      }

      span {
        color: $grey;
      }
    }
  }

  .actions {
    border-top: 1px solid rgba(0, 0, 0, 0.07);
    display: flex;
    justify-content: space-between;
    padding-top: 1rem;
    list-style-type: none;
    margin: 0;
    padding: 1rem 0 0 0;

    li {
      flex: 1;
      margin-right: 0.5rem;      

      a {
        text-align: center;
        background-color: rgba(0, 0, 0, 0.05);
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 0.25rem;
        color: #3878c7;
        border-radius: 3px;
      }

      i {
        margin-right: 0.5rem;
      }

      &:last-child {
        margin-right: 0;
      }

      &:hover {
        background-color: rgba(0, 0, 0, 0.08);
        cursor: pointer;
      }
    }
  }

  .offered-services {
  }

  .hours-container {
    padding-top: 1rem;
    margin-top: 1rem;
    border-top: 1px solid rgba(0, 0, 0, 0.07);

    h6 {
      font-size: 0.75rem;
      font-weight: 600;
      text-transform: uppercase;
      padding: 0.25rem 1rem;
      color: #3878c7;
      border-radius: 3px;
      background-color: rgba(0, 0, 0, 0.05);
      display: flex;
      justify-content: space-between;
      padding: .75rem;

      &:hover {
        background-color: rgba(0, 0, 0, 0.08);
        cursor: pointer;
      }
    }

    .hours {
      display: flex;
      flex-direction: column;
      margin-top: 0;
      padding: 0;

      li {
        flex: 1;
        margin-bottom: 0.4rem;
        display: flex;

        span {
          flex: 1;

          &.time {
            font-weight: 600;
          }
        }
      }
    }
  }
}

.react-datepicker-popper {
  z-index: 9999 !important;
}
