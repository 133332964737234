.new-product-main-form {
  label {
    margin-bottom: .3rem;
  }

  input, textarea {
    border-radius: 3px !important;
  }

  .form-section {
    margin-bottom: 1.5rem;

    .select__multi-value {
      width: 100%;
      font-size: 1rem;
      padding: .3rem;
      background: rgba(0,0,0,.05);
      justify-content: space-between;
    }
  }

  .images-upload-container {
    padding: 2rem 1rem;
    border: 2px dashed rgb(168, 168, 168);

    &:hover {
      border-color: $blue;
      background-color: rgba($blue, .05);
    }

    .inner {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      i {
        font-size: 3rem;
        color: #cecece;
      }

      .btn {
        text-transform: none;
        text-transform: none;
        margin-bottom: .7rem;
        background: rgba(0,0,0,.05);
        border-radius: 3px;
        font-size: .75rem;
        margin: 1rem 0;
      }

      p {
        margin-bottom: 0;
      }
    }
  }

  .pairs-container {
    margin-bottom: 1rem;

    .pair {
      display: flex;
      justify-content: space-between;
      margin-bottom: 1rem;

      .input-container-wrapper {
        flex: 1;

        &:first-child {
          flex: 0.7;
          margin-right: 1rem;
        }
      }
    }

    .btn {
      text-transform: none;
      font-size: .75rem;
      border-radius: 3px;
      background-color: rgba(0,0,0,.1);

      &:hover {
        background-color: rgba(0,0,0,.15);
      }
    }
  }
  

  .submit-btn-container {
    padding-top: 1rem;
    border-top: 1px solid rgba($green, .5);
    text-align: right;

    .btn {
      background: $green;
      text-transform: none;
      color: white;
      width: 150px;
      border-radius: 3px;

      &:hover {
        background-color: darken($green, 20%);
      }
    }
  }
}