.product-box-container {
  position: relative;
}

.product-box {
  background: #fff;
  padding: 0.75rem !important;
  border-radius: 3px;
  position: relative;
  margin-top: 0 !important;
  margin-bottom: 1.3rem !important;
  border: 2px solid white;
  transition: 0.2s;
  overflow: hidden;

  .affirm-as-low-as {
    font-size: .8rem;
    margin-bottom: .2rem;

    a {
      color: #4A4AF4;
    }
  }

  a {
    color: rgb(36, 36, 36);
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }

  span {
    &.featured-text {
      display: none;
      position: absolute;
      top: 1rem;
      right: -1.4rem;
      background-color: #F26200;
      color: white;
      font-weight: 600;
      color: white;
      font-size: .8rem;
      padding: 0 1.4rem;
      z-index: 20;
      transform: rotate(45deg);

      &.recommended {
        top: 2rem;
        right: -1.6rem;
      }
    }
  }

  &.promoted {
    background-color: #fefaf0;
    border-color: navajowhite;

    span {
      &.featured-text {
        display: block;
      }
    }
  }

  .has-overlay {
    position: relative;

    &:hover {
      .overlay {
        opacity: 1;
      }

      .img-wrapper {
        @include min-width(768px) {
          filter: blur(3px);
        }
      }

      .product-details {
        // filter: blur(3px);
      }
    }

    .overlay {
      transition: 0.3s all;
      position: absolute;
      opacity: 0;

      //background: radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .inner {
        background: rgba(255, 255, 255, 0.8);
        padding: 1rem;
        text-align: center;
        border-radius: 3px;

        i {
          color: #4A52F5;
        }

        h6 {
          font-size: 1rem;
          font-weight: 600;
          margin-bottom: 0;
          color: rgb(44, 44, 44);
          
        }
      }
    }
  }

  .discounts {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;

    a {
      color: white;
    }

    .discount-item {
      border: 2px solid lighten($red, .5);
      background-color:rgba($red, 1);
      border-top-right-radius: 100px;
      border-bottom-right-radius: 100px;
      padding: .1rem 1rem .1rem .5rem;
      margin-top: .5rem;
      color: white;
      transition: .2s all;

      &:hover {
        transform: scale(1.02);
        cursor: pointer;
      }

      .content {
        font-size: .8rem;
        // display: flex;

        .amount {
          //font-size: 1rem;
          font-weight: 600;
        }

        .details {
          // display: flex;
          // flex-direction: column;

          span {
            font-weight: 300;
          }

          img {
            width: 1.5rem;
          }
        }
      }
    }
  }

  .not-available-text {
    margin-bottom: 0;
    margin-top: 0.5rem;
    padding-top: 0.5rem;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    font-weight: 600;
    text-transform: uppercase;
    color: rgb(189, 189, 189);
    font-size: 0.75rem;
  }

  &:hover {
    .not-available-text {
      color: $red;
    }
  }

  .not-available {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    z-index: 999;
    flex-direction: column;
    padding: 1rem;
    text-align: center;
    border-radius: 3px;
    display: none;

    i {
      font-size: 2rem;
    }

    h6 {
      font-size: 0.9rem;
      color: black;
      margin-bottom: 0;
      margin-top: 0.7rem;
      font-weight: 600;
      text-transform: uppercase;
    }
  }

  .img-wrapper {
    transition: 0.3s all;
    height: 230px;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center; 

    .front {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;

      .img-fulid {
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        width: 100%;
        height: 100%;
      }
    }
  }

  .img-wrapper {
    border-color: #e8e8e8;
  }
      
  .disclaimer {
    background-color: #fffbef;
    color: #984b0e;
    margin: 0;
    text-align: center;
    padding: .3rem;
    font-weight: 600;
    font-size: .8rem;
    line-height: 1.1rem;
    text-align: left;
    margin-top: .3rem;

    span {
      border-bottom: 1px dashed #984b0e;;
    }
  }


  .product-details {
    display: flex;
    margin-top: 1rem;
    position: relative;
    transition: 0.3s all;

    .text-content {
      flex: 1;

      h6 {
        line-height: 1.4rem;
        font-size: 1.1rem;
        color: black;
        margin-bottom: 0.5rem;
      }

      p {
        margin-bottom: 0;
        line-height: 1.2rem;
        font-size: .8rem;
      }
    }

    .price-content {
      text-align: left;
      margin-left: 0.5rem;
      padding-left: 0.5rem;
      border-left: 1px solid rgb(238, 238, 238);

      del {
        font-size: .9rem;
        color: grey;
      }

      h4 {
        color: rgb(49, 49, 49);
        font-size: 1.3rem;
      }

      h6 {
        font-style: italic;
      }

      .free-installation {
        color: darken($orange, 10%);
        font-weight: 600;
        border-radius: 30px;
        display: block;
        text-align: left;
        font-size: .70rem;
      }
    }
  }

  .available-services {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .btn {
      &.add-to-cart {
        margin-top: 0.5rem;
        background-color: $orange;
        color: white;
        border-radius: 3px;
        font-size: 0.75rem;

        &:hover {
          background-color: darken($green, 10%);
        }
      }

      &.selection {
        margin-top: 0.5rem;
        font-weight: 400;
        text-transform: none;
        color: grey;
        background-color: rgba(0, 0, 0, 0.05);
        width: 100%;
        z-index: 999;
        text-align: left;
        border-radius: 3px;
        font-size: 0.75rem;

        &:hover {
          color: darken($green, 15%);
          background-color: rgba($green, 0.05);
          border-color: $green;
          color: grey;
        }

        &.active {
          color: darken($green, 15%);
          background-color: rgba($green, 0.1);
          border-color: $green;

          span {
            color: black;
          }
        }

        i {
          margin-right: 0.5rem;
        }

        span {
          &.title {
            color: black;
          }
        }
      }
    }
  }

  .campaign-hypherlink {
    text-align: center;
    color: #0A357E;
    display: block;
    margin-top: .5rem;
    font-size: .8rem;

    span {
      font-weight: 600;
      border-bottom: 2px solid rgba(#0A357E, .4);
    }

    &:hover {
      color: darken(#0A357E, 10%);
      
      span {
        border-bottom: 2px solid rgba(#0A357E, 1);
      }
    }
  }
}

.indicator {
  background-color: rgba(0,0,0,.05);
  padding: .3rem .5rem .2rem .5rem;

  .graphic {
    width: 100%;
    height: 3px;
    border-radius: 100px;
    background: $green;
    background: linear-gradient(90deg, rgba(180,104,58,1) 0%, rgba(253,230,29,1) 50%, rgba(18,158,52,1) 100%);
    position: relative;

    i {
      display: none;
      position: absolute;
      bottom: -8px;
      transform: rotate(60deg);
      font-size: .7rem;
      color: rgba(117,159,63,1);
    }

    .square {
      height: 15px;
      width: 7px;
      border: 1px solid #0fbcee;
      background: rgba(255,255,255,.6);
      position: absolute;
      border-radius: 2px;
      bottom: -6px;
    }
  }

  span {
    text-transform: uppercase;
    font-size: .7rem;
    display: block;
    font-weight: 600;
    color: #364a1d;
    margin-top: 7px;
    text-align: right;
  }
}