.search-feed-item {
  background-color: #fff;
  padding: .7rem;
  height: 100%;
  text-align: center;
  border-radius: 5px;
  box-shadow: 0 0 7px -2px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;

  @include max-width($md) {
    flex-direction: column;
    justify-content: center;
  }

  &.product {
    flex-direction: column;

    img {
      height: 5rem;
      margin-right: 0;
      margin-bottom: .5rem;
    }

    .buttons {
      display: flex;
      justify-content: center;
      margin-bottom: .5rem;

      .btn {
        border: none;
        font-weight: 400;
        text-transform: none;
        border-radius: 2px;

        &.btn-primary {
          background-color: $green;
          border: none;
          color: #fff;
          font-weight: 600;
        }
  
        &.btn-secondary {
          margin-right: .5rem;
          background: rgba(0, 0, 0, .05);
          color: $grey5;
        }
      }
    }

    p {
      &.price {
        font-size: 1.2rem;
        font-weight: 600;
      }
    }
  }

  h6 {
    margin: 0;
    text-align: left;
    font-size: 1rem;
    font-weight: 600;

    @include max-width($md) {
      text-align: center;
    }
  }

  img {
    height: 4rem;
    max-width: 80%;
    margin-right: .5rem;

    @include max-width($md) {
      margin-right: 0;
    }
  }

  &:hover {
    //cursor: pointer;
    box-shadow: 0 0 7px -2px rgba(0, 0, 0, 0.4);
    transform: scale(1.02);

    h6 {
      color: $green;
    }
  }
}