.styles_modal__gNwvD {
  max-width: 90%;
  width: 500px;
}

.update-location-modal {
  //width: 500px;
  max-width: 100%;

  .alert {
    @include max-width($lg) {
      max-width: 100% !important;
    }
  }

  h6 {
    font-size: 1.2rem;
    color: rgb(43, 43, 43);
    padding-bottom: .7rem;
    margin-bottom: 1rem;
    border-bottom: 1px solid rgb(226, 226, 226);
    padding-right: 2rem;
  }

  .place-selection {
    input {
      width: 100% !important;
    }
  }
}