footer {
  margin-top: 1rem;

  .footer-theme {
    padding-bottom: 2rem;
  }
  
  &.footer-light {
    background-color: #F9F9F9;
    padding-top: 4rem;

    .payment-card-bottom {
      img {
        height: 60px;
      }
    }
  }

  .footer-title {
    h4 {
      color: #575f70;
      font-size: 1rem;
      text-transform: uppercase;
      margin-bottom: 1rem;
    }
  }

  .footer-logo {
    img {
      width: 200px;
    }
  }

  ul {
    list-style-type: none;
    padding: 0;

    li {
      margin-bottom: .5rem;
      font-size: .9rem;

      a {
        text-decoration: none;
        color: #677788;

        &:hover {
          text-decoration: none;
          color: #000000;
        }
      }
    }
  }

  .footer-social {
    ul {
      display: flex;
      li {
        margin-right: 1rem;
        
        a {
          font-size: 1.3rem;
        }
      }
    }
  }

  .footer-contant {
    p {
      font-size: .9rem !important;
    }
  }

  .sub-footer {
    padding: 1.5rem 0;
    background-color: #fff;

    .footer-end {
      p {
        font-size: .8rem;
        margin-bottom: 0;

        i {
          margin-right: .3rem;
        }
      }
    }

    .payment-card-bottom {
      ul {
        display: flex;
        justify-content: flex-end;
        margin-bottom:  0;
      }
    }
  }
}

.tap-top {
  display: none !important;
}