.add-to-cart-selector {
  position: relative;
  overflow-y: auto;

  &.not-available {
    .book {
      background-color: #FF9966;
      color: white;
      width: 100%;
      text-align: center;
      font-size: 0.9rem;
      font-weight: 600;
      text-transform: uppercase;
      border: none;
    }
  }

  .show-price-only {
    .btn {
      &.active {
        display: flex !important;
      }

      display: none !important;
      background: none !important;
      border: none !important;
      padding: 0;
      margin-bottom: 1.5rem !important;
      box-shadow: none !important;
      opacity: 1 !important;

      .icon {
        display: none !important;
      }
  
      .content {
        .reviews-cont {
          display: none;
        }

        span {
          display: none !important;
  
          &.service-description {
            display: block !important;
            text-transform: none;
            font-weight: 600;
            font-size: .9rem;
            border-bottom: 2px solid rgba(255, 0, 0, 0.226);
            padding-bottom: .2rem;
            color: rgb(160, 41, 41);
          }
        }
      }
  
      .secondary {
        display: none;
      }
    }
  }

  &.compact {
    margin-top: 0.5rem;

    .product-options-container {

      .service-description {
        text-transform: uppercase;
      }
      
      .btn {
        font-size: 0.7rem;
        .primary {
          .content {
            span {
              line-height: 1rem;
              font-size: .7rem;
            }
          }
        }

        .icon {
          margin-right: 0.5rem;

          i {
            font-size: 1.2rem;
          }

          img {
            width: 20px;
            margin-top: 5px;
          }
        }

        .secondary {
          background: none;
          padding: .2rem 0;

          span {
            color: grey;
          }

          .provider-logo {
            width: 40px;
          }
        }

        &.active {
          background-color: rgba($green, .02)
        }
      }
    }

    .add-to-cart-container {
      padding: 0;
      margin-top: 0.5rem;

      .btn {
        margin-left: 0;
        flex: 1;

        &.cart {
          margin-right: 0;
        }
      }
    }
  }

  .quantity-container {
    input {
      border-radius: 0;
      width: 50px;
      text-align: center;
      padding-left: 0.5rem;
      border-radius: 3px;
      font-weight: 600;
    }

    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  .product-options-container {
    position: relative;

    .btn {
      background: none;
      width: 100%;
      text-align: left;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 0.5rem;
      border-radius: 3px;
      border: 2px solid rgb(0 0 0 / 8%);
      box-shadow: inset 0 0 20px 0px #e3e3e3;

      .primary {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .content {
          span {
            white-space: initial;
            line-height: 1.2rem;
            font-size: .9rem;

            &.service-description {
              font-weight: 600;
              text-transform: uppercase;
            }
          }
        }

        .about-location {
          font-weight: 400;
          text-transform: none;
          display: flex;

          &.star-details {
            display: flex;
            flex-direction: row;
      
            @include min-width(1200px) {
              flex-direction: column;
            }
      
            @include min-width(1440px) {
              flex-direction: row;
            }

            @include max-width(350px) {
              flex-direction: column;
            }
      
            .numerical-rating-container {
              display: flex !important;
            }
          }

          .numerical-rating {
            font-size: 0.7rem;
          }
        }
      }

      .secondary {
        background-color: #f3f3f3;
        padding: 0.2rem 0.5rem;
        border-radius: 3px;

        &:hover {
          // background-color: darken(#F3F3F3, 1%);
        }

        span {
          font-size: 0.7rem;
          font-weight: 600;
          text-align: center;

          &:hover {
            color: $green;
          }
        }

        .provider-logo {
          width: 60px;
          opacity: .75;

          @include max-width($md) {
            width: 40px;
          }
        }
      }


      &:hover {
        background-color: #fff;
        border: 2px solid rgba($green, 0.5);
      }

      .icon {
        margin-right: 1rem;
        display: flex;
        align-items: center;
        flex-direction: column;

        i {
          font-size: 1.5rem;
          color: rgba($green, 1);

          &.checked {
            display: none;
          }

          &.unchecked {
            color: rgba(rgb(5, 5, 5), 1);
            display: block;
          }
        }

        img {
          width: 40px;
          margin-top: 5px;
        }
      }

      span {
        display: block;
      }

      &.active {
        background-color: #fff;
        border: 2px solid rgba($green, 0.9);

        &:active, &:focus {
          border: 2px solid rgba($green, 0.9) !important;
          box-shadow: inset 0 0 20px 0px #e3e3e3 !important;
          opacity: 1;
        }

        i {
          &.unchecked {
            display: none;
          }

          &.checked {
            display: block;
          }
        }
      }
    }
  }

  .add-to-cart-container {
    background-color: #fff;
    padding: 0.5rem;
    margin-top: 1rem;

    .primary {
      display: flex;
    }

    .btn {
      margin-left: 0.7rem;
      border-radius: 3px;

      &.cart {
        background-color: $orange;
        color: white;
        width: 100%;
        text-align: center;
        font-size: .9rem;
        font-weight: 600;
        text-transform: uppercase;

        &.disabled {
          background-color: grey !important;
        }

        &:hover {
          background-color: darken($orange, 30%)
        }

        i {
          margin-right: 0.3rem;
        }

        .service-name {
          border-bottom: 1px dashed white;
        }
      }

      svg {
        width: 1rem;
        height: 1rem;
        margin-right: .5rem;
        margin-bottom: -.15rem;
      }

      &.info {
        background: rgba(0, 0, 0, 0.05);
        color: grey;
        font-size: 1.1rem;

        &:hover {
          background: rgba(0, 0, 0, 0.1);
        }
      }
    }

    .alert {
      padding: 0.3rem 0.7rem;
      margin-bottom: 0;
      font-size: .75rem;
      color: #2b2b2b;
      border: none;
      background-color: #F3F3F3;
      width: 100% !important;
      max-width: 100% !important;
    }
  }
}

.change-location-modal {
  padding: 0 !important;
  background-color: #F3F3F3;

  .react-responsive-modal-closeButton {
    display: none;
  }
}

.location-items-integrated {

  .header {
    display: flex;
    border-bottom: 1px solid rgb(224, 224, 224);
    padding-bottom: 0.7rem;
    margin-bottom: 1rem;
    flex-direction: column;
    background-color: #fff;
    padding: .7rem;

    .info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: .5rem;

      h3 {
        font-weight: 400;
        font-size: .9rem;
        margin-bottom: 0;
        text-transform: uppercase;
        color: black;
      }
  
      .btn {
        text-transform: none;
        font-size: .8rem;
        padding: 0;
        background: none;
        border-radius: 3px;
        height: 30px;
        display: flex;
        align-items: center;
        color: rgba(darken($red, 20%), .6);

        i {
          margin-right: .3rem;
        }

        &:hover {
          color: rgba(darken($red, 20%), 1);
        }
      }
    }

    .controls {
      border: 1px solid rgb(224, 224, 224);
      display: flex;
      align-items: center;
      border-radius: 3px;
      padding-left: 1rem;
      justify-content: space-between;

      .title {
        display: block;
        margin-right: 1rem;
      }

      .css-2b097c-container {
        flex: 1;
      }
      .css-yk16xz-control {
        border-color: white;
      }
    }
  }

  .btn {
    &.back {
      display: inline-block;
      font-size: 0.8rem;
      padding: 0;
      background: none;
      border: none;
      color: rgb(70, 70, 70);

      &:hover {
        background: none;
        border: none;
        color: black;
      }
    }
  }

  &>.body {
    padding: .7rem;
  }

  .item {
    background-color: #fff;
    margin-bottom: 1rem;
    border-radius: 3px;
    padding: .5rem 1rem;
    width: 500px;
    display: flex;
    align-items: center;
    border: 2px solid #fff;
    border-radius: 3px;

    @include max-width($md) {
      flex-direction: column;
      align-items: flex-start;
      border: 2px solid #e2e2e2;
      padding: 1rem;
    }

    .section {
      align-items: center;
      display: flex;

      @include max-width($md) {
        width: 100%;
        text-align: center;
      }

      &.first {
        flex: 1.1;
      }

      &.second {
        flex: 1;
        display: flex;
      }
    }

    &:hover {
      border: 2px solid rgba($green, .5);
      cursor: pointer;
    }

    .selected-label {
      display: none;
    }

    &.selected {
      border: 2px solid rgba($green, .5);
      background-color: lighten($green, 39%);
      padding: .5rem 1rem;
      position: relative;

      @include max-width($md) {
        padding-top: 1rem;
      }

      .selected-label {
        display: block;
        position: absolute;
        top: -12px;
        left: -2px;
        background-color: $green;
        color: white;
        padding: .1rem 1rem;
        font-size: .8rem;
      }

      .content {
        .body {
          p {
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }

      .btn {
        display: none !important;
      }
    }

    @include max-width($md) {
      width: auto;
      max-width: 100%;
    }

    .content {
      flex: 1;
      padding-right: .75rem;
      margin-right: .75rem;
      border-right: 1px solid rgb(236, 236, 236);

      @include max-width($md) {
        margin-right: 0;
        padding-right: 0;
        padding-bottom: .5rem;
        margin-bottom: .5rem;
        border-right: none;
        border-bottom: 1px solid rgb(236, 236, 236);
      }

      .body {
        p {
          margin-bottom: 0.5rem;
  
          &:first-child {
            font-weight: 600;
            color: black;
          }
        }
      }
  
      .footer {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        // margin-top: .5rem !important;
        padding-top: 0.5rem;

        @include max-width($md) {
          display: none;
        }
  
        .footer-item {
          margin-right: 0.5rem;
          flex: 1;
  
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }

    .info {
      flex: 1;
      text-align: center;
      padding-right: .75rem;
      margin-right: .75rem;
      border-right: 1px solid rgb(236, 236, 236);

      img {
        width: 40%;
        opacity: .7;
      }

      h5 {
        font-size: .8rem;
        font-weight: 400;
        line-height: .99rem;
        color: #444444;
        margin-top: .5rem;
        margin-bottom: 0;
      }
    }

    .price {
      flex: 1;
      
      span {
        font-size: 1.2rem;
        display: block;

        &.ex-price {
          color: grey;
        }

        &.price {
          color: black;
        }

        &.online-only {
          color: darken($orange, 10%);
          font-size: .7em;
          font-weight: 600;
        }
      }
    }

    .rating {
      display: flex;
      flex-direction: column;

      span {
        color: grey;
        font-size: .7rem;
      }
    }

    .btn.location {
      color: grey;
      border: none;
      background-color: rgba(0, 0, 0, 0.05);
      border-radius: 3px;
      font-size: 0.7rem;
      padding: 0.2rem 1rem;
      font-weight: 400;
      text-align: center;
      margin-bottom: 0;
      display: block;
      // width: 100%;

      @include max-width($md) {
        display: none;
      }

      &.mobile-only {
        display: none;
        margin: 1rem auto 0 auto;

        @include max-width($md) {
          display: block;
        }
      }

      &:hover {
        background-color: rgba(0, 0, 0, 0.1);
      }

      &.location {
        background: none;
        border: 1px solid rgba($green, 0.8);
        color: darken($green, 20%);
        font-weight: 600;

        &:hover {
          background-color: $green;
          color: white;
        }
      }

      i {
        margin-right: 0.2rem;
      }
    }
  }
}

.styles_overlay__CLSq- {
  @include max-width($sm) {
    padding-right: 0;
    padding-left: 0;
  }
}

.change-location-modal {
  width: auto !important;
}