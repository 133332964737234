.brands-section {
	position: relative;
	background-color: #fff;
	box-shadow: 0 0 7px -2px rgba(0, 0, 0, 0.1);
	overflow: hidden;
	text-align: center;
	border-radius: 5px;

	.fader-container {
		text-align: center;
		&.initial {
			height: 230px !important;
			padding-bottom: 0 !important;
		}

		&.expanded {
			height: auto !important;
			padding-bottom: 50px;

			img {
				opacity: 1;
			}

			.btn-container {
				display: none;
			}
		}
	}

	.fader {
		width: 150px;
		height: 100%;
		position: absolute;
		z-index: 10;
		padding: 1rem;

		&.right {
			right: 0;
			background: linear-gradient(270deg, #f3f3f3 0%, rgba(128, 81, 79, 0) 100%);

			@include max-width(767px) {
				display: none;
			}
		}

		&.left {
			left: 0;
			background: linear-gradient(90deg, #f3f3f3 0%, rgba(128, 81, 79, 0) 100%);

			@include max-width(767px) {
				display: none;
			}
		}

		&.bottom {
			bottom: 0;
			width: 100%;
			background: rgb(235, 235, 240);
			background: linear-gradient(180deg, rgba(235, 235, 240, 0) 0%, rgba(255, 255, 255, 1) 100%);
			height: 100px;
		}
	}

	img {
		margin-right: 0.5rem;
		margin-left: 0.5rem;
		margin-top: 1rem;
		opacity: 0.5;
		transition: all 0.5s;
		width: 100px;

		@include max-width(767px) {
			width: 64px;
		}
	}

	.title {
		h2 {
			text-align: center;
			color: #064a5e;
			font-size: 2rem;
			font-weight: 600;
			margin-bottom: 0.5rem;
			margin-top: 1.5rem;
			text-transform: none;

			@include max-width(991px) {
				font-size: 1.5rem;
				padding-bottom: 0.5rem;
			}
		}
	}

	.btn-container {
		position: absolute;
		bottom: 0;
		left: 0;
		z-index: 11;
		width: 100%;
		padding: 1rem;

		.btn {
			background-color: white;
			color: #282828;
			border-radius: 3px;
			box-shadow: 0 0 11px 0px rgb(0 0 0 / 20%);

			&:hover {
				opacity: 1;
				box-shadow: 0 0 11px 0px rgb(0 0 0 / 30%);
			}
		}
	}
}
