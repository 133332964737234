.provider-details {
  position: absolute;
  top: 0;
  background: #F3F3F3;
  border-radius: 3px;
  z-index: 999;
  border: 2px solid $orange;
  visibility: hidden;
  opacity: 0;
  transition: all 0s linear .5s, opacity 0.33s linear;

  @include max-width($md) {
    left: 1rem !important;
  }

  &.specific {
    .inner {
      &:after {
        content: '';
        position: absolute;
        left: auto ;
        right: 0 !important;
        top: 50%;
        width: 0;
        height: 0;
        border: 15px solid transparent;
        border-left-color: $orange;
        border-right: 0;
        margin-top: -15px;
        margin-right: -15px;
      }
    }
  }

  &.horizontal {
    position: static;
    margin-top: 1rem;

    .inner {
      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        width: 0;
        height: 0;
        border: 15px solid transparent;
        border-bottom-color: $orange;
        border-top: 0;
        margin-left: -15px;
        margin-top: -15px;

        @include max-width($md) {
          display: none;
        }
      }

      .table {
        tbody {
          display: flex;
          justify-content: flex-start;
          @include max-width($lg) {
            flex-direction: column;
          }

          tr {
            margin-right: .5rem;

            @include max-width($lg) {
              margin-right: 0;
            }

            td {
              border: none;
              background: #fff;
              border-radius: 100px;
              padding: .2rem 1.5rem;

              @include max-width($lg) {
                border-radius: 0;
                background: none;
                display: block;
                border-bottom: 1px solid rgb(212, 212, 212);
                padding: .2rem 0;
              }
            }
          }
        }
      }

      .testimonials {
        height: 400px;
        overflow-y: auto;
      }
    }
  }

  &.on-product-page {
    top: 0;
    &:after {
      content: '';
      position: absolute;
      right: 0;
      top: 20%;
      width: 0;
      height: 0;
      border: 15px solid transparent;
      border-left-color: $orange;
      border-right: 0;
      margin-top: -15px;
      margin-right: -15px;

      @include max-width($md) {
        display: none;
      }
    }

    .inner {
      &:after {
        display: none;
      }

      .table {
        tr {
          td {
            padding: .2rem 0;
          }
        }
      }
    }
  }

  &.active {
    visibility: visible;
    opacity: 1;
    transition-delay: 0s;
  }

  .inner {
    margin: 0;
    padding: 1rem;
    position: relative;
    height: 100%;
    // overflow-y: auto;
    display: flex;
    flex-direction: column;


    &:after {
      content: '';
      position: absolute;
      z-index: 99999;
      left: 0;
      top: 50%;
      width: 0;
      height: 0;
      border: 15px solid transparent;
      border-right-color: #FF9966;
      border-left: 0;
      margin-top: -15px;
      margin-left: -15px;
    }

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: .5rem;
      padding-bottom: .5rem;
      padding-top: 0;

      img {
        height: 1rem;
        margin-right: .5rem;
      }

      h4 {
        margin-bottom: 0;
        flex: 1;
      }

      .btn {
        font-size: .6rem;
        line-height: 1rem;
        padding: 0 .8rem;
        border-radius: 100px;
        background: #dedede;

        &:hover {
          background: #cccccc;
        }
      }
    }

    .table {
      td {
        border: none;
        border-bottom: 1px solid rgb(212, 212, 212);
        padding: .2rem 0;
      }
    }

    .testimonials {
      overflow: auto;

    }

    .blockquote {
      background-color: #fff;
      padding: .5rem;
      font-size: .8rem;
      font-style: italic;
      border-radius: 3px;
      margin-bottom: .5rem;
      border-color: #c7c7c7;

      p {
        line-height: 1.2rem;
      }

      .blockquote-footer {
        font-size: .8rem;
        margin-top: .2rem;
      }
    }
  }
}