.fitment-verification {
  .form {
    .form-items-container {
      .form-items {
        position: relative;

        .loading-container {
          position: absolute;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 2;
          background: rgba(255,255,255,.5);
        }

        label {
          text-transform: uppercase;
          font-weight: 600;
          font-size: .8rem; 
          margin-bottom: .3rem !important;
        }

        .form-item {
          &.disabled {
            opacity: .5;
          }

          &.active {
            .css-nyk460-control {
              border-color: $green;
            }

            .css-1wa3eu0-placeholder {
              color: black;
              font-weight: 600;
            }
          }
        }
      }
    }
  }
}

.single-product-fitment {
  background: none;
  margin-bottom: 1rem;
  margin-top: 1rem; 
  background-color: #fff;
  border-radius: 3px;

  .inner-wrapper {
    background: none;
    padding: 0;

    .form {
      h6 {
        // font-size: .95rem;
        color: rgb(39, 39, 39);
        font-weight: 600;
      }


      .form-items-icon {
        display: none;
      }

      .form-items-container {
        border: none;
        margin: 0;
        padding: 0;

        h4 {
          display: none;
        }

        .form-item {
          margin-top: .5rem; 

          &.disabled {
            opacity: .5;
          }

          &.active {
            .css-nyk460-control {
              border-color: $green;
            }

            .css-1wa3eu0-placeholder {
              color: black;
              font-weight: 600;
            }
          }

          .btn {
            background: $blue;
            color: #ffffff;
            text-transform: none;
            width: 100%;

            &:hover {

            }
          }
        }
      }
    }
  }
}

.fitment-status {
  display: flex;
  margin-bottom: 1rem;
  margin-top: 1rem; 
  align-items: center;
  padding: .5rem 0;
  border: 1px solid;
  border-image-slice: 1;
  border-width: 1px;
  background: linear-gradient(41deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0) 50%, #e7e7e7);
  border-radius: 5px;
  border: none;

  &.checkout-sidebar {
    padding: 0;
    border: none !important;

    .icon {
      display: none;
    }

    .text {
      h6 {
        font-size: .8rem;
      }

      p {
        font-size: .8rem;
      }
    }

    .button-container {
      button {
        background: none;
        padding: 0;
      }
    }
  }

  &.in-modal {
    padding: 0;
    margin: 0;
  }

  i {
    font-size: 1.5rem;
    margin-right: .5rem;
    color: #7CAE46;
  }

  .text {
    flex: 1;
    
    h6 {
      margin-bottom: 0;
      font-weight: 600;
      color: #7CAE46;
      text-transform: uppercase;
      font-size: .9rem;
    }
  
    p {
      margin-bottom: 0;
      color: rgb(65, 65, 65);
      font-size: .85rem;
      line-height: 1rem;
    }
  
  }
  .button-container {
    margin-left: .5rem;
    margin-right: .5rem;
  }

  button {
    padding: 0;
    background: 0;
    border: none;
    color: rgb(0, 0, 0);
    background-color: #c7c7c7;
    border-radius: 100px;
    padding: .2rem .5rem;
    font-weight: 400;
    font-size: .85rem;

    @include max-width($md) {
      margin-top: .5rem;
    }
  }

  &.fits {
    border-bottom: 2px solid #7cae4638;

    button {
      // font-size: .8rem;
    }
  }

  &.loading {
    i {
      color: black;
    }

    h6 {
      color: black;
    }

    p {
      color: black;
    }
  }

  &.does-not-fit {
    border-image-source: linear-gradient(to left, #ae4646, rgba(0, 0, 0, 0));
    border-bottom: 2px solid #ae464638;

    i {
      color: rgb(182, 63, 63)
    }

    h6 {
      color: rgb(177, 55, 55)
    }

    p {
      color: rgb(99, 99, 99);
      margin-bottom: .5rem;
    }

    button, a {
      border-radius: 100px;
      margin-top: .5rem;
      padding: .3rem;
      padding-left: 1rem;
      padding-right: 1rem;
      background-color: $green;
      color: #fff;
      text-decoration: none;

      &:hover {
        background-color: darken($green, 10%);
      }
      
    }

    .change-vehicle {
      text-decoration: underline;

      &:hover {
        color: black;
        cursor: pointer;
      }
    }
  }

  &.target-missing {
    background: linear-gradient(41deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0) 50%, #e7e7e7);

    i {
      color: rgb(53, 46, 46);
    }

    h6 {
      color: rgb(41, 37, 37);
    }

    &.variable-title {
      display: none;
    }
  }
}

.fitment-verification-in-modal {
  padding: 1rem;
  width: 500px;
  max-width: 90%;

  .fitment-verification {
    margin-bottom: 0;
    margin-top: 0;
  }

  h6 {
    font-size: 1rem;
    color: rgb(43, 43, 43);
    text-transform: uppercase;
    font-weight: 600;
    margin-right: 1.5rem;
  }

  .form-items {
    .form-item {
      margin-top: .5rem;
    }
  }
}