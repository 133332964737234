.place-selection {
  display: flex;
  flex-direction: column;

  &.has-my-location {
    input {
      width: 100% !important;
    }
  }

  &.checkout {
    .btn {
      width: auto !important;
    }

    input {
      width: 100%;
    }

    .row-items {
      width: 100%;
    }
  }

  .btn {
    background-color: $green;
    color: white;
    width: 200px;
    max-width: 100%;
    border-radius: 3px;
    text-transform: none;
    font-size: .9rem;
  }

  input {
    height: 40px;
    padding-left: 1rem;
    padding-right: 1rem;
    border-color: rgb(223, 223, 223);
    width: 100%;
    font-size: .9rem;

    &:focus-visible {
      border-color: rgb(197, 197, 197);
      outline: none;
    }
  }

  .input-pack {
    position: relative;

    .location-icon-container {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
}