body {
	font-family: Lato, sans-serif;
	background: #F3F3F3;
}

.lead {
	font-size: 1.4em;
}

pre {
	background-color: #333;
	border-color: #333;
	color: #eee;
	padding: 10px 20px;
	border-radius: 5px;
}

.navbar,
.navbar .btn,
.navbar .dropdown-item,
.navbar button {
	font-size: 18px;
}

.navbar {
	border-bottom: 1px solid #ddd;
}

@media (max-width: 768px) {
	.display-2 {
		font-size: 3em;
	}
	.display-3 {
		font-size: 2.5em;
	}
	.display-4 {
		font-size: 2em;
	}
}

.icon-spin {
	display: inline-block;
	animation: spin 2s infinite linear;
}
@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(359deg);
	}
}

.spinner-icon {
	animation: rotate-icon 2s linear infinite;
	& .path {
		stroke: #fff;
		stroke-linecap: round;
		animation: dash 1.5s ease-in-out infinite;
	}
}

@keyframes rotate-icon {
	100% {
		transform: rotate(360deg);
	}
}

@keyframes dash {
	0% {
		stroke-dasharray: 1, 150;
		stroke-dashoffset: 0;
	}
	50% {
		stroke-dasharray: 90, 150;
		stroke-dashoffset: -35;
	}
	100% {
		stroke-dasharray: 90, 150;
		stroke-dashoffset: -124;
	}
}

/**
   * This is a fix for Reactstrap 5.0.0-alpha.4 not displaying menus in the 
   * navbar correcrtly on mobile devices.
   **/
.navbar .dropdown {
	display: block;
}

@media (max-width: 768px) {
	.navbar .dropdown-menu {
		position: relative !important;
		display: block !important;
		transform: none !important;
		padding: 0 0 10px 0;
		border: none;
	}
}

/**
   * Fix for Reactstrap
   **/
.react-bs-table-sizePerPage-dropdown .dropdown-item a {
	display: block;
	color: #000;
	text-decoration: none;
}

.react-bs-table-sizePerPage-dropdown button {
	background: #fff;
	color: #000;
}

/**
  * Using <Link/> instead of <a/> omits default theme styles on cart and wishlist pages. This is the fix
**/

.cart-section tbody tr td img,
.wishlist-section tbody tr td img {
	height: 90px;
}

/* Header fixes */

.mobile-wishlist,
.mobile-account {
	@include max-width($lg) {
		display: none;
	}
}

.search-box {
	@include max-width($lg) {
		display: none;
	}
}

.main-menu {
	&.with-mobile-fixes {
		display: flex;

		.menu-left {
			@include max-width($lg) {
				flex: 1;
			}

			.brand-logo {
				display: flex;
				align-items: center;

				@include max-width($md) {
					padding-top: 0;
					padding-bottom: 0;
				}

				.toggle-nav {
					margin-right: 0.5rem;
				}
			}
		}

		.call-us.contact-right {
			@include max-width($xl) {
				display: none;
			}
		}
	}

	.mobile-navigation-icons {
		display: none;

		@include max-width($lg) {
			display: block;
		}

		a {
			font-size: 1rem;
			font-weight: 600;
			color: $grey;

			&.account {
				margin-right: 0.7rem;
				font-size: 0.9rem;
			}

			i {
				color: $green;
			}
		}
	}
}

.badges {
  display: flex;
  align-items: flex-start;
  position: relative;
  opacity: .6;

  &:hover {
    opacity: 1;
  }

  .trustedsite-trustmark {
    flex: 1;
  }
}

#godaddy-security-badge.godaddy-security-relative {
  display: none !important;

  &.visible {
    display: block !important;
  }
}

.pac-container {
	// padding: 1rem;

	.pac-item {
		padding: .3rem .5rem;
		
		&:hover {
			cursor: pointer;
			background-color: rgb(238, 238, 238);
		}
	}
}

.tab-product {
	margin-top: 0 !important;
	padding-top: 0;
}

p {
	font-size: .9rem;
	line-height: 1.7rem;
}

.blurred {
  filter: blur(3px);
}

a:focus {
	outline: none;
}

.infinite-scroll-component {
	overflow: hidden !important;
}

.btn:active, .btn:focus {
	border-color: transparent !important;
	box-shadow: none !important;
}
a {
	text-decoration: none;
}

.cms-single-page {
	.post-full {
		h3 {
			margin-top: 1.5rem;
		}

		p {
			margin-bottom: 1rem;
		}
	}
}