@import '../sass/sanity.module.scss';
@import './bootstrap';
@import './color1';

@import './nojs-menus.scss';
@import './utility/themify';
@import './variables';

// Utilities
@import './utility/mixins';
@import './utility/helpers';

// Pages
@import './components/page';

// Components
/*! purgecss start ignore */
@import './components/horizontal-loader';
@import './components/smart-search';
@import './components/search-feed-item';
@import './components/map';
@import './components/location-details';
@import './components/filters';
@import './components/header';
@import './components/checkout-content';
@import './components/product';
@import './components/gallery';
@import './components/not-available';
@import './components/home-categories';
@import './components/cart';
@import './components/product-box';
@import './components/product-sidebar';
@import './components/order';
@import './components/licence-plate-search';
@import './components/account-login-register';
@import './components/empty-alert';
@import './components/add-to-cart-selector';
@import './components/ad-banner';
@import './components/update-location-modal';
@import './components/location-permission-modal';
@import './components/dashboard-elements';
@import './components/business-registration-form';
@import './components/feedback-modal';
@import './components/roadside-assistance';
@import './components/newsletter';
@import './components/full-page-loader';
@import './components/validation-message';
@import './components/faq-elements';
@import './components/provider-details';
@import './components/modal';
@import './components/new-product-main-form';
@import './components/category-section';
@import './components/footer';
@import './components/brands-section';
@import './components/category-search';
@import './components/category-elements';
@import './components/pagination';
@import './components/why-1800-battery';
@import './components/collection-title';
@import './components/preference-question-modal';
@import './components/nprogress';
@import './components/input-variables';
@import './components/aaa-card';
@import './components/welcome-module';
@import './components/bio';
@import './components/reviews';
@import './components/price-comparison';
@import './components/landing';
@import './components/contact-modal';
@import './components/video-modal';
@import './components/video-modal';
@import './components/becoming-provider-sections';
@import './components/flow-preview';
@import './components/fitment-verification';
@import './components/location-required-overlay';
@import './components/place-selection';
@import './components/upsell-overlay';

@import './components/update-order';
@import './components/order-options';
@import './components/date-time-picker';
@import './components/product-options';
@import './components/cms-single-page';

@import './utility/themes';
@import './components/starting-time';
@import './components/webchat';
@import './components/general';
