.admin-order {
  overflow-y: hidden;

  #custom-gladly-chat-button {
    display: none;
  }

  .header-new {
    display: none;
  }

  .burger-menu-container {
    display: none;
  }

  .footer-light {
    display: none;
  }

  .order-options {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
    padding: 2rem;

    .cc-wrapper {
      margin-top: 1rem;
      

      .header {
        display: none;
        
        h3 {
          font-size: 1rem;
        }
      }

      .payment-methods-container {
        background-color: #f7f7f9;
        border: 1px solid #e4e7ed;

        .payment-methods-header {
          display: none;

          a {
            background-color: #f7f7f9;
          }
        }

        .btn-primary  {
          background: #fff;
          border-color: #b7c6fe;
          border-radius: 2px;
          -webkit-box-shadow: none !important;
          box-shadow: none !important;
          color: #4b71fc;
          font-weight: 400;
          line-height: 1;
          padding: 6px 9.0005px;
          font-size: 0.9rem;
          border: none;
          background-color: #273A8A;
          color: #fff;
          padding: .5rem;
          width: 100%;
          height: 40px;
        }
      }
    }

    .react-datepicker-wrapper {
      width: 100%;
    }

    .form-control, input {

      display: block;
      width: 100%;
      padding: 0.375rem 0.75rem;
      font-size: 1rem;
      line-height: 1.5;
      color: #495057;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #ced4da;
      border-radius: 0.25rem;
      -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
      transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
      border-color: #e3e9ee !important;
      width: 100%;
      border-radius: 0;
    }

    .btn {
      background: #fff;
      border-color: #b7c6fe;
      border-radius: 2px;
      box-shadow: none!important;
      color: #4b71fc;
      font-weight: 400;
      line-height: 1.0;
      padding: 6px 9.0005px;
      font-size: .9rem;

        &.admin-actions {
            background-color: #D2B276;
            color: #000;
        }
    }

    .admin-actions-container {
        position: fixed;
        overflow-y: scroll;
        top: 0;
        right: 0;
        height: 100%;
        width: 100%; 
        background-color: rgb(255 255 255);
        z-index: 10;
        box-shadow: -2px 0 8px 6px #adadad36;
        right: -650px;
        transition: all .2s ease-in-out;

        &.visible {
          right: 0;
        }

        .element {
          padding:  0 1rem;
          display: flex;
          gap: .7rem;
          // box-shadow: 0 7px 6px -5px #cbc6c6;
          // padding-bottom: 1rem;

          .icon {
            width: 25px;

            img {
              margin-top: .5rem;
              width: 100%;
            }

            i {
              margin-top: .5rem;
              font-size: 1.5rem;
              color: #4b71fc75;
            }
          }

          .content {
            flex: 1;
          }
          
        }

        .inner {
          position: relative;

          .btn.close-overlay {
            position: absolute;
            top: -1rem;
            left: -50px;
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #fff;
            box-shadow: 0 0 6px 0px #cacaca;
            border-radius: 100px;
            font-size: 1.1rem;
            z-index: 10;
            color: grey;

            &:hover {
              box-shadow: 0 0 6px 0px #a0a0a0;
              opacity: 1;
            }
          }
        }

        .header {
          margin-bottom: 1.5rem;
          display: block;
          background-color: #E4E7ED;
          padding: 1rem;

          h2 {
            font-size: 1.2rem;
            margin-bottom: 0;
            color: #34313f;

            span {
              font-weight: 600;
            }
          }

          .customer {
            margin-top: .8rem;
            margin-bottom: 0;
            display: flex;
            align-items: center;
            gap: .5rem;

            i {
              color: #ACBEFE;
            }

            a {
              text-decoration: none;
              border-bottom: 2px solid #cbd6fc;
              color: rgb(65, 65, 65);
              padding-bottom: .2rem;
              font-weight: 600;
              font-size: 1rem;
              
              i {
                color: #4b71fcbe !important;
              }

              &:hover {
                border-color: #ACBEFE;
              }
            }

            .action-btn-local {
              background-color: rgb(255, 255, 255);
              padding: 0;
              border-radius: 100px;
              width: 32px;
              height: 32px;
              display: flex;
              align-items: center;
              justify-content: center;
              border: 1px solid #4b71fc71;

              &:hover {
                color: #4b71fc;
              }
            }
          }
        }

        .action-title { 
            color: #4b71fc;;
            // font-weight: 600;
            // text-transform: uppercase;
            font-size: .9rem;

            &.form-expander {
                margin-bottom: 0;
                padding-bottom: .5rem;
                display: flex;
                justify-content: space-between;
                width: 100%;
                text-align: left;
                padding: .7rem .5rem;

                &:active, &:focus {
                  border: 1px solid #4b71fc !important;;
                }
                

                &:hover {
                    color: #4b71fc !important;
                    cursor: pointer;
                    border: 1px solid #4b71fc !important;;
                }
            }
        } 

        .form {
            background-color: #f7f7f9;
            padding: 1rem;
            border: 1px solid #e4e7ed;
            box-shadow: 0 16px 14px -15px #273a8a73;
            padding-bottom: 1rem;
            margin-bottom: 1.5rem;
            

            h5 {
              color: #2b2b2b;
              display: block;
              font-size: 12px;
              font-weight: 400;
              letter-spacing: 0.4px;
              margin-bottom: 4px;
              text-transform: uppercase;
            }

            .product-items {
              .product-item {
                display: flex;
                align-items: center;
                background-color: rgba(0, 0, 0, 0.041);
                padding: .5rem;

                img {
                  width: 32px;  
                  margin-right: 1rem;
                }

                h6 {
                  margin-bottom: 0;
                }
              }
            }

            .form-control {
                border-color: #d4d4d4;
            }

            label {
                color: #000000;
                display: block;
                font-size: 12px;
                font-weight: 400;
                letter-spacing: 0.4px;
                margin-bottom: 4px;
                text-transform: uppercase;
            }

            .place-selection {
                margin: 0;

                label {
                    display: none;
                }
            }
        }

        .final-status-container {
          position: relative;
          
          .status-overlay {
            padding: 1rem;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(255, 255, 255, 0.932);
            border-radius: 5px;
            z-index: 99;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .btn {
              margin-top: 1rem;
              width: 70px;
            }
          }
        }

        .btn-group {
          padding-top: 1rem;
          margin-top: 1rem;
          border-top: 1px solid rgb(212, 216, 231);
          display: flex;
          justify-content: space-between;

          .btn {
              min-width: 80px;
              height: auto;
              padding: .7rem;
              border-radius: 3px;

              &.submit {
                border: none;
                background-color: #273A8A;
                color: #fff;
              }
          }
      }
    }

    .tech-element {
      border: 1px solid #d7d6d9;
      margin-top: .5rem;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex: 1;

      .clickable {
        display: flex;
        padding: .5rem;
        flex: 1;

        &:hover {
          cursor: pointer;
          background: #273b8a0c;

          .name {
            color: #273A8A;
          }

          i {
            color: #4b71fc;
          } 
        }
      }

      &.online {
        background: none;
        background: linear-gradient(315deg, rgba(246,247,249,0) 0%, rgba(123,192,59,0) 97%, rgba(123,192,59,1) 97%);
      }

      &.offline {
        background: none;
        background: linear-gradient(315deg, rgba(246,247,249,0) 0%, rgba(195,195,195,0) 97%, rgb(155, 155, 155) 97%);
      }

      // &.is-assigned {
      //   border-color: #b7c6fe;

      //   .assign-status {
      //     i {
      //       color: #273A8A !important;
      //     }
      //   }
      // }

      &.single {
        margin: 0;
        border: none;

        .actions {
          padding-right: 0;
        }

        .assign-status {
          i {
            color: #273A8A !important;
          };
        }

        &.online {
          &:hover {
            cursor: default;
            background: linear-gradient(315deg, rgba(246,247,249,0) 0%, rgba(123,192,59,0) 97%, rgba(123,192,59,1) 97%);
          }
        }
  
        &.offline {
          &:hover {
            cursor: default;
            background: linear-gradient(315deg, rgba(246,247,249,0) 0%, rgba(195,195,195,0) 97%, rgba(196,196,196,1) 97%);
          }
         
        }

        &:hover {
          cursor: default;
        }

        .name {
          color: #273A8A !important;
        }
      }

      &:hover {
        // background-color: #b7c6fe59;
      
      }
  

      .assign-status {
        display: flex;
        align-items: center;

        i {
          font-size: 1.3rem;
          margin-right: .5rem;
          color: rgb(196, 196, 196);

          &.fa-check-circle {
            color: #4b71fc;
          }
        }
      }

      .details {
        flex: 1;
        list-style-type: none;
        padding: 0;
        margin: 0;

        li {
          font-size: .80rem;
          color: rgb(66, 66, 66);

          b {
            color: #000;
          }

          &.pair {
            display: flex;

            span {
              display: block;
              flex: 1;

              &:first-child {
                flex: .8;
              }

              &.value {
                color: #000;
              }
            }
          }
        }

        .name {
          font-weight: 600;
          color: #000;
          font-size: .85rem;
        }
        
      }

      .actions {
        margin: 0;
        gap: .5rem;
        padding: 1rem;
        padding-right: 1rem;
        padding-left: .5rem;
        list-style-type: none;


        li {
          // flex: 1;

          &:last-child {
            .btn {
              margin-bottom: 0;
            }
          }

          .btn {
            margin-bottom: .5rem;
            width: 70px;

            &.btn-primary {
              font-weight: 600;
            }
          }
        }
      }
    }
  }
}

.order-options {
  display: none;
}

.order-success {
    position: relative;
}

.sms-modal {
  background-color: rgb(255, 255, 255);
  width: 400px;
  padding: 0;

  .header {
    padding: 1rem;
    padding-right: 2rem;

    i {
      color: grey;
    }
  }

  .footer {
    padding: 1rem;
    padding-top: 0;
  }

  .body {
    .form {
      background-color: #f7f7f9;
      padding: 1rem;
      border: 1px solid #e4e7ed;
      -webkit-box-shadow: 0 16px 14px -15px rgb(39 58 138 / 45%);
      box-shadow: 0 16px 14px -15px rgb(39 58 138 / 45%);
      padding-bottom: 1rem;
      margin-bottom: 1.5rem;

      textarea {
        font-size: .9rem !important;
      }

      .label {
        color: #606f7b;
        display: block;
        font-size: 12px;
        font-weight: 700;
        letter-spacing: 0.4px;
        margin-bottom: 4px;
        text-transform: uppercase;
      }

      .btn-group {
        padding-top: 1rem;
        margin-top: 1rem;
        border-top: 1px solid rgb(212, 216, 231);
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: justify;
        justify-content: space-between;

        .btn {
          background: #fff;
          border-color: #b7c6fe;
          border-radius: 2px;
          -webkit-box-shadow: none !important;
          box-shadow: none !important;
          color: #4b71fc;
          font-weight: 400;
          line-height: 1;
          padding: 6px 9.0005px;
          font-size: 0.9rem;
      
          min-width: 80px;
          height: auto;
          padding: 0.7rem;
          border-radius: 3px;

          &.submit {
            border: none;
            background-color: #273A8A;
            color: #fff;
          }
        }
      }
    }
    
  }

  .btn-call {
    background: #fff;
    border-color: #b7c6fe;
    border-radius: 2px;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    color: #4b71fc;
    font-weight: 400;
    line-height: 1;
    padding: 6px 9.0005px;
    font-size: 0.9rem;

    min-width: 80px;
    height: auto;
    padding: 0.7rem;
    border-radius: 3px;
    margin: 0 auto;
    display: block;
  }
}