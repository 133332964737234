.shop-page-content {
	padding-top: 1.5rem;
}

.page {
	// padding-top: 1rem;
	padding-bottom: 1rem;
	background-color: #F3F3F3;

	.header {
		margin-top: 1.5rem;
		margin-bottom: 1.5rem;
		padding-bottom: 1.5rem;
		border-bottom: 1px solid rgba(0, 0, 0, 0.05);

		h1 {
			margin: 0;
			font-size: 1.9rem;
			color: #5a5a5a;
		}
	}

	&.all-categories {
		.category-section {
			h6 {
				font-size: 1rem;
				margin-top: 1rem;

				display: block;
				margin-bottom: 1rem;
				padding: 0.5rem 0;
				background: #f3f3f3;
				text-transform: uppercase;
				font-weight: 600;

				@include themify($themes) {
					color: darken(themed('colorSecondary'), 10%);
					border-bottom: 2px solid rgba(themed('colorSecondary'), 0.3);
				}
			}
		}
	}

	&.dashboard {
		padding-top: 1rem;
	}

	&.add-business {
		padding-top: 2rem;
		padding-bottom: 2rem;
		background-size: auto 100%;

		h1 {
			text-transform: none;
			font-weight: 400;
			font-size: 2rem;
			margin-top: 0;
			color: darken($blue-light, 20%);
			margin-bottom: 1rem;

			span {
				color: grey;
			}
		}
	}

	&.collection {
		padding-top: 1.5rem;
		padding-bottom: 0;

		.top-banner-content {
			margin-bottom: 1rem;
			padding: 0.5rem 0.5rem;
			border-radius: 3px;
			background: linear-gradient(
				80deg,
				rgba(255, 255, 255, 1) 0%,
				rgba(255, 255, 255, 1) 35%,
				rgba(117, 159, 63, 0) 96%
			);

			@include max-width($lg) {
				margin-bottom: 1rem;
			}

			h4 {
				font-weight: 400;
				font-size: 1.35rem;
				line-height: 1.6rem;
				color: #4a4a4a;

				@include min-width($xl + 1) {
					display: flex;
					align-items: center;
					margin: 0;
				}

				@include max-width($lg) {
					font-size: 1.2rem;
				}

				@include max-width($md) {
					font-size: 1rem;
				}

				span {
					&.intro {
						color: darken($green, 20%);
						margin-right: 0.5rem;
					}

					&.focused {
						font-weight: 600;
					}

					&.address {
						border: 2px solid rgba($red, 0.4);
						display: inline-block;
						padding: 0.3rem 0.8rem;
						border-radius: 3px;
						margin-left: 0.5rem;

						@include max-width($xl) {
							display: block !important;
							margin-left: 0;
							margin-top: 0.5rem;
						}

						.inner {
							display: flex;
							align-items: center;

							@include max-width($md) {
								flex-direction: column;
								align-items: flex-start;
							}

							.address-text {
								font-weight: 600;
							}

							.btn {
								font-size: 0.66rem;
								background: #f9dbdb;
								color: #794646;
								margin-left: 0.5rem;
								border-radius: 5rem;
								padding: 0.1rem 1rem;
								border: 1px solid #efd3d3;

								@include max-width($md) {
									margin-left: 0;
								}

								&:hover {
									background-color: $red;
									border-color: $red;
									color: white;
								}
							}
						}
					}
				}
			}

			h6 {
				color: rgb(78, 78, 78);
			}
		}

		.page-title {
			font-weight: 400;
			font-size: 1.6rem;
			line-height: 1.6rem;
			color: #4a4a4a;
			margin-bottom: 1rem;

			@include max-width($md) {
				line-height: 1.6rem;
				font-size: 1.2rem;
				margin-bottom: 0;
			}

			.current-level {
				font-weight: 600;
				color: #000;
			}

			.selected-id {
				display: block;
				font-size: 1rem;
				color: grey;
			}
		}

		.mobile-disclaimer {
			font-size: 0.9rem;
			background: #fff;
			background-color: #f3f3f3;
			padding: 0.5rem 0.5rem;
			position: relative;
			background: white;
			background: linear-gradient(41deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 50%, #bfeaf9 100%);
			border-radius: 3px;
			border: 2px solid #c2ecf8;
			display: none;
			flex-direction: row;
			line-height: 1.2rem;
			color: #143b46;
			text-align: center;
			margin-bottom: 0.7rem;
			flex: 1;
			text-transform: none;
			text-align: left;

			@include max-width($lg) {
				display: flex;
				flex-direction: row;
				align-items: center;
			}
		}

		.page-description {
			line-height: 1.1rem;
			background-color: rgba($blue, 0.1);
			padding: 0.7rem;
			color: darken($blue, 20%);
			margin: 1rem 0;

			@include max-width($md) {
				display: none;
			}
		}
	}

	&.search {
		background-color: #f3f3f3;

		.section-title {
			color: $grey6;
			font-size: 1.3rem;
			border-bottom: 1px solid rgba(0, 0, 0, 0.1);
			padding-bottom: 0.5rem;
			margin-bottom: 1rem;
			margin-top: 2rem;
		}

		.ais-Hits-list {
			display: flex;
			flex-wrap: wrap;

			.ais-Hits-item {
				@include min-width($lg) {
					width: 24%;
					margin-right: 1.33%;
					margin-bottom: 1.33%;

					&:nth-child(4n) {
						margin-right: 0;
					}
				}

				@include max-width($lg) {
					width: 31%;
					margin-right: 2%;
					margin-bottom: 2%;
				}

				@include max-width($sm) {
					width: 48%;
					margin-right: 2%;
					margin-bottom: 2%;
				}
			}
		}
	}

	&.empty-results {
		text-align: center;

		&.not-found-404 {
			text-align: center;

			.page-title {
				border-color: rgba(255, 166, 0, 0.534);
				padding: 1rem 0;
				border-radius: 0;

				i {
					color: goldenrod;
				}
			}

			.help {
				p {
					font-size: 1.2rem;
				}
			}
		}

		.page-title {
			font-size: 2rem;
			font-weight: 600;
			display: inline-block;
			text-align: center;
			color: #5e5e5e;
			line-height: 2rem;
			margin: 3rem auto 1rem auto;
			padding-bottom: 1rem;
			border-bottom: 3px solid rgba($blue, 0.4);
		}

		.help {
			margin: 0 auto;
			width: 1000px;
			max-width: 100%;

			h6 {
				font-size: 1.2rem;
				text-transform: uppercase;
				margin-bottom: 1.4rem;

				@include max-width($sm) {
					font-size: 1rem;
				}
			}

			.help-elements {
				display: flex;
				justify-content: space-between;

				@include max-width($md) {
					flex-direction: column;
				}

				.help-element {
					background-color: #fff;
					border-radius: 3px;
					box-shadow: 0 0 7px -2px rgba(0, 0, 0, 0.1);
					padding: 1rem;
					flex: 1;
					margin-right: 1rem;
					display: flex;
					align-items: center;
					border: none;
					transition: 0.2s all;
					color: rgb(66, 66, 66);
					text-decoration: none;

					@include max-width($md) {
						margin-bottom: 1rem;
					}

					i {
						margin-right: 1rem;
						font-size: 1.5rem;
						color: $blue;
					}

					p {
						margin-bottom: 0;
						font-size: 1.1rem;
					}

					&:hover {
						background-color: $blue;
						color: white;

						i {
							color: white;
						}

						p {
							color: white;
						}
					}
				}
			}
		}
	}

	&.locations {
	

		.header-with-filters {
			margin-bottom: 1.5rem;

			@include max-width($md) {
				margin-bottom: 1rem;
			}

			h1 {
				text-transform: none;
				font-size: 1.4rem;
				font-weight: 400;
			}
		}

		.locations-header-alert {
			background-color: #fff;
			background: url('../../static/images/landing/cover-merged.jpg') center bottom;
			background-size: cover;

			@include max-width($md) {
        background: url('../../static/images/landing/cover-mobile-2.jpg') center center;
        background-size: cover !important;

				.inner {
					background-color: rgb(255, 255, 255) !important;
					padding: 2rem 0;
				}
      }

			.inner {
				background-color: rgba(255, 255, 255, 0.534);
				padding: 4rem 0;
			}

			.content {
				width: 60%;

				@include max-width(767px) {
					width: 100%;
				}
			}

			h1 {
				color: black;
				font-size: 2.2rem;
				font-weight: 400;
				text-transform: none;
			}

			p {
				font-size: 1.1rem;
			}

			.btn {
				background-color: #484ab7;
				color: white;
				margin-right: 0.5rem;
				text-transform: uppercase;
				font-size: .9rem;
				font-weight: 600;
				padding: .7rem 1.5rem;
				border: none;

				&:hover {
					background-color: #5c5ecc;
				}
			}
		}

		.map-loading {
			position: relative;

			.front {
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				background-color: rgba(235, 235, 235, 0.658);
				text-align: center;

				.inner {
					margin-top: 1rem;
					display: inline-block;
					background-color: red;
				}
			}
		}
	}

	&.checkout {
		background-color: #f3f3f3;
		min-height: 100vh;
		padding-top: 1rem;

		@include max-width($md) {
			background-color: #fff;
			box-shadow: 0 11px 20px 0px #afafaf;
		}

		.container {
			width: 1100px;
			max-width: 100%;
		}

		.loading-container {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;

			h6 {
				font-size: grey;
				margin-top: 0.5rem;
				font-size: 1.2rem;
			}

			i {
				color: grey;
			}
		}

		.page-checkout-content {
			display: flex;
			justify-content: flex-start;
			margin-bottom: 150px;

			@include max-width($md) {
				flex-direction: column;
			}

			.step-content {
				flex: 2;
			}

			.cart-content {
				flex: 1.3;
				margin-left: 1rem;

				@include max-width($md) {
					margin-left: 0;
					margin-top: 1rem;
				}
			}
		}
	}

	&.product {
		background-color: #f3f3f3;
	}
	
}
