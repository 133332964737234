.blurred {
  -webkit-filter: blur(5px);
  filter: blur(5px);
}

.hidden {
  display: none !important;
}

.position-relative {
  position: relative !important;
}

.ml-05 {
  margin-left: .5rem !important;
}