.preference-question-modal {
  // max-height: 90%;
  // position: relative;
  // padding-bottom: 50px;
  // max-height: 100%;
  // overflow-y: auto;
  padding: .5rem;

  .react-responsive-modal-closeButton {
    display: none; 
  }

  .header {
    margin-bottom: 0;

    h3 {
      color: #0b7a9a !important;
      font-weight: 600 !important;
    }
  }
  
  .item {
    display: block;
    padding: 1rem .5rem .5rem .5rem;
    border: none;

    input {
      height: 0 !important;
    }

    &:hover {
      cursor: auto;
      border: none;
    }

    h3 {
      font-size: 1.1rem;
      color: rgb(37, 37, 37);
      margin-bottom: 1rem; 
    }

    .btn {
      background-color: #fff;
      font-size: .89rem;
      color: #0b7a9a;
      transition: .2s;
      padding: .5rem .5rem;
      text-transform: none;
      text-align: left;
      margin-bottom: .5rem;
      border: 2px solid #eff8fc;
      font-weight: 400; 
      display: flex;

      i {
        margin-right: .5rem;
      }

      &.selected {
        font-weight: 600;
        border-color: #023b4b2e;
        background-color: #bbe3f23d;

        &:active {
          border-color: #023b4b2e !important;
        }

        &:focus {
          border-color: #023b4b2e !important;
        }
      }

      &:active {
        color: #0b7a9a;
      }

      &:hover {
        border-color: #0765802e;
      }

      .main-text {
        display: block;
        white-space: normal;
      }

      .help-text {
        display: block;
        font-size: .8rem;
        line-height: 1rem;
        font-weight: 400;
        white-space: normal;
      }
    }

    .btn-group {
      display: flex;
      flex-direction: column;
    }
  }

  .submit-btn {
    background-color: #0ebcee;
    border: none;
    font-size: .9rem;
    padding-left: 1rem;
    padding-right: 1rem;
    color: white;
    border-radius: 2px;
  }

  .css-kt7tn6-control {
    &:hover {
      border-color: #0765802e;
    }
  }

  .css-n6tzyr-singleValue {
    .description {
      display: none !important;
    }
  }

  .complex-selection {
    display: flex;

    .indicator {
      display: block;
      margin-right: .5rem;
      background: none;
    }

    .text {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;

      span {
        display: block;

        &.description {
          font-size: .8rem;
        }
      }
    }
  }

  .preference-question-modal-inner {
    

    .footer {
      // height: 40px;
      // position: absolute;
      // bottom: 0;
      // left: 0;
      width: 100%;
    }
  }
}