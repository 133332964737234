.gallery {

  .preview-container {
    border: 1px solid #e4e4e4;
    background: #fff;
    height: 450px;
    position: relative;
    overflow: hidden;

    @include max-width(991px) {
      height: 300px;
    }
    
    .preview-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 1rem;
    }

    .preview {
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      width: 100%;
      height: 100%;
    }
  }

  .selection-container {
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    margin-top: 1rem;
    

    .selection-item {
      border: 2px solid #e4e4e4;;
      background-color: #fff;
      margin-right: .5rem;
      padding: .5rem;
      display: flex;
      align-items: center;
      opacity: .7;
      width: 100px !important;
      height: 100px !important;
      flex-shrink: 0;

      @include max-width(767px) {
        width: 64px !important;
        height: 64px !important;
      }

      &.active {
        border-color: $primary;
        opacity: 1;

        @include themify($themes) {
          border-color: themed('colorPrimary');
        }
      }

      &:hover {
        border-color: $primary;
        @include themify($themes) {
          border-color: themed('colorPrimary');
        }
        cursor: pointer;
        opacity: 1;
      }
    }
  }
}