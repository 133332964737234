.product-options {

  h6 {
    margin-bottom: 0;
    font-size: .85rem;
    color: #000000;
    text-transform: uppercase;
    font-weight: 400;
  }

  .error-text {
    display: none;
  }

  .css-1pahdxg-control {
    box-shadow: none;
    border-radius: 3px;
  }

  .css-yk16xz-control {
    background: none;
  }

  .option-item {
    &.error {
      .css-yk16xz-control {
        border-color: red;
      }

      .error-text {
        display: block;
      }
    }

    .error-text {
      color: rgb(235, 49, 49);
      margin-top: .5rem;
    }
  }
}