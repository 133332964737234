.price-comparison {
  position: relative;

  @include max-width($md) {
    display: none;
  }

  &.is-mobile {
    display: none;
    margin-bottom: 1rem;

    @include max-width($md) {
      display: block;
    }
  }

  &:hover {
    .content-container {
      display: block;
    }
  }

  .btn {
    padding: .3rem 1rem;
    text-transform: none;
    background: #e8f8ff;
    border: 2px solid #b6ecff;
    color: #0b7a9a;
    border-radius: 3px;
  }

  .content-container {
    display: none;
    background-color: #fff;
    padding: .5rem;
    border: 2px solid #C7EEFC;
    border-top: 0;
    position: absolute;
    top: 32px;
    left: 0;
    width: 100%;
    z-index: 999;
    box-shadow: 1px 3px 24px rgba(0,0,0,0.2);

    h6 {
      color: black;
      font-size: 1.1rem;

      span {
        font-weight: 600;
      }
    }

    ul {
      display: flex;
      border-top: 1px solid rgb(216, 216, 216);

      li {
        flex: 1;
        padding: .5rem 0;
      }
    }
  }
}