@keyframes slidein {
  from {
    bottom: -100px;
    opacity: 0;
  }

  to {
   bottom: 0;
   opacity: 1;
  }
}

.upsell-overlay {
  // position: fixed;
  // // left: 0;
  // width: 100%;
  display: flex;
  align-items: flex-end;
  z-index: 11;
  // background: url('../../static/images/landing/map-2.jpg') center center;
  // box-shadow: 0 -20px 23px 2px #f3f3f3;
  background-size: cover;

  @include min-width(992px) {
    // background: url('../../static/images/landing/cover-merged.jpg') bottom center;
    // background-size: cover;
  }

  &.active {
    animation-duration: .7s;
    animation-name: slidein;
    animation-fill-mode: forwards;  
  }

  .inner-outside {
    box-shadow: inset 0 85px 40px -10px #f3f3f3c4;
    margin: 0 auto;
    width: 100%;
    position: relative;
    
    @include min-width(992px) {
      background: rgb(255,255,255);
      background: radial-gradient(circle, rgba(255,255,255,.5) 0%, rgba(255,255,255,.5) 40%, rgba(255,255,255,0) 100%);
    }
    
    .close-overlay {
      position: absolute;
      padding: 0;
      top: 1rem;
      right: 1rem;
      background: #fff;
      font-size: .9;
      padding: 0rem 1rem;

      @include max-width(991px) {
        top: -.8rem;
      }
    }
  }

  .inner {
    margin: 0 auto;
    max-width: 900px;

    h3 {
      color: rgb(39, 39, 39);
      margin-bottom: 1rem;
      text-align: left;
      font-size: 1.3rem;

      @include min-width(992px) {
        font-size: 1.3rem; 
      }

      span {
        font-weight: 600;
      }
    }

    .body {
      display: flex;
      flex-direction: column;
      
      ul {
        display: flex;
        flex-direction: column;
        list-style-type: none;
        padding: 0;

        @include max-width(992px) {
          flex-direction: column;
        }

        li {
          font-size: .9rem;
          margin-bottom: .2rem;
          color: black;
          padding-left: 1.3rem;
          position: relative;

          &:last-child {
            margin-bottom: 0;
          }

          img {
            height: 18px;
            margin-top: -10px;
            margin-left: 7px;
          }

          i {
            color: $green;
            position: absolute;
            top: .2rem;
            left: 0;
            margin-left: 0rem;
          }
        }
      }

      .btn-container {
        width: 100%;
      }

      .btn {
        background-color: $purple;
        color: white;
        display: flex;
        align-items: center;
        border-radius: 3px;
        border: none;
        padding: .8rem;
        font-size: 1rem;
        text-transform: none;
        max-width: 100%;
        text-align: center;
        justify-content: center;
        margin-right: auto;
        margin-left: auto;
        margin-top: 1.2rem;

        &:hover {
          background-color: darken($purple, 10%);
        }

        @include min-width(992px) {
          padding: .7rem 2rem;
          font-size: 1rem;
        }
      }
    }

    .footer {
      text-align: center;
      background-color: #fff;
      border-radius: 7px;
      padding: .5rem;
      margin-top: 1rem;

      @include min-width(992px) {
        background-color: rgba(255, 255, 255, 0);
        margin-top: 0;
      }

      .inline-reviews {
        display: flex;
        align-content: center;
        justify-content: center;

        span {
          margin-left: .4rem;
        }
      }

      p {
        color: #000;
        margin-bottom: 0;
        font-size: 1rem;
        margin-bottom: .5rem;
      }

      .mobile-reviews {
        display: none;
        @include max-width(991px) {
          display: block;
        }
      }
  
      .desktop-reviews {
        display: none;
        
        @include min-width(992px) {
          display: block;
        }
      }
    }
  }
}