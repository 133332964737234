@keyframes change-border-color {
	0% {
		border: 1px solid #ccc;
	}
	20% {
		border: 1px solid $red;
	}
	100% {
		border: 1px solid #ccc;
	}
}

.smart-search-container {
	margin-bottom: 0.5rem;

	.smart-search {
		display: flex;
		flex-direction: row;

		@include max-width($sm) {
			flex-direction: column;
		}
		.btn {
			background-color: grey;
			color: white;
			border-radius: 3px;

			@include themify($themes) {
				background-color: themed('colorPrimary');
			}

			@include max-width($sm) {
				&.btn-success {
					display: none;
				}
			}

			&:hover {
				background-color: darken($primary, 10%);

				@include themify($themes) {
					background-color: darken(themed('colorPrimary'), 10%);
				}
			}
		}
	}
}

.category-selection {
	flex: 1;
	@include max-width($sm) {
		flex: 1;
		margin-right: 0 !important;
		margin-bottom: 0.5rem;
	}

	#react-autowhatever-1 {
		max-height: 300px;
		overflow: hidden;
		overflow-y: auto;
	}
	.autosuggest-container {
		position: relative;

		input {
			font-size: .9rem !important;
			width: 270px;
			border-radius: 3px;
			border: 1px solid rgb(211, 216, 224) !important;

			@include max-width(1429px) {
				width: 210px;
			}

			@include max-width($xl) {
				width: 100%;
			}
		}

		button {
			position: absolute;
			right: 16px;
			top: 1px;
			height: 38px;
			background: none;
			border: none;
			outline: none;
			color: #aaaaaa;

			&:hover {
				cursor: default;
				color: #8e8e8e;
			}
		}

		ul {
			li {
				&:hover {
					background-color: #efefef !important;
				}
			}
		}
	}
}

#category-selection {
	&.error {
		input {
			border: 1px solid red !important;
		}
	}
}

.place-selection {
	display: flex;
	position: relative;
	border-radius: 3px;
	flex: 1;

	.input-pack {
		input {
			@include max-width(991px) {
				width: 100% !important;
			}
		}
	}

	.location-icon-container {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 38px;
		height: 38px;

		i {
			opacity: 0.5;
		}
	}

	.geo-search {
		background-color: #97c956;
		transition: ease 0.3s;
		cursor: pointer;
		i {
			opacity: 1;
			color: white;
		}

		&:hover {
			background-color: #85be3b;
		}
	}

	.input-loading-status {
		width: 100%;
		height: 40px;
		background-color: rgba(255, 255, 255, 0.95);
		border: 1px solid #ccc;
		padding-left: 16px;
		border-radius: 3px;
		position: absolute;
		bottom: 0;
		left: 0;
		z-index: 1;
		display: flex;
		align-items: center;
		color: black;
		font-size: .9rem;
		color: grey;

		i {
			margin-right: 0.5rem;
		}

		&.address-search {
			top: 0;
			left: 0;
		}
	}
	@include max-width($sm) {
		flex: 1;
		margin-right: 0 !important;
	}

	input {
		width: 270px !important;
		border-radius: 3px;
		border: 1px solid rgb(122, 140, 168);
		background-color: #fff;
	}

	.my-location {
		background: $grey-darken !important;
		border: none;
		width: 100%;
		cursor: pointer;
		text-align: left;
		padding: 7px 10px;
		font-weight: 400;
		text-transform: none;
		font-weight: 600;
		color: #fff;

		i {
			color: $primary;

			@include themify($themes) {
				color: darken(themed('colorPrimary'), 10%);
			}
		}

		&:hover {
			background-color: darken($grey-darken, 10%);
			color: $primary;

			@include themify($themes) {
				color: themed('colorPrimary');
				background-color: darken(themed('colorPrimary'), 10%);
			}
		}
	}

	&.checkout {
		margin-top: 1rem;
		margin-bottom: 1rem;
		display: flex;
		align-items: center;

		.ap-icon-pin {
			display: none;
		}

		input {
			width: 100% !important;
			font-size: 1.05rem;
			padding: 0.375rem 0.6rem !important;
			border-radius: 4px;
			height: 45px !important;

			&:focus,
			&:not(:placeholder-shown) {
				box-shadow: none;
				outline: none;
				padding-top: 0.8rem !important;
			}
		}

		.manual-input {
			flex: 2;
			position: relative;

			&.row-items {
				margin-top: 0 !important;
			}

			@include max-width($md) {
				width: 100%;
			}
		}

		.or {
			font-size: 0.8rem;
			font-weight: 600;
			color: grey;
			margin: 0 0.75rem;

			@include max-width($md) {
				margin: 0.5rem;
			}
		}

		.btn {
			padding-right: 0.2rem;
			padding-left: 0.2rem;
			font-size: 0.8rem;
			text-transform: none;
			border-radius: 0;
			background: none;
			color: darken($blue, 10%);
			font-weight: 400;
			padding: 0.2rem 0.3rem;
			line-height: 1rem;
			text-align: left;
			padding: 0;
			border-bottom: 1px solid rgba($blue, 0.1);

			&:hover {
				color: #152225;
			}
		}
	}
}

#place-selection {
	&.focus-color {
		.ap-input {
			&:focus {
				border-color: #13bbee;
				background: #eaf9ff;
			}
		}
	}

	&.error {
		input {
			border: 1px solid red !important;
		}
	}
}

.custom-highlight {
	display: flex !important;
	align-items: center;

	img,
	i {
		width: 45px;
		margin-right: 10px;
		text-align: center;

		&.smaller-thumb {
			width: 30px;
		}
	}

	i {
		color: #aaaaaa;
	}

	.ais-Highlight {
		font-size: .9rem;
	}

	.ais-Highlight-highlighted {
		padding: 0;
	}

	.ais-Highlight-highlighted {
		padding: 0;
		background: none;
		font-weight: 600;
		color: #000;
	}
}

p {
	&.section-title {
		margin-bottom: 0;
		padding: 10px 10px 5px 10px;
		border-bottom: 1px solid #dee2e685;
		text-transform: uppercase;
		font-weight: 600;
		font-size: 0.8em;
		color: #99cb58;

		@include themify($themes) {
			color: themed('colorPrimary');
		}
	}
}

@include max-width(767px) {
	/* Disable zoom on focus on iPhone */
	input[type='color'],
	input[type='date'],
	input[type='datetime'],
	input[type='datetime-local'],
	input[type='email'],
	input[type='month'],
	input[type='number'],
	input[type='password'],
	input[type='search'],
	input[type='tel'],
	input[type='text'],
	input[type='time'],
	input[type='url'],
	input[type='week'],
	select:focus,
	textarea {
		font-size: 16px !important;
	}
}
