.cart-container {
  margin-top: 2rem;
}

.cart-item-new {
  display: flex;
  padding: 1rem;
  background-color: #fff;
  border-radius: 3px;
  margin-bottom: 0.7rem;
  align-items: center;
  position: relative;
  box-shadow: 0 0 10px 0 #d5d5d5;

  .overlay-loading {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgb(255,255,255);
    background: radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(255,255,255,0.938813025210084) 35%, rgba(255,255,255,0) 100%);
  }

  @include max-width($md) {
    align-items: unset;
  }

  .image-container {
    margin-right: 1rem;

    img {
      width: 100px;
      border: 1px solid rgba(0, 0, 0, 0.1);

      @include max-width($md) {
        width: 64px;
      }
    }
  }

  .content-container {
    flex: 1;
    display: flex;
    flex-direction: column;

    .campaign-hypherlink {
      text-align: left;
      color: #0A357E;
      display: block;
      margin-top: .5rem;
      font-size: .8rem;
  
      span {
        font-weight: 600;
        border-bottom: 2px solid rgba(#0A357E, .4);
      }
  
      &:hover {
        color: darken(#0A357E, 10%);
        
        span {
          border-bottom: 2px solid rgba(#0A357E, 1);
        }
      }
    }

    h2 {
      margin-bottom: 0;
      font-size: 1.3rem;
      color: rgb(58, 58, 58);

      @include max-width($md) {
        font-size: 1.1rem;
      }
    }

    small {
      line-height: 0.5rem;
    }

    p {
      margin-bottom: 0.1rem;
      font-size: 0.9rem;
      line-height: 1.4rem;

      i {
        margin-right: 0.5rem;
      }
    }

    .central-part {
      flex: 1;
      display: flex;
      justify-content: space-between;

      @include max-width($lg) {
        align-items: center;
      }
    }

    .quantity {
      display: flex;
      flex: 1;
      margin-bottom: 0.5rem;

      @include max-width($xl) {
        margin-right: 0.3rem;
        margin-left: 0.3rem;
      }

      input {
        background: none;
        width: 50px;
        height: 30px;
        margin: 0 0.2rem;
        text-align: center;
        border-radius: 3px;
      }

      .btn {
        background: none;
        height: 30px;
        color: grey;
      }
    }

    .price {
      flex: 1;

      h2 {
        font-weight: 400;
      }
    }

    .total-price {
      width: 200px;
      text-align: right;

      @include max-width($xl) {
        width: 100px;
      }

      @include max-width($lg) {
        width: auto;
        flex: 1;
      }

      @include max-width($sm) {
        display: none;
      }

      h2 {
        font-size: 1.5rem;
        font-weight: 400;

        @include max-width($md) {
          font-size: 1.2rem;
        }
      }
    }

    .btn.remove {
      background: none;
      padding: 0;
      text-transform: none;
      font-weight: 400;
      color: $red;

      @include max-width($md) {
        margin-left: 0.5rem;
      }
    }

    .upper {
      display: flex;
      justify-content: space-between;
      //border-bottom: 1px dashed rgb(228, 228, 228);

      @include max-width($xl) {
        align-items: center;
      }

      @include max-width($lg) {
        flex-direction: column;
        align-items: unset;
        padding-bottom: 0.5rem;
      }

      .title {
        margin-bottom: 0.6rem;
        margin-right: 1rem;
        width: 500px;

        @include max-width($xl) {
          width: 400px;
        }

        @include max-width($xl) {
          width: auto;
        }

        @include max-width($lg) {
          width: auto;
          margin-right: 0;
        }
      }
    }

    .down {
      padding-top: 0.85rem;
      display: flex;
      justify-content: space-between;
    }
  }
}

.total-price-sub {
  text-align: right;
  margin-top: 1.5rem;

  h3 {
    color: black;
    font-size: 1.1rem;
    text-transform: uppercase;

    span {
      font-size: 1.6rem;
    }
  }
}

.action-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  align-items: center;

  .btn {
    border: none;
    padding: 0.7rem 1rem;
    min-width: 200px;
    border-radius: 3px;
    font-size: 1rem;
    text-transform: none;
    text-transform: uppercase;

    @include max-width($md) {
      min-width: auto;
    }

    i {
      margin-right: 0.35rem;
    }

    &.btn-success {
      background-color: $orange;
      color: white;

      &:hover {
        background-color: darken($orange, 10%);
      }
    }

    &.btn-success {
      background-color: $orange;
      color: white;

      &:hover {
        background-color: darken($orange, 10%);
      }
    }

    &.btn-secondary {
      padding: 0;
      background: none;
      color: grey;

      &:hover {
        color: rgb(68, 68, 68);
      }
    }
  }
}
