$themes: (
  default: (
    colorPrimary: $green,
    colorSecondary: $blue,
    buttonBackgroundColor: $green,
    buttonBorderColor: $green,
    buttonColor: #fff,
  ),
  
  continental: (
    colorPrimary: #FEA712,
    colorSecondary: #000,
    buttonBackgroundColor: #FEA712,
    buttonBorderColor: #FEA712,
    buttonColor: #fff
  ),

  ntb: (
    colorPrimary: #035DA9,
    colorSecondary: #FFC52F,
    buttonBackgroundColor: #FFC52F,
    buttonBorderColor: #FEA712,
    buttonColor: #00335d,
  ),

  interstate: (
    colorPrimary: #60BC46,
    colorSecondary: #EC1E2B,
    buttonBackgroundColor: #60BC46,
    buttonBorderColor: #60BC46,
    buttonColor: white,
  ),

  optima: (
    colorPrimary: #EE2A33,
    colorSecondary: #000,
    buttonBackgroundColor: #EE2A33,
    buttonBorderColor: #EE2A33,
    buttonColor: white,
  ),

  duracell: (
    colorPrimary: #B06637,
    colorSecondary: #000,
    buttonBackgroundColor: #B06637,
    buttonBorderColor: #B06637,
    buttonColor: white,
  ),

  carparts: (
    colorPrimary: #2E4787,
    colorSecondary: #000,
    buttonBackgroundColor: #FFCE05,
    buttonBorderColor:#FFCE05,
    buttonColor: rgb(0, 0, 0),
  ),
);

// More Specific Theme Styles

.theme-carparts {
  // background: #fff;

  .header-new .logo-col .logo-container .with-menu-icon img {
    height: 40px;

    @media screen and (max-width: 767px) {
      height: 30px;
    }
  }

  .smart-search {
    // .category-selection {
    //   input {
    //     border-top-left-radius: 100px;
    //     border-bottom-left-radius: 100px;
    //   }
    // }

    // .btn-success {
    //   border-top-right-radius: 100px !important;
    //   border-bottom-right-radius: 100px !important;
    // }
  }

  .page.collection {
    background-color: #fff;
    padding-top: 0;

    .page-title {
      margin-top: 1.5rem;
    }

    .collection-content {
      margin-top: 1.5rem;

      @media screen and (max-width: 767px) {
        margin-top: 0;
      }
    }
  }

  .home-categories-container {
    
  }

  .breadcrumb-section {
    background-color: #ffffff;
  }

  .collection-title {
    padding-left: 0;
    padding-right: 0;
  }

  .category-section {
    .section-header {
      border-color: #2e478750;
      
      h2 {
        color: #2e4787;
        background: none;
      }
    }
  }

  .product-box {
    padding: 0 !important;

    .product-details {
      .price-content {
        .free-installation {
          color: #ff0000;
        }
      }
    }

    .disclaimer {
      background-color: #fffdf4;
      color: #000000;
      margin-top: .5rem;
    }
  }

  .add-to-cart-selector .add-to-cart-container .btn.cart {
    background-color: #FFCE05;
    color: rgb(0, 0, 0);
    border-radius: 100px;
    text-transform: none;  

    &:hover {
      background-color: #ffd323;
    }
  }

  .page.product {
    background-color: #fff;
    
    .add-to-cart-selector {
      .add-to-cart-container {
        padding: 0;
      }
    }
  }

  .action-buttons .btn.btn-success {
    background-color: #FFCE05;
    color: #000;

    &:hover {
      background-color: #ffd21c;
    }
  }

  footer {
    margin-top: 0;
    padding-top: 1rem;
  }

  .page-checkout-content .step-content .footer-buttons .btn {
    background-color: #FFCE05;
    color: #000;

    &:hover {
      background-color: #ffd21e;
    }
  }
}