.dashboard-elements {
	.section {
		background-color: rgb(255, 255, 255);
		padding: 1rem;
		border-radius: 2px;
		margin-bottom: 1rem;

		h2 {
			font-size: 1.1rem;

			.btn {
				padding: 0 0.6rem;
				border-radius: 3px;
				font-size: 0.8rem;
				margin-left: 0.5rem;
				background-color: rgb(224, 224, 224);
			}
		}

		h3 {
			font-size: 1rem;
		}

		.items {
			display: flex;
			flex-wrap: wrap;

			.item {
				cursor: pointer;
				width: 32%;
				margin-right: 1%;
				margin-bottom: 1rem;
				border: 1px solid rgb(233, 233, 233);
				padding: 0.5rem;
				border-radius: 3px;
				position: relative;
				transition: all 0.3s;
				color: #000;

				@include max-width($md) {
					width: 100%;
					margin-right: 0;
				}

				ul {
					li {
						display: block;
					}
				}

				&:hover {
					border-color: black;

					.controls {
						display: block;
					}
				}

				.default-address {
					position: absolute;
					top: 0.5rem;
					right: 0.5rem;
					font-size: 0.75rem;
					color: $red;
					z-index: 99;

					i {
						margin-right: 0.2em;
					}
				}

				.controls {
					position: absolute;
					bottom: 0.5rem;
					right: 0.5rem;
					display: none;

					.btn {
						display: block;
						background: #f5f4f4;
						display: block;
						width: 40px;
						padding: 0.2rem;
						border-radius: 3px;
						font-size: 0.7rem;

						&.delete {
							color: $red;
						}

						&:hover {
							background: rgba(0, 0, 0, 0.15);
						}
					}
				}

				img {
					width: 80px;
					margin-right: 1rem;
				}
			}
		}
	}

	.alert {
		width: 100%;
		border-radius: 2px;
		border: none;

		@include max-width($lg) {
			max-width: 100% !important;
		}

		span {
			font-weight: 600;
		}
	}

	.side-navigation-container {
		// background: rgb(255,255,255);
		// background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 44%, rgba(255,255,255,0) 100%);

		@include max-width($md) {
			background: none;
		}
	}

	.side-navigation {
		padding-right: 2rem;

		@include max-width($md) {
			// background: #fff;
			padding-right: 0;
		}

		// margin-top: 1rem;

		li {
			display: block;

			&.active {
				.btn {
					background-color: #fff;
					border-bottom: 1px solid rgba($green, 0.5);
					color: darken($green, 20%);

					@include themify($themes) {
						color: darken(themed('colorPrimary'), 20%);
						border-bottom: 1px solid themed('buttonBorderColor');
					}
				}
			}

			.btn {
				display: block;
				width: 100%;
				text-align: left;
				font-size: 0.8rem;
				border-radius: 3px;
				background: rgba(0, 0, 0, 0.02);
				border: 1px solid rgba(212, 212, 212, 0);
				border-bottom: 1px solid rgb(228, 228, 228);
				color: rgb(88, 88, 88);
				margin-bottom: 0.5rem;
				text-transform: none;

				i {
					margin-right: 0.5rem;
					color: rgb(126, 125, 125);
				}

				&:hover {
					background-color: #fff;
					border-bottom: 1px solid rgba($green, 0.5);
					color: darken($green, 20%);

					@include themify($themes) {
						color: darken(themed('colorPrimary'), 20%);
						border-bottom: 1px solid themed('buttonBorderColor');
					}
				}
			}
		}
	}

	.content {
		// border-left: 1px solid rgb(228, 228, 228);
	}

	.section-element {
		background-color: #fff;
		padding: 1rem;
		border-radius: 3px;
		box-shadow: 0 0 7px -2px rgba(0, 0, 0, 0.2);
		margin-bottom: 1rem;
		display: flex;
		flex-wrap: wrap;

		.section-title {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 1rem;

			h2 {
				margin-bottom: 0;
			}

			.btn {
				background-color: rgba($blue-light, 1);
				border-radius: 3px;
				font-size: 0.8rem;
				text-transform: none;
				color: white;
				font-weight: 400;

				&:hover {
					background-color: darken($blue-light, 20%);
				}
			}
		}

		h2 {
			text-transform: none;
			font-weight: 400;
			font-size: 1.2rem;
			margin-top: 0;
			margin-bottom: 1.1rem;
		}

		h3 {
			text-transform: none;
			font-weight: 400;
			font-size: 1.2rem;
			margin-top: 0;
			margin-bottom: 1.1rem;

			.btn {
				background: rgba(0, 0, 0, 0.1);
				padding: 0 0.6rem;
				border-radius: 3px;
				font-size: 0.8rem;
				margin-left: 0.5rem;

				&:hover {
					background: rgba(0, 0, 0, 0.15);
				}
			}
		}

		.order-item {
			border: 1px solid #dfdfdf;
			padding: 0.5rem;
			border-radius: 3px;
			margin-right: 1.2%;
			margin-bottom: 1.2%;
			position: relative;
			align-items: center;
			width: 31%;

			&:hover {
				background: rgb(236, 236, 236);
			}

			ul {
				li {
					margin-bottom: 0.5rem;
					display: block;
				}
			}
		}
	}

	.profile-info-list {
		.srv-validation-message,
		.alert {
			margin-top: -0.5rem !important;
		}

		label {
			display: none;
		}

		.form-control {
			margin-bottom: 1rem;
		}

		.password {
			color: #004085;
			font-weight: 600;

			&:hover {
				cursor: pointer;
			}
		}

		.btn {
			background: $secondary;
			color: white;
			text-transform: none;
			border: none;
			border-radius: 3px;
			margin-bottom: 1rem;

			&:hover {
				background: darken($secondary, 20%);
			}
		}
	}

	.box-items-container {
		display: flex;
		flex-wrap: wrap;

		&.addresses {
			.box-item {
				padding-right: 45px;
			}
		}

		&.orders {
			.box-item {
				display: block;

				&:hover {
					border-color: grey;
					box-shadow: 0 0 7px -2px rgba(0, 0, 0, 0.1);
				}

				.text-content {
					li {
						padding: 0.2rem 0;
						border-bottom: 1px solid #e2e2e2;

						&:last-child {
							padding-bottom: 0;
							border: none;
							text-align: right;
							color: darken($green, 20%);
							font-weight: 600;
						}
					}
				}
			}
		}

		@include max-width($md) {
			flex-direction: column;
		}

		a {
			color: rgb(70, 70, 70);
		}

		.box-item {
			width: 32%;
			display: flex;
			border: 1px solid rgb(223, 223, 223);
			padding: 0.5rem;
			border-radius: 3px;
			margin-right: 1.2%;
			margin-bottom: 1.2%;
			position: relative;
			align-items: center;

			&:hover {
				.controls {
					display: block;
				}
			}

			@include max-width($lg) {
				width: 48%;
			}

			@include max-width($md) {
				width: 100%;
				margin-right: 0;
			}

			.default-address {
				position: absolute;
				top: 0.5rem;
				right: 0.5rem;
				font-size: 0.75rem;
				color: $red;

				i {
					margin-right: 0.2em;
				}
			}

			.controls {
				position: absolute;
				bottom: 0.5rem;
				right: 0.5rem;
				display: none;

				.btn {
					display: block;
					background: #f5f4f4;
					display: block;
					width: 40px;
					padding: 0.2rem;
					border-radius: 3px;
					font-size: 0.7rem;

					&.delete {
						color: $red;
					}

					&:hover {
						background: rgba(0, 0, 0, 0.15);
					}
				}
			}

			img {
				width: 80px;
				margin-right: 1rem;
			}

			.text-content {
				ul {
					li {
						display: block;
						margin-bottom: 0.2rem;

						small {
							display: block;
						}

						&.title {
							font-weight: 600;
						}
					}
				}
			}
		}
	}
}

.password-change-form {
	h6 {
		font-size: 1.2rem;
		color: rgb(43, 43, 43);
		padding-bottom: 0.7rem;
		margin-bottom: 1rem;
		border-bottom: 1px solid rgb(226, 226, 226);
	}

	.form-control {
		width: 500px;
		max-width: 100%;
	}

	.btn {
		font-size: 0.75rem;
		border-radius: 3px;
		border: none;

		&.btn-success {
			background-color: $secondary;
			color: white;

			&:hover {
				background: darken($secondary, 20%);
			}
		}

		&.btn-secondary {
		}
	}

	.buttons-container {
		display: flex;
		justify-content: space-between;
	}
}

.address-modal {
	width: 600px !important;

	.srv-validation-message,
	.alert {
		margin-top: -0.5rem !important;
	}
}

.styles_modal__gNwvD {
	max-width: 90%;
}
.styles_closeButton__20ID4 {
	display: none;
}

.dashboard-table {
	.table {
		tbody {
			tr {
				&:hover {
					background: #f3f3f3;
					cursor: pointer;
				}
			}

			td {
				&.with-thumb {
					display: flexbox;
					align-items: center;

					img {
						margin-right: 0.5rem;
						width: 32px;
					}
				}
			}
		}

		.price {
			font-weight: 600;
		}

		span {
			&.label {
				padding: 0.2rem 1rem;
				border-radius: 100px;
				border: 2px solid white;
				background: rgba(0, 0, 0, 0.1);

				&.complete,
				&.success {
					background-color: rgba($green, 0.2);
					color: darken($green, 20%);
				}

				&.warning {
					background-color: #ffea8a;
					color: rgb(43, 43, 43);
				}

				&.danger {
					color: darken($red, 20%);
					background: rgba($red, 0.2);
				}
			}
		}
	}
}

.update-address-form {
	.row-items {
		display: flex;
		flex-direction: column-reverse;
	}
}
