@keyframes scale-up {
  0% {
    transform: scale(1.00);
    border: 2px solid $red;
  }
  /* Adding a step in the middle */
  70% {
    transform: scale(1.025);
  }
  100% {
    transform: scale(1.00);
    border: 2px solid $green;
  }
}

/* Standard syntax */
@keyframes blink {
  from { 
    color: #1C75A1; 
  }
  to { 
    color: $orange; 
  }
}

.roadside-assistance {
  .statuses {

  }

  .header {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);

    h1 {
      margin: 0;
      font-size: 1.9rem;
      color: #5a5a5a;
    }
  }

  .body {
    // background: #fff;

    .with-map {
      background: #fff;
      border: 2px solid $red;
      border-radius: 3px;
      text-align: center;
      // padding: 1rem;

      &.start-animation {
        animation-name: scale-up;
        animation-duration: 1s;
        border-color: $green;
        
        .map-container {
          border-color: rgba($green, .5);
        }
      }

    
      .btn {
        background-color: $red;
        color: white;
        border-radius: 3px;
        font-size: 1rem;
        text-transform: none;
        margin: 1rem;
        padding: .4rem 1.5rem;

        &:hover {
          background-color: darken($red, 10%);
        }
      }

      .progress-header {
        background: #fff;
        padding: 1rem;

        ul {
          display: flex;
          justify-content: space-between;

          li {
            font-weight: 600;
            text-transform: uppercase;
            font-size: .9rem;

            @include max-width($md) {
              text-align: left;
            }

            span {
              @include max-width($md) {
                display: block;
              }

              &.status {
                &.success {
                  color: darken($green, 20%);
                }
              }

              &.eta {
                color: $blue;
              }
            }
          }
        }
      }

      .map-container {
        border-top: 2px solid rgba($red, .5);
        position: relative;

        .location-required {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
        }
      }
    }

    .roadside-form {
      background: #fff;
      height: 100%;
      padding: 1rem;
      box-shadow: 0 0 7px -2px rgba(0, 0, 0, 0.1);
      border-radius: 3px;
      display: flex;
      flex-direction: column;


      h2 {
        font-weight: 400;
        text-transform: none;
        font-size: 1.2rem;
        font-weight: 300;
      }

      .form-item {
        label {
          display: none;
        }

        input {
          border-radius: 3px;
          display: block;
          width: 100%;
          padding: 0.375rem 0.75rem;
          font-size: 1rem;
          line-height: 1.5;
          color: #495057;
          background-color: #fff;
          background-clip: padding-box;
          border: 1px solid #ced4da;
          border-radius: 0.25rem;
          transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        }
      }

      .btn-container {
        display: flex;
        flex: 1;
        align-items: flex-end;

        .btn-items {
          flex: 1;
          display: flex;
          justify-content: space-between;

          span {
            margin: 0 1rem;
            text-transform: uppercase;
            font-weight: 600;
          }
        }
      }

      .btn {
        background-color: $primary;
        border-radius: 3px;
        color: white;
        padding-left: 1rem;
        padding-right: 1rem;
        height: 38px;
        width: 200px;

        @include themify($themes) {
          background-color: themed('buttonBackgroundColor');
          color: themed('buttonColor');
        }

        &.sec {
          background-color: rgba(0,0,0,.05);
          color: rgb(53, 53, 53);
          font-size: .7rem;

          &:hover {
            background-color: rgba(0,0,0,.1);
          }
        }


        &:hover {
          background-color: darken($secondary, 10%);

          @include themify($themes) {
            background-color: darken(themed('buttonBackgroundColor'), 10%);
            color: themed('buttonColor');
          }

        }
      }
    }
  }

  .main-content-container {
    position: relative;

    .waiting {
      background-color: rgba(255,255,255,.8);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 999;
      display: flex;

      .inner {
        padding: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex: 1;

        img {
          width: 200px;
        }

        h4 {
          font-weight: 300;
          margin-top: 1rem;
          font-size: 1.5rem;
          line-height: 1.9rem;
          margin-bottom: 1rem;
        }

        p {
          margin-bottom: 0;
          font-size: 1.0rem;
        }
      }
    }

    .response {
      background-color: rgba(255,255,255,.8);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 999;
      display: flex;

      &.negative {
        .inner {
          .element {
            border-color: rgba($red, .4) !important;

            .text {
              p {
                margin-bottom: 0;
              }
            }

            .icon {
              i {
                color: $red;
              }
            }
          }
        }
      }

      .inner {
        padding: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex: 1;

        .element {
          width: 500px;
          display: flex;
          border: 2px solid rgba($green, .4);
          padding: 1rem;
          border-radius: 3px;

          @include max-width($md) {
            width: 100%;   
          }

          .icon {
            margin-right: 1rem;
            
            i {
              color: $green;
              font-size: 3rem;

              @include max-width($md) {
                font-size: 1.5rem;
              }
            }
          }

          .text {
            h4 {
              color: dark($green, 10%);
              font-size: 1.5rem;
              line-height: 1.9rem;
              border-bottom: 1px solid rgba(0,0,0,.1);
              padding-bottom: .5rem;
              margin-block: .5rem;

              @include max-width($md) {
                font-size: 1.3rem;
              }  
            }

            .btn {
              background: $green;
              color: white;
              border-radius: 3px;
              width: 200px;
              font-size: .75rem;

              &:hover {
                background-color: darken($green, 10%);
              }
            }
          }
        }
      }
    }
  }

  .map-container {
    .incoming-vehicle {
      i {
        font-size: 2rem;
        color: #1C75A1;
        text-shadow: 1px 1px 0px #fff;
        animation-name: blink;
        animation-duration: 1s;
        animation-iteration-count: infinite;
      }
    }
  }
}