.feedback-modal {
  background-color: #fff;
  border-radius: 3px;
  border: 3px solid rgba($green, .5);
  padding: 0;
  background: #fff;
  background-size: 500px;

  &.alert {
    border-color: $orange;

    .icon {
      i {
        color: $orange !important;
      }
    }

    h3 {
      color: darken($orange, 20%);
    }

    .btn-confirm {
      background: $orange !important;

      &:hover {
        background: darken($orange, 20%) !important;
      }
    }
  }

  &.info {
    border-color: $blue;

    .icon {
      i {
        color: $blue !important;
      }
    }

    h3 {
      color: darken($blue, 20%);
    }

    .btn-confirm {
      background: $blue !important;

      &:hover {
        background: darken($blue, 20%) !important;
      }
    }

    p {
      font-size: .9rem;
      line-height: 1.4rem;
    }
  }

  &.coupon-code {
    max-width: 500px;
    padding: 0 !important;

    border-color: #484ab785;

    .icon {
      i {
        color: #484ab7 !important;
      }
    }

    h3 {
      color: darken(#484ab7, 20%);
    }

    .btn-confirm {
      background: #afafc58c !important;
      color: black !important;

      &:hover {
        background: darken(#484ab748, 20%) !important;
      }
    }

    p {
      font-size: .9rem;
      line-height: 1.4rem;
    }

    .coupon-code-items {
      background: #ebebeb;
      padding: 0.5rem;
      display: block;
      justify-content: space-between;
      margin-bottom: 1rem;
      border: 2px dashed #b3b3b3;
      letter-spacing: 3px;
      font-weight: 600;
      text-align: left;
      font-size: 1.1rem;
      color: #171729;
    }
  }

  &.set-installer-availability {
    padding: 1rem;
    width: 500px;
    max-width: 100%;
    margin: 2rem auto;
    

    @include max-width(767px) {
      overflow-y: scroll;
      width: 100%;
      height: 100%;
      position: fixed;
      z-index: 10;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0;
    }
    
    .date-selection-button {
      text-align: left;
      padding: .5rem;
      background-color: #fff;
      color: rgb(63, 63, 63);
      font-weight: 400;
      border: 1px solid #c5c5c5
    }

    .react-datepicker-wrapper {
      width: 100%;
    }

    .inner {
      padding: 0;
      position: relative;

      .info {
        background: #0ebcee14;
        padding: 0.2rem 0.5rem;
        border-radius: 5px;

        p {
          margin-bottom: 0;
          /* border-radius: 5px; */
          color: #1d424c;
        }
      }

      .form-check {
      
        
        label {
          font-weight: 400;
          font-size: .8rem;
          color: rgb(43, 43, 43);
        }
      }

      .contains-form {
        input {
          width: 100% !important;
          padding: .5rem;
          border: 1px solid rgb(216, 216, 216);
          border-radius: 3px;
          font-size: .85rem !important;
          font-weight: 600;
          color: rgb(49, 49, 49);
        }
      }

      .two-forms {
        display: flex;
        gap: .4rem;
      }
      
      h2 {
        margin-top: 0rem;
        font-size: 1.1rem;
        font-weight: 400;
        color: #2f98b7;
        margin-bottom: 1rem;
        border: none;
      }


      .btn {
        width: 100%;
      }

      .footer {
        background-color: #7797a12f;

        .btn {
          background: none;
          border-radius: 5px;
          font-weight: 400 !important;
          font-size: .85rem;
          text-align: left;
          padding: .8rem .3rem;
          position: relative;
          padding-right: 2rem;
          padding-left: 2rem;

          &:hover {
            background-color: #56737c2f;
          }

          .down {
            position: absolute;
            top: 25px;
            right: 10px;
          }

          .left {
            position: absolute;
            top: 20px;
            left: 8px;
            font-size: 1.2rem;
          }
        }

        .tasks {
          padding: 0 1rem;

          .task-item {
            border-top: 1px solid rgb(219, 219, 219);

            p {
              padding: .5rem 0;
              margin-bottom: 0;

              span {
                font-weight: 600;
              }

              &:last-child {
                margin-bottom: 0;
              }
            }
          }
        }
      }

      .step {
        position: relative;
        margin-top: 0;
        opacity: 1;
        transition: all .5s;
        
        &.low-opacity {
          opacity: .2;
        }

        .dimmed-overlay {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: none;
          z-index: 20;
        }
      }

      .btn {
        &.btn-danger {
          color: #fff;
        }
      }

      .response {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        background-color: rgba(255, 255, 255, 0.974);
        z-index: 10;
        flex-direction: column;
        display: flex;
        align-items: center;
        justify-content: center;

        h2 {
          font-weight: 600;
          font-size: 1.3rem;
        }

        .btn {
          display: inline-block;
          background: #0ebcee !important;
          color: #fff;
          width: 100px;
          border: none;

          &:hover {
            background-color: #7797a162;;
          }
        }
      }
    }
  }

  .inner {
    padding: 1rem;
    background: rgb(255, 255, 255);
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 1) 44%,
      rgba(255, 255, 255, 0) 100%
    );

    display: flex;
    flex-direction: row;

    .icon {
      margin-right: 1rem;

      @include max-width(320px) {
        display: none;
      }
      
      i {
        color: $green;
        font-size: 2rem;
      }
    }

    .content {
      flex: 1;  
    }
  }

  .close-btn {
    display: none;
  }

  h3 {
    color: darken($green, 10%);
    font-weight: 600;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-bottom: 0.3rem;
    margin-bottom: 1rem;
  }

  p {
    color: black;
    font-size: 1rem;
    line-height: 1.2rem;
    margin-bottom: 1rem;
  }

  .buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .btn {
      text-transform: none;
      border-radius: 3px;
      border: none;
      color: grey;
      display: flex;
      width: 120px;
      position: relative;
      align-items: center;
      justify-content: center;

      svg {
        width: 1rem;
        height: 1rem;
        margin-right: .5rem;
      }


      &.btn-default {
        background: rgba(0,0,0,.1);
      }

      &.btn-confirm {
        background-color: $green;
        color: white;

        &:hover {
          background-color: darken($green, 10%);
        }
      }
    }
  }
}
