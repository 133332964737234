.not-available {
  position: relative;

  .blured-content {
    // -webkit-filter: blur(3px);
    // -moz-filter: blur(3px);
    // -o-filter: blur(3px);
    // -ms-filter: blur(3px);
    // filter: blur(3px);
    // padding: .5rem;
    height: 350px;
    background: url('../../static/images/temporary-options-blurred.png');
    background-size: 100% auto;
    background-position: top left;
  }

  .text-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.2);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;

    &.checking-availability {
      .inner {
        i {
          color: rgb(77, 77, 77);
        }
      }
    }

    .inner {
      border: 1px dashed $orange;
      text-align: center;
      padding: 1rem;
      border-radius: 3px;
      margin-top: 0;
      width: 95%;
      background-color: #fff;
      
      .icon-container {
        margin-right: 1rem;
      }

      i {
        color: $orange;
      }
  
      h5 {
        color: rgb(27, 27, 27);
        font-weight: 600;
        font-size: 1.2rem;
        margin-bottom: .5rem;
        margin-top: .5rem;
      }

      p {
        margin-bottom: 0;
        font-size: 1rem;
        color: black;
        line-height: 1.3rem;
      }

      .btn {
        margin: .7rem auto;

        i {
          font-size: 1rem;
          color: white;
        }
      }

      .input-loading-status {
        i {
          font-size: 1rem;
          color: rgb(27, 27, 27);
        }
      }

      .input-pack {
        i {
          font-size: 1rem;
          color: rgb(27, 27, 27);
        }
      }
    }
  }
}