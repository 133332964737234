.product-sidebar {
	background-color: #f7f7f7;
	padding: 1rem;
	border: 1px solid #efefef;
	border-radius: 3px;
}

// // FIXME: find location for class
// .search-location-input {
// 	padding-right: 10px;
// 	padding-left: 0;
// 	line-height: 40px;
// 	height: 38px;
// 	border: none !important;
// 	border-radius: 0 !important;
// 	background: transparent;
// 	outline: none;
// 	font: inherit;

// 	&--modal {
// 		padding-left: 10px;
// 	}
// }
