.ais-Pagination {
  background: #fff;
  border-radius: 5rem;
  padding: .5rem 1rem;
  display: block;
  margin: 0 auto;
  width: 450px;
  transition: 0.1s;
  max-width: 100%;

  &.ais-Pagination--noRefinement {
    display: none;
  }

  &:hover {
    //box-shadow: 0 0 7px -2px rgba(0, 0, 0, 0.4);
  }

  .ais-Pagination-list {
    margin-bottom: 0;
    flex: 1;
    display: flex;
    justify-content: space-between;
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  .ais-Pagination-item {
    font-size: 1.1rem;
    font-weight: 600;
    flex: 1;
    justify-content: center;
    text-align: center;

    &:first-child, &:last-child {
      font-weight: 400;
      
      a, .ais-Pagination-link {
        padding-right: 1rem;
        padding-left: 1rem;
      }
    }

    a, .ais-Pagination-link {
      display: block;
      width: 100%;
      border-radius: 4rem;
      color: $primary;

      @include themify($themes) {
        color: themed('colorPrimary');
      }

      &:hover {
        color: $primary;
        
        @include themify($themes) {
          color: themed('colorPrimary');
        }
        background-color: rgba(0, 0, 0, .1);          
      }
    }

    &.ais-Pagination-item--selected {
      a {
        background-color: rgba(0, 0, 0, .05);  
        color: $grey6
      }
    }

    &.ais-Pagination-item--disabled {
      span {
        color: $grey6 !important;
      }
    }
  }
}