.filters-container {
  border-bottom: 1px solid rgba(0,0,0,.05);
  padding-bottom: 1.5rem;
  margin-top: 1.5rem;

  @include max-width($md) {
    margin-top: 0;
    padding-bottom: 1rem;
  }

  ul {
    display: flex;
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      margin-right: .5rem;
      position: relative;

      @include max-width(768px) {
          flex: 1;
      }

      .btn {
        //border-radius: 5rem;
        background: none;
        background-color: #fff;
        font-size: .8rem;
        color: $grey5;
        transition: 0s;
        height: 40px;
        min-width: 200px;

        @include max-width($md) {
          min-width: auto;
          width: 100%;
          height: 40px;
        }

        &:hover {
          background-color: rgba($primary, .7);

          @include themify($themes) {
            background-color: rgba(themed('colorPrimary'), .7);
          }
          color: white;
        }

        &:hover {
          &>.hidden-filters {
            display: block !important;
          }
        }
      }


      .hidden-filters {
        z-index: 999;
        position: absolute;
        background-color: #fff;
        box-shadow: 0 0 7px -2px rgba(0, 0, 0, 0.4);
        top: 40px;
        left: 0;
        display: none;

        &:last-child {
          @include max-width($md) {
            
          }
        }

        ul {
          flex-direction: column;
          overflow-y: scroll;
          max-height: 350px;

          li {
            margin-right: 0;

            .btn {
              background-color: #fff;
              border: none;
              border-radius: 0;
              display: block;
              text-align: left;
              padding-left: 1rem;
              display: flex;
              align-items: center;
              font-size: .7rem;
              width: 100%;

              @include max-width($md) {
                font-size: .6rem;
              }

              i {
                font-size: 1.2rem;
                margin-right: .7rem;
              }

              &.active {
                color: darken($primary, 10%);

                @include themify($themes) {
                  color: darken(themed('colorPrimary'), 10%);
                }
              }

              &:hover {
                color: white;
                background-color: rgba($primary, .7);

                @include themify($themes) {
                  background-color: rgba(themed('colorPrimary'), .7);
                }
              }
            }
          }
        }
      }
    }
  }
}