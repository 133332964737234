.product {
  .inner {
    margin-top: 2rem;
  }

  .services {
    &.left-content {
      display: block;
      overflow-y: auto;

      @include max-width($xl) {
        display: none !important;
      }
    }

    &.bottom-content {
      display: none;
      margin-bottom: 1rem;
      margin-top: 1rem;

      @include max-width($xl) {
        display: block;
      }
    }
  }

  .center {
  }

  .right-sidebar {
  }

  .discounts {
    // position: absolute;
    // top: 0;
    // left: 0;
    // z-index: 2;

    @include max-width($md) {
      margin-top: 1rem;
    }

    .discount-item {
      border: 2px solid lighten($red, .5);
      background-color:rgba($red, 1);
      border-top-right-radius: 100px;
      border-bottom-right-radius: 100px;
      padding: .1rem 1rem .1rem .5rem;
      margin-bottom: .5rem;
      color: white;
      transition: .2s all;
      width: 60%;

      a {
        color: #fff;
      }

      @include max-width($md) {
        width: 100%;
      }

      &:hover {
        transform: scale(1.02);
        cursor: pointer;
      }

      .content {
        font-size: .8rem;
        // display: flex;

        .amount {
          //font-size: 1rem;
          font-weight: 600;
        }

        .details {
          // display: flex;
          // flex-direction: column;

          span {
            font-weight: 300;
          }

          img {
            width: 1.5rem;
          }
        }
      }
    }
  }
  
  .campaign-hypherlink {
    text-align: left;
    color: #0A357E;
    display: block;
    margin-top: .5rem;
    font-size: .9rem;

    span {
      font-weight: 600;
      border-bottom: 2px solid rgba(#0A357E, .4);
    }

    &:hover {
      color: darken(#0A357E, 10%);
      
      span {
        border-bottom: 2px solid rgba(#0A357E, 1);
      }
    }
  }

  .intro-container {
    display: flex;
    align-items: center;
    // padding-bottom: 1rem;
    // border-bottom: 1px solid rgb(206, 206, 206);
    // margin-bottom: 1rem;

    @include max-width($md) {
      margin-top: 1rem;
    }

    .text-container {
      flex: 1;
      padding-right: .5rem;

      h1 {
        text-transform: none;
        font-size: 1.9rem;
        margin-bottom: 0;
      }
    }

    .numbers-container {
      position: relative;

      .horizontal-loader {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background-color: #f7f7f7e5;
      }

      .call-for-price {
        padding: 0.3rem 0.7rem;
        margin-bottom: 0;
        text-align: center;
        color: #004085;
        background-color: #cce5ff;
        border-color: #b8daff;
        border-left: 1px solid rgb(206, 206, 206);
        padding-left: 0.7rem;
      }

      .price {
        border-left: 1px solid rgb(206, 206, 206);
        padding-left: 0.7rem;
        margin-bottom: 0;

        &.not-available {
          h4 {
            &.old-price {
              span {
                color: #0459b4;
                font-weight: 600;
              }
            }
          }
        }

        .valid-price {
          margin-bottom: 0;
          font-size: 1.4rem;
          color: rgb(44, 44, 44);
          font-weight: 600;
          display: block;
        }

        .old-price {
          margin-bottom: 0;
          display: block;

          del {
            margin-right: 0.3rem;
          }

          span {
            font-size: 0.8rem;
            text-transform: uppercase;
            font-weight: 600;
            color: darken($green, 20%);
          }
        }

        .free-installation {
          color: darken($orange, 10%);
          font-weight: 600;
          border-radius: 30px;
          display: block;
          text-align: center;
        }
      }
    }
  }

  .short-description {
    text-transform: none;
    font-size: .95rem;
    font-weight: 400;
    color: rgb(36, 36, 36);
    margin-top: .7rem;
    margin-bottom: 1.5rem;
    line-height: 1.5rem;
  }

  .services-container {
    border-radius: 3px;
    border: 1px solid #cce5fe;
    overflow: hidden;
    background-color: #f2f8ff;

    .service-item {
      display: flex;
      align-items: center;
      padding-top: 0.75rem;
      padding-left: 0.75rem;
      padding-right: 0.75rem;

      &:last-child {
        .content {
          border: none;
        }
      }

      .image {
        margin-right: 1rem;
        width: 50px;

        i {
          font-size: 2.1rem;
          color: rgb(165, 165, 165);
        }
      }

      .content {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        width: 100%;
        padding-bottom: 0.75rem;

        h6 {
          font-size: 0.9rem;
          text-transform: uppercase;
          font-weight: 600;
          color: #0459b4;
          margin-bottom: 0;
        }

        p {
          margin-bottom: 0;
        }

        p {
          span {

            &.clickable {
              color: black;

              &:hover {
                color: darken($green, 10%);
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }

  .description {
    p {
      line-height: 1.4rem;
    }
  }

  .product-images-container {
    img {
      width: 100%;
      border: 1px solid rgba(0, 0, 0, 0.1);
    }

    .thumbnails {
      display: flex;

      .thumbnail-item {
        flex: 1;

        img {
          width: auto;
        }
      }
    }
  }

  .related-products-wrapper {
    width: 100%;
  }

  .related-products-container {

    .info-available {
      background-color: $green;
      color: white;
      border-radius: 25px;
      padding: .1rem 1rem;
      font-size: .8rem;
    }

    .section-title {
      font-size: 1rem;
      margin-bottom: .5rem;
      text-transform: uppercase;
    }

    .product-item {
      margin-bottom: 0.7rem;
      display: flex;
      flex-direction: column;
      background-color: #fff;
      border-radius: 3px;
      border: 2px solid white;  

      a {
        color:  rgb(51, 51, 51);

        &:hover {
          text-decoration: none;
        }
      }

      &:hover {
        border-color: #0EC8F0;
      }

      a {
        padding: 0.75rem;
      }

      .body {
        display: flex;
        align-items: center;
      }

      .img-container {
        border: 1px solid rgba(0, 0, 0, 0.1);
        padding: 0.2rem;

        img {
          width: 64px;
        }
      }

      .content-container {
        display: flex;
        flex-direction: column;
        flex: 1;
        margin-left: 1rem;

        .primary {
          display: flex;
          flex-direction: row;



          .title {
            h5 {
              font-size: 1.1rem;
            }

            p {
              line-height: 1.0rem;
              font-size: .8rem;
            }
          }

          .price {
            margin-left: 0.4rem;
            width: 30%;

            h5 {
              border-left: 1px solid rgba(0, 0, 0, 0.05);
              padding-left: 0.4rem;
              font-weight: 400;
              font-size: 0.9rem;

              del {
                margin-right: 0.3rem;
                font-weight: 400;
              }
            }

            .indicator {
              .graphic {
                height: 3px;
              }

              .square {
                height: 15px;
                width: 7px;
                border-width: 1px;
                bottom: -6px;
              }
            }
          }
        }

        .cta-buttons {
          text-align: left;

          .btn {
            font-size: 0.7rem;
            background: rgba(0, 0, 0, 0.05);
            padding: 0.1rem 1rem;
            border-radius: 5rem;

            &:hover {
              background-color: rgba(0, 0, 0, 0.1);
            }

            &.cart {
              margin-left: 0.5rem;
              color: darken($orange, 15%);
            }
          }
        }
      }

      .footer {
        margin-top: 0.5rem;
        padding-top: 0.5rem;
        border-top: 1px dashed rgb(214, 214, 214);

        .btn {
          text-align: left;
          background: none;
          padding: 0.2rem 0;
          border-bottom: 1px dashed rgb(192, 192, 192);
          font-size: 0.7rem;
          margin-top: 0.4rem;
          border-radius: 3px;
          display: block;
          width: 100%;
          margin-bottom: 0.4rem;
          color: grey;
        }

        .service-items-container {
          margin-bottom: 0;

          td {
            border: none;
            padding: 0.2rem 0;
            font-size: 0.9rem;
            color: grey;

            &:hover {
              .service-wrapper {
                background-color: rgba(0, 0, 0, 0.1);
              }
            }

            .service-wrapper {
              background-color: rgba(0, 0, 0, 0.05);
              margin-right: 0.2rem;
              text-align: center;
              padding: 0.2rem 0.5rem;
              border-radius: 3px;
              display: block;

              .description {
                font-size: 0.8rem;
                margin-left: 0.3rem;
              }
            }
          }
        }
      }
    }
  }

  .tab-content {
    margin-bottom: 1rem;

    .tab-pane {
      background: #fff;
    }

    .description {
      background: #fff;
      padding: 0.75rem;

      p {
        padding: 0;
        margin-bottom: 0;
      }
    }

    .theme-form {
      background: #fff;
      padding: 0.75rem;

      .media {
        margin-top: 0.75rem;
        margin-bottom: 1.5rem;
        padding-bottom: 0.5rem;
        border-bottom: 1px solid rgb(230, 230, 230);

        h4 {
        }
      }

      .form-control {
        padding: 0.5rem 0.7rem;
      }

      .btn {
        background-color: rgba(0, 0, 0, 0.05);
        width: 150px;
        max-width: 100%;

        &:hover {
          background-color: rgba(0, 0, 0, 0.1);
        }
      }
    }

    .nav-tabs {
      .nav-item {
        &.react-tabs__tab--selected {
          .nav-link {
            background-color: #fff;
            color: $primary;

            @include themify($themes) {
              color: themed('colorPrimary');
            }
          }

          .material-border {  
            @include themify($themes) {
              border-color: themed('colorPrimary');
            }
          }
        }

        .nav-link {
          padding: 0.5rem 1rem;
          font-weight: 600;
          font-size: 0.8rem;
          color: rgb(48, 48, 48);

          &:hover {
            background-color: #fff;
            cursor: pointer;
          }
        }
      }
    }

    .brand-description {
      background-color: #fff;
      align-items: flex-start;
      padding: 0.75rem;
      min-height: 100px;

      a {
        display: block;
        float: left;

        &:hover {
          opacity: .5;
        }

        img {
          width: 150px;
          margin-top: .5rem;
          margin-right: 1rem;
          margin-bottom: 1rem;

          @include max-width($md) {
            width: 90px;
          }
        }
      }

      p {
        padding: 0;
      }
    }
  }

  .bottom-container {
    box-shadow: none !important;
    background: none !important;    
  }

  .banner-with-sample-report {
    .inner {
      padding-top: 2rem !important;
      margin-top: 1rem;
      background: #f9f9f9;
    }

    .text {
      h3 {
        font-size: 1.5rem !important;
      }

      p {
        font-size: 1rem !important;
      }
    }
  }

  .steps {
    .item {
      h6 {
        font-size: 1rem !important;
        font-weight: 600;
        color: #43435e !important;
      }

      p {
        font-size: .85rem !important;
      }
    }
  }
}