ol.progtrckr {
  list-style-type: none;
  padding: 0;
}

ol.progtrckr li {
  display: inline-block;
  text-align: center;
  cursor: pointer;
}

@media (max-width: 650px) {
  .progtrckr li span {
    display: none;
  }
}

.progtrckr em {
  display: none;
  font-weight: 700;
  padding-left: 1rem;
}

@media (max-width: 650px) {
  .progtrckr em {
    display: inline;
  }
}

.page-checkout-content {
  iframe {
    @include max-width($md) {
      min-height: 20px;
    }
  }
  
  .step-content {
    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      background-color: rgba(255,255,255,.35);
      z-index: 999;
      display: flex;
      align-items: center;
      justify-content: center;

      @include max-width($md) {
        align-items: flex-end;
        padding-bottom: 100px;
      }

      .overlay-inner {
        background: (255,255,255);
        background: radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
        padding: 2rem;
      }

      p {
        color: rgb(43, 43, 43);
        font-weight: 600;
      }
    }

    .step-progress {
      position: relative;

      .step-overlay {
        position: absolute;
        z-index: 2;
        height: 100px;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(255, 255, 255, 0);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      &.loading {
        .section {
          filter: blur(2px);
        }
      }
    }

    .inner {
      border-radius: 5px;
      background-color: #fff;
      position: relative;
      padding-bottom: 3.5rem;
      padding: 0.75rem;
      box-shadow: 0 0 7px -2px rgba(0, 0, 0, 0.1);

      @include max-width($md) {
        padding: 0;
        box-shadow: none;
      }
    }

    .reviews-container {
      position: relative;

      .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 999;
        background-color: rgba(0,0,0,.0);
      }
    }

    .progtrckr {
      @include max-width($md) {
        display: none;
      }

      li {
        margin-right: 1.3rem;
        padding: 0.2rem;
        transition: 0.2s;

        &.progtrckr-todo {

          h6 {
            i {
              display: none;
            }
          }
        }

        &.progtrckr-done {
          background-color: white;

          .step-title-container {
            h6 {
              color: darken($green, 10%);

              i {
                display: none;
              }
            }
          }
        }

        &.progtrckr-doing {
          background-color: white;

          .step-title-container {
            h6 {
              color: black;
            }

            .after-title {
              i {
                color: rgb(180, 180, 180);
              }
            }
          }
        }

        .step-title-container {
          position: relative;

          .after-title {
            position: absolute;
            right: -1rem;
            top: 0;
            display: flex;
            align-items: center;
            height: 100%;

            i {
              color: rgb(180, 180, 180);
              font-size: 0.5rem;
            }
          }

          h6 {
            font-weight: 400;
            margin-bottom: 0;
            color: rgb(180, 180, 180);
            transition: 0.2s;
            font-size: .9rem;
          }
        }
      }
    }

    .section {
      margin-bottom: 0.75rem;

      .header {
        margin-bottom: 1rem;
        padding-bottom: .5rem;
        margin-bottom: 0;
        border: none;

        @include max-width(767px) {
          margin-top: 0;
          margin-bottom: .7rem;
          padding-bottom: 0;
        }

        h3 {
          font-size: 1rem;
          color: rgb(34, 34, 34);
          margin-bottom: 0;
          text-transform: uppercase;
          font-weight: 600;
        }
      }

      .body {
        .intro {
          p {
            color: $grey;
          }

          .terms-checkbox {
            label {
              color: $grey;
              font-size: .9rem;
            }
          }
        }

        .input-container-wrapper {
          &.error {
            input {
            }
          }
          .alert {
            padding: 0.2rem 0.7rem;
            margin-top: 0rem;
          }
        }

        .row-items {
          display: flex;
          align-items: center;
          position: relative;
          margin-top: 1rem;

          label {
            z-index: -1;
            opacity: 0;
            transition: all .5s;
            position: absolute;
            top: 0;
            left: .5rem;
            padding: 0 .3rem;
            font-weight: 600;
          }

          input,
          select,
          textarea {
            transition: all .3s;
            border-radius: 3px;
            height: 45px;
            color: #000;
            font-size: .95rem;

            &:focus, &:not(:placeholder-shown) {
              box-shadow: none;
              outline: none;
              padding-top: .8rem;
            }

            &:focus ~ label, &:not(:placeholder-shown) ~ label {
              z-index: 1;
              opacity: 1;
              top: -.4rem;
              left: .5rem;
              background-color: #fff;
              margin-bottom: 0;
              color: rgb(65, 65, 65);
              font-weight: 400;
              font-size: .7rem;
            }
          }

          textarea {
            height: 80px;
          }
        }

        .help-question {
          background-color: rgba(0, 0, 0, 0.05);
          padding: 0.2rem 0.75rem;
          border-radius: 5px;
          display: inline-block;

          .btn {
            background: none;
            padding: 0;
            font-weight: 600;
            color: $dark-font;
            text-transform: none;
            font-size: .9rem;

            &:hover {
              text-decoration: underline;
            }
          }

          p {
            margin: 0;
            font-size: .9rem;
            color: $dark-font;
          }

          &.margin-left {
            margin-left: 150px;
          }
        }
      }
    }

    .footer-buttons {
      margin-top: 1rem;
      width: 100%;
      display: flex;
      justify-content: flex-end;
      
      @include max-width($md) {
        margin-bottom: .5rem;
        padding-bottom: 2rem;
        border-bottom: 1px solid rgb(243, 243, 243);
      }

      .btn {
        min-width: 150px;
        max-width: 50%;
        margin-left: 1rem;
        border-radius: 3px;
        background-color: $orange;
        border: none;
        position: relative;
        padding-right: 2rem;
        padding-left: 2rem;
        font-size: .95rem !important;
        font-weight: 600;
        text-transform: uppercase;
        transition: none;
        color: #fff;
        
        @include max-width($md) {
          flex: 1;
        }

        &.disabled {
          background-color: rgb(196, 196, 196) !important;
        }

        &.loading {
          &:before {
            display: inline-block;
            font-style: normal;
            font-variant: normal;
            text-rendering: auto;
            -webkit-font-smoothing: antialiased;
            font-family: "Font Awesome 5 Pro";
            font-weight: 900;
            content: "\f1ce";
            -webkit-animation: fa-spin 2s infinite linear;
            animation: fa-spin 2s infinite linear;
            position: absolute;
            left: 10px;
          }
        }

        &.btn-prev {
          &:visited, &:focus, &:active {
            color: white; 
            box-shadow: none;
            outline: none ;
            background-color: #e65107 !important;
          }
        }

        &.btn-next {
          background-color: #f7f7f7;
          color: grey;
          margin-left: 0;

          &:visited, &:focus, &:active {
            color: grey;
            box-shadow: none;
            outline: none ;
            background-color: darken(rgb(255, 255, 255), 10%);
          }

          &:hover {
            background-color: darken(rgb(255, 255, 255), 10%);
            color: grey;
          }
        }

        &:hover {
          background-color: darken($orange, 20%);
        }
      }
    }
  }

  .cart-content {
    &.hide-on-mobile {
      @include max-width($md) {
        display: none;
      }
    }

    .cart-items-container {
      padding-bottom: 0.75rem;
      margin-bottom: 0.75rem;
      border-bottom: 1px solid rgb(214, 214, 214);
    }

    .cart-item-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 0.5rem;

      &.total {
        h5 {
          font-size: 1.1rem;
        }

        .amount {
          h5 {
            font-size: 1.3rem;
          }
        }
      }

      .content-left {
        display: flex;
        align-items: center;

        h5 {
          font-size: 1rem;
        }

        .image-container {
          border: 1px solid rgb(216, 216, 216);
          margin-right: 0.5rem;
          position: relative;

          .quantity {
            position: absolute;
            top: 0;
            right: -10px;
            width: 20px;
            height: 20px;
            background-color: grey;
            color: #fff;
            border-radius: 5rem;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          img {
            width: 64px;
            border-radius: 5px;
            overflow: hidden;
          }
        }

        .title-container {
          h5 {
            margin-bottom: 0;
          }
        }
      }

      .amount {
        h5 {
          font-size: 1rem;
        }
      }
    }

    .links {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    .btn {
      font-size: 0.8rem;
      font-weight: 400;
      padding: .1rem .5rem;
      border-radius: 5rem;

      &.return {
        color: grey;
        background: rgb(224, 224, 224);
        display: flex;
        align-items: center;
        justify-content: center;
      
        i {
          margin-right: .3rem;
        }

        &:hover {
          background-color: #545b62;
          color: white;
          border-color: #545b62;
        }
      }

      &.call {
        color: #545b62;
        text-transform: none;
        padding-right: 0;
        padding-left: 0;
        border-radius: 0;
        border-bottom: 2px dashed #545b6249;

        span {
          font-weight: 600;
        }

        &:hover {
          border-color: #F3F3F3;
          border-bottom: 2px solid #545b6249;
        }
      }
    }
  }

  .coupon-container {
    border-bottom: 1px solid rgb(214, 214, 214);
    border-top: 1px solid rgb(214, 214, 214);
    padding: 1rem 0;
    margin-bottom: 0.75rem;

    .inner {
      display: flex;
    }

    .btn {
      width: 100px;
      border-radius: 3px;
      margin-left: 0.5rem;
    }

    .active-coupons-container {
      .active-coupon {
        margin-top: 0.6rem;
        display: flex;
        justify-content: space-between;

        i {
          color: $green;
        }

        .info {
          font-weight: 600;
        }

        .number {
          color: darken($green, 10%);
        }
      }
    }
  }

  .eta {
    background: #b9dde93d;
    padding: .5rem;
    margin-bottom: 1rem;
    margin-top: 1rem;
    border-radius: 3px;
    border: 1px solid #b8dce9;

    h6 {
      font-size: 1.1rem;
      color: #313131;

      span {
        font: 600;
      }
    }

    p {
      margin-bottom: 0;
      color: #313131;
    }
  }
}

.sign-in-container {
  margin: 0 auto;
  width: 400px;
  max-width: 100%;
  border-radius: 5px;
  padding: 0.75rem;
  box-shadow: 0 0 7px -2px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  position: relative;

  .row-items {
    display: flex;
    align-items: center;
    //margin-bottom: .5rem;

    label {
      display: none;
    }

    input {
      margin-bottom: 1rem;
    }
  }

  .btn {
    background-color: $green;
    color: white;
    border: none;
    border-radius: 3px;
    width: 100%;

    &:hover {
      background-color: darken($green, 10%);
    }
  }

  .close {
    position: absolute;
    top: 0.75rem;
    right: 0.75rem;
    color: grey;
    font-size: 0.9rem;

    &:hover {
      cursor: pointer;
    }
  }
}

.srv-validation-message {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
  padding: 0.2rem 0.5rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  margin-top: -0.5rem;
  margin-bottom: 0.5rem;
}

.add-additional-payment-method {
  width: 500px;
  max-width: 100%;
}

.payment-methods {

  .payment-methods-container {
    background-color: #f5f9fc;
    
    .payment-methods-header {
      background-color:#fff;
      margin-bottom: -2px;

      ul {
        display: flex;
        justify-content: flex-start;
        margin-bottom: 0;
        padding-left: 0;

        li {
          display: flex;
          justify-content: center;
        }

        li.center {
          flex: 1;
        }
      }

      .btn {
        background: none; 
        color: rgb(51, 51, 51);
        padding: .5rem 1rem;
        min-width: 170px;
        border-top-right-radius: 2px;
        border-top-left-radius: 2px;
        border: 2px solid rgba(0, 0, 0, 0);
        transition: none;
        text-transform: none;
        font-size: .9rem;
        display: flex;
        align-items: center;
        justify-content: center;
        
        img {
          margin-right: .52rem;
          width: 20px;
        }

        i {
          margin-right: .5rem;
        }

        @include max-width($md) {
          // padding: .5rem .5rem;
          width: 100%;
          font-size: .80rem;
          min-width: auto;
        }

        &.active {
          background-color: #f5f9fc;
          color: rgb(51, 51, 51);
        }
      }
    }

    .payment-method-inner {
      padding: 1rem;

      @include max-width($md) {
        padding: .7rem;
      }
    }

    .pay-later-inner {
      margin-top: 1rem;

      p {
        color: black;
        line-height: 1.2rem;
        line-height: 1.4rem;
        margin-bottom: 0;
      }
    }

    .affirm-pay {
      display: flex;
      align-items: center;
      flex-direction: column;

      .error {
        display: flex;
        align-items: center;

        .icon {
          margin-right: .7rem;

          i {
            font-size: 1.7rem;
            color: $red;
          }
        }

        .text {
          border-left: 1px solid rgb(218, 218, 218);
          padding-left: .7rem;
          
          h6 {
            font-weight: 600;
            color: rgb(70, 70, 70);
            font-size: 1.1rem;
          }

          p {
            margin-bottom: 0;
          }
        }
      }

      .affirm-pay-inner {
        padding: .5rem;
        border-radius: 5px;
        background-color: rgb(255, 255, 255);
      }

      .submit-btn {
        background-color: #0097E8;
        color: #fff;
        border: none;
        font-size: 1.0rem;
        border-radius: 3px;
        margin-top: 1rem;
        text-transform: uppercase;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: center; 

        img {
          width: 20px;
        }
      }
    }
  }

  .pay-later {
    background: #dbdfe2;
    padding: .1rem .7rem;
    font-size: .8rem;
    text-transform: none;
    color: #494949;
    border-radius: 5rem;
  }

  .covid-update {
    background-color: #f3f3f3;
    position: relative;
    background: rgb(255,255,255);
    background: linear-gradient(41deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 50%, rgba(191,234,249,1) 100%);
    border-radius: 3px;
    margin-top: .5rem;
    padding-right: .5rem;
    padding-top: .5rem;

    .icon-container {
      margin-right: .5rem;
      position: absolute;
      top: .5rem;
      right: .5rem;

      i {
        font-size: 1.5rem;
        color: #494949;
      }
    }

    p {
      margin-bottom: 0;
      line-height: 1.3rem;

      @include max-width($md) {
        font-size: .75rem;
        font-size: 0.75rem;
        line-height: 1.1rem;
      }
    }
  }

  input,
  .StripeElement {
    display: block;
    margin: 10px 0 10px 0;
    padding: 10px 14px;
    font-size: 1em;
    font-family: "Source Code Pro", monospace;
    box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
    border: 0;
    outline: 0;
    border-radius: 4px;
    background: white;
    @include max-width($md) {
      height: 40px;
    }
  }

  .cc-error {
    background-color: rgba(red, .1);
    padding: .3rem .7rem;
    border-radius: 2px;
    margin-bottom: .5rem;

    h6 {
      color: #925252;
      margin-bottom: 0 !important;
      font-size: .9rem;
    }
  }

  input::placeholder {
    color: #aab7c4;
  }

  input:focus,
  .StripeElement--focus {
    box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px, rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease;
  }

  .StripeElement.IdealBankElement,
  .StripeElement.PaymentRequestButton {
    padding: 0;
  }

  .paypal-container {
    text-align: center;
    padding: 1rem;
    border: 1px solid rgba(0, 0, 0, 0.05);

    .btn {
      background-color: #ffc43a;
      min-width: 200px;
      border-radius: 3px;
      font-style: italic;
      text-transform: none;
      font-size: 1.2rem;

      &:hover {
        background-color: rgb(233, 183, 67);
      }

      i {
        color: #012169;
      }

      span {
        &.first {
          color: #002f87;
        }

        &.last {
          color: #039cde;
        }
      }
    }
  }

  .cc-container {
    margin-bottom: 10px;
    margin-top: 0.5rem;

    .credit-card-form {
      flex: 1;

      h6 {
        text-transform: uppercase;
        font-weight: 600;
        margin-bottom: 0;
        font-size: .9rem;
        color: rgba(85, 85, 85, 1);

        @include max-width($md) {
          // margin-bottom: -.5rem;
        }
      }
    }

    .button-container {
      .btn {
        min-width: 120px;
        margin-left: 1rem;
        border-radius: 3px;
        background-color: #FF9966;
        border: none;
        position: relative;

        &:hover {
          background-color: #FF9966;
        }
      }
    }
  }
}

.or-container {
  display: flex;
  align-items: center;
  margin: 1.5rem 0;

  span {
    margin: 0 1rem;
    color: grey;
  }

  .line {
    flex: 1;
    background-color: rgba(0, 0, 0, 0.1);
    height: 1px;
  }
}

.billing-type-container {
  display: flex;

  .btn {
    flex: 1;
    text-align: left;
    text-transform: none;
    font-weight: 400;
    display: flex;
    align-items: center;
    border-radius: 3px;

    i {
      font-size: 1.2rem;
      margin-right: 0.7rem;
    }

    &.active {
      background-color: rgba(0, 0, 0, 0.05);
      color: darken($green, 10%);
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
      color: darken($green, 10%);
    }

    &:first-child {
      margin-right: 1rem;
    }
  }
}

.shipping-options-container {
  border-top: 1px dashed rgb(218, 218, 218);

  h4 {
    padding-top: 1rem;
    margin-bottom: 1rem;
    font-size: 1rem;
  }

  .shipping-option {
    background: white;
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 0.5rem 0.7rem;
    margin-bottom: 0.6rem;
    border-radius: 3px;
    font-size: 0.8rem;
    font-weight: 400;
    background-color: rgba(0, 0, 0, 0.05);
    align-items: center;
    margin-right: 0.75rem;
    transition: 0;
    border: 1px solid rgba(0, 0, 0, 0);

    &.active {
      font-weight: 600;
      color: darken($green, 15%);
      background-color: rgba($green, 0.2);
      border-color: $green;

      .status-container {
        i {
          &.checked {
            display: block;
          }

          &.unchecked {
            display: none;
          }
        }
      }
    }

    &:hover {
      background-color: rgba($green, 0.2);
      border-color: $green;
    }

    .status-container {
      margin-right: 0.7rem;

      i {
        font-size: 1.2rem;

        &.checked {
          display: none;
        }

        &.unchecked {
          display: block;
          color: rgb(134, 134, 134);
        }
      }
    }

    .text-container {
      flex: 1;
      text-align: left;
    }

    .price-container {
      span {
      }
    }
  }
}

.rccs_card,
.rccs__card--front {
  -webkit-backface-visibility: hidden;
}

.authorize-cc {
  margin-top: .5rem !important;

  &>div {
    width: 100%;
  }

	#field-wrapper {
    box-shadow: 0 0 7px -2px rgba(0, 0, 0, 0.1);
    width: 100%;
    display: flex;
    padding: .5rem !important;
    align-items: center;

    input {
      box-shadow: none;
      font-family: Lato, sans-serif;
      font-weight: 600;
      font-size: 1rem !important;

      &::placeholder {
        color: rgb(109, 109, 109);
        font-weight: 400;
      }

      @include max-width(767px) {
        padding-left: 0.3rem;
        padding-right: 0.3rem;
        font-size: .9rem !important;
      }
    }

    &>img {
      // position: absolute;
      // top: 0;
      // left: 0;
      // display: none;
    }

    label {
      height: 40px;
      margin-bottom: 0;
      margin-left: 0;

      @include max-width(767px) {
        height: 30px;
      }


      &:first-of-type {
       flex: 1;
       margin-left: 0;

       input {
        width: 100%;

        @include max-width(767px) {

        }
       }
      }

      &:nth-of-type(2) {
        width: 80px;
        

        input {
   
        }

        @include max-width(767px) {
          width: 25%;
        }
      }

      &:nth-of-type(3) {
        width: 80px;
        
        input {

        }

        @include max-width(767px) {
          width: 20%;
        }
      }
    }
  }
}

.billing-address-input-form {
  overflow: hidden;
  
  .btn {
    font-size: .8rem;
    flex: none;

    &.active {
      color: #131313;
    }

    &:hover {
      color: black;
    }
  }
}
.billing-address-input-form-header {
  text-transform: uppercase;
  font-weight: 400;
  margin-bottom: 0;
  font-size: 0.9rem;
  color: rgb(85, 85, 85);
}

.billing-address-input-form-elements {
  .form-control {
    font-family: Lato, sans-serif;
  }
}

.submitting-order-loader-container {
  position: relative;

  .submitting-order-loader {
    position: absolute;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.829);
    backdrop-filter: blur(2px);
    display: flex;
    align-items: center;
    justify-content: center;

    .circle-loader {
      padding: 0;
    }

    p {
      font-weight: 600;
    }
  }
}