#custom-gladly-chat-button {
	position: fixed;
	padding: 10px;
	right: 20px;
	bottom: 10px;
	z-index: 10;
}

/* 
	By default we hide the button 
*/
#custom-gladly-chat-button {
	display: none;
}
#custom-gladly-chat-button #with-active-chat {
	display: none;
}
#custom-gladly-chat-button #with-unread-message {
	display: none;
}

/* 
	If "gladly-show" is there we show the button 
*/
#custom-gladly-chat-button.gladly-show {
	display: block;
}

/* 
	If user has gone through onboarding the "gladly-has-authenticated" class will be added,
	we hide the element with the text "START NEW CHAT" and show the element with the text 
	"CONTINUE EXISTING CHAT"
*/
#custom-gladly-chat-button.gladly-has-authenticated #with-no-active-chat {
	display: none;
}
#custom-gladly-chat-button.gladly-has-authenticated #with-active-chat {
	display: block;
}
#custom-gladly-chat-button.gladly-unread #with-unread-message {
	display: block;
}

#custom-gladly-chat-button {
	background: rgb(63, 63, 63);
	border: none;
	border-radius: 5px;
	color: #fff;
	font-weight: 600;
	padding: .5rem .7rem;
  box-shadow: 0px 0 14px 10px #ffffff;

  &:hover {
    background: rgb(24, 24, 24);
    opacity: 1;
  }

  .icon-text-container {
    display: flex;
    align-items: center;
    justify-content: center;

    .icon-container {
      margin-right: .3rem;
      padding-right: .3rem;

      i {
        font-size: 1.2rem;

        @include max-width(767px) {
          font-size: .9rem;
        }
      }
    }

    .text {
      @include max-width(767px) {
        display: flex;
      }

      span {
        display: block;
        font-weight: 400;
        text-align: left;

        @include max-width(767px) {
          display: inline;
        }

        &.title {
          font-size: 1.1rem;
          color: $green !important;

          @include max-width(767px) {
            font-size: .9rem;
            margin-right: .3rem;
          }
        }

        &.subtitle {
          font-size: .9rem;
        }
      }
    }
  }
}