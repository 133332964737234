@tailwind base;
@tailwind components;
@tailwind utilities;

img {
    display: inline-block !important;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

.h1,
h1 {
  font-size: calc(1.375rem + 1.5vw);
}

@media (min-width: 1200px) {
  .h1,
h1 {
    font-size: 2.5rem;
  }
}
.h2,
h2 {
  font-size: calc(1.3rem + 0.6vw);
}

@media (min-width: 1200px) {
  .h2,
h2 {
    font-size: 1.75rem;
  }
}
.h3,
h3 {
  font-size: calc(1.25625rem + 0.075vw);
}

@media (min-width: 1200px) {
  .h3,
h3 {
    font-size: 1.3125rem;
  }
}
.h4,
h4 {
  font-size: 1.125rem;
}

.h5,
h5 {
  font-size: 1rem;
}

.h6,
h6 {
  font-size: 0.875rem;
}