.flow-preview {
  background-color: #fff;;
  border-radius: 5px;
  padding: 1rem;
  padding-bottom: 0;
  margin-top: 2rem;
  background-color: #EBEBF0;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 2 1'%3E%3Cdefs%3E%3ClinearGradient id='a' gradientUnits='userSpaceOnUse' x1='0' x2='0' y1='0' y2='1'%3E%3Cstop offset='0' stop-color='%23EBEBF0'/%3E%3Cstop offset='1' stop-color='%23EBEBF0'/%3E%3C/linearGradient%3E%3ClinearGradient id='b' gradientUnits='userSpaceOnUse' x1='0' y1='0' x2='0' y2='1'%3E%3Cstop offset='0' stop-color='%23C1D7F0' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%23C1D7F0' stop-opacity='1'/%3E%3C/linearGradient%3E%3ClinearGradient id='c' gradientUnits='userSpaceOnUse' x1='0' y1='0' x2='2' y2='2'%3E%3Cstop offset='0' stop-color='%23C1D7F0' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%23C1D7F0' stop-opacity='1'/%3E%3C/linearGradient%3E%3C/defs%3E%3Crect x='0' y='0' fill='url(%23a)' width='2' height='1'/%3E%3Cg fill-opacity='0.5'%3E%3Cpolygon fill='url(%23b)' points='0 1 0 0 2 0'/%3E%3Cpolygon fill='url(%23c)' points='2 1 2 0 0 0'/%3E%3C/g%3E%3C/svg%3E");
  background-attachment: fixed;
  background-size: cover;

  @include max-width($md) {
      margin-top: 0;
  }

  .description-with-devices {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 80%;
      margin: 0 auto;
      text-align: center;
      overflow: hidden;

      @include max-width($md) {
          width: 100%;
      }

      h3 {
          margin-bottom: 2rem;
          flex: 1;
          font-size: 1.5rem;
          color: rgb(48, 48, 48);
          line-height: 2rem;

          @include max-width($md) {
              font-size: 1rem;
              line-height: 1.5rem;
          }
      }

      .items {
          display: flex;
          flex: 1;

          .item {
              position: relative;
              margin: 0 1rem;
              border-top-left-radius: 5px;
              border-top-right-radius: 5px;
              box-shadow: 0 -2px 5px 5px #b0bccf5e;
              overflow: hidden;

              @include max-width($md) {
                  margin: 0;
                  &:first-child {
                      margin-right: .5rem;
                  }

                  &:last-child {
                      margin-left: .5rem;
                  }
              }

              .text {
                  text-transform: uppercase;
                  width: 100%;
                  background: #F0F0F5;
                  padding: .5rem;
                  text-align: center;

                  h5 {
                      font-size: .9rem;
                      line-height: 1.8rem;
                      margin-bottom: 0;
                      font-weight: 600;
                      color: #000;

                      @include max-width($md) {
                          font-size: .8rem;
                          line-height: 1rem;
                      }
                  }
              }
  
              img {
                  width: 300px;
              }
          }
      }
  }
}