.license-plate-search-container {
  border-radius: 3px;
  background-color: #fff;

  .license-plate-search {

    display: flex;
    flex-direction: column;
    // margin-top: 1rem;

    @include max-width($sm) {
      flex-direction: column;
    }

    .preview-container {
      width: 100%;
      margin-right: 1rem;
      padding: 1rem;


      @include max-width($sm) {
        margin-right: 0;
        margin-bottom: 1rem;
      }

      img {
        border-radius: 5px;
        overflow: hidden;
        width: 100%;
        border: 1px solid rgb(216, 216, 216);
      }
    }

    .right-container {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      box-shadow: 0 0 7px -2px rgba(0, 0, 0,.1);
      padding: 1rem;
      background-color: rgb(225 230 235);
      border-radius: 3px;
    }

    h3 {
      font-size: 1.2rem;
      font-weight: 600;
      color: #0ebcee;
      margin-bottom: 0;

      @include max-width($sm) {
        font-size: 1rem;
      }
    }

    p {
      line-height: 1rem;
      margin-top: .3rem;
    }

    .form-container {
      display: flex;
      flex-direction: column;
      border-radius: 3px;
      margin-top: 1rem;

      @include max-width($lg) {
        flex-direction: column
      }

      .form-item {
        display: flex;
        flex-direction: column;
        flex: 1;
        margin-bottom: 0.5rem;
        border: none !important;
  
        .srv-validation-message {
          margin-top: .2rem;
        }

      }

      .input-container-wrapper {
        width: 100%;

        @include max-width($lg) {
          margin-right: 0;
          margin-bottom: .5rem;
          width: 100%;
        }
      }

      label {
        display: none !important;
      }

      .form-input-title {
        color: #606f7b;
        display: block;
        font-size: 12px;
        font-weight: 700;
        letter-spacing: 0.4px;
        margin-bottom: 4px;
        text-transform: uppercase;
      }

      input, select {
        height: 40px;
        border-radius: 3px !important;
        border: none;
        font-size: .9rem;

        font-weight: 600;

      }

      input {
        letter-spacing: 2px;
        color: #202020;
        text-transform: uppercase;

        &::placeholder {
          letter-spacing: normal;
          text-transform: none;
          color: #8d8d8d;
        }
      }

      .btn {
        background-color: $green;
        color: white;
        border-radius: 3px;
        height: 40px;
        font-size: .9rem;
        padding-right: 1.5rem;
        padding-left: 1.5rem;
        margin-top: .5rem;

        i {
          margin-right: .5rem;
        }

        &:hover {
          background-color: darken($green, 10%);
        }
      }
    }
  }
}
.license-plate-search-result {
  margin-top: 2rem;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 0 20px #eaeaea;
  padding: .5rem 1rem;
  justify-content: space-between;

  @include max-width(767px) {
    flex-direction: column;
  }

  .content {
    display: flex;
    align-items: center;
    
    .text {
      margin-left: 1rem;
      border-left: 1px solid #d2d2d2;
      padding-left: 1rem;
    }
  }

  p {
    display: inline-block;
    color: #39393a;;
    padding: 0;
    margin-bottom: .5rem;
    font-size: .8rem;
    font-weight: 600;
  }

  h6 {
    font-size: 1.1rem;
    color: #27659e;
    font-weight: 600;
    margin-bottom: 0;

    @include max-width(767px) {
      font-size: 1.1rem;
    }
  }

  img {
    width: 150px;

    @include max-width(767px) {
      width: 100px;
    }
  }

  .button-container {
    @include max-width(767px) {
      margin-top: .7rem;
    }

    .btn {
      display: block;
      background-color: #0ebcee;
      color: #fff;
      line-height: 20px;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 700;
      transition: .3s ease-in-out;
      padding: .5rem 1rem;
      border-radius: 3px;
      text-align: center;
      margin-top: .5rem;

      @include max-width(767px) {
        flex-direction: column;
      }

      &:hover {
        background-color: #0aaad6;
        color: #fff;
        border-color: #0ebcee;;
      }
    }
  }
}

.model-unknown-disclaimer {
  padding: .8rem .5rem;
  border-radius: 3px;
  margin-top: 1rem;
  background: linear-gradient(80deg,#fff,#fff 35%,hsla(0,0%,100%,0) 96%);
  display: flex;
  margin-bottom: 1rem;
  align-items: center;
  justify-content: space-between;
  border-left: 2px solid #0ebcee;

  @include max-width(767px) {
    padding: .4rem .5rem;
  }

  .text-container {
    p {
      margin-bottom: 0;
      font-size: 1.0rem;
      color: #2f6777;
      line-height: 1.5rem;      

      @include max-width(767px) {
        font-size: .9rem;
      }


      i {
        color: $blue;
      }

      span {
        &.link {
          color: $blue;
          padding-bottom: .2rem;
          font-weight: 600; 
          border-bottom: 2px solid rgba($blue, .2);

          &:hover {
            cursor: pointer;
            border-bottom: 2px solid rgba($blue, .5);
          }
        }

        &.text {
          @include max-width(767px) {
            // display: block;
          }
        }
      }
    }
  }

  .btn-container {
    .btn {
      background-color: $blue;
      font-size: .7rem;
      padding-left: 1rem;
      padding-right: 1rem;
      color: white;
      border-radius: 100px;

      &:hover {
        background-color: darken($blue, 10%);
      }
    }
  }
}


.license-vin-container {
  .react-responsive-modal-modal {
    width: 400px;
    max-width: 100%;
    padding: 0;

    @include max-width(551px) {
      width: 90%;
    }
    
    .header {
      margin-bottom: 0;
      border-bottom: 1px solid #2f97b752;
      border-radius: 0;
      padding: 1rem 0 0 0;

      .nav {
        .btn {
          font-weight: 400;
          color: #303030;
          font-size: .9rem;
          border-radius: 0;
          padding: .5rem 1rem;
          margin-right: 1rem;
          border-bottom: 2px solid transparent;
          font-weight: 600;
          margin-bottom: -1px;

          &:active, &:focus {
            opacity: 1 !important;
            // color: #0ebcee !important;
            // border-bottom: 2px solid #2f98b7 !important;
          }

          &.active {
            color: #0ebcee !important;
            border-bottom: 2px solid #2f98b7 !important;
          }
        }
      }
    }

    .body {
      padding: 1rem;
    }

    .license-plate-search-container {

    }
  }
}

.where-to-find-vin {
  button {
    border: none;
    outline: none;
    
    &:hover {
      cursor: pointer;
    }
  }
}