/**
 * Pure CSS .nojs-dropdown & .nojs-navbar classes for Boostrap menus .
 *
 * @FIXME: While this works well for browsers without JavaScript, there are
 * accessibility issues for keyboard access and screen readers with this
 * implementation.
 **/

/**
 * Add ".nojs-dropdown" class to any Bootstrap ".dropdown" for a pure CSS menu.
 * Note: Do not also add JavaScript to the dropdown.
 **/
.nojs-dropdown {
  position: relative;
  display: inline-block;
  outline: none;
}
.nojs-dropdown .dropdown-toggle {
  cursor: pointer;
}
.nojs-dropdown .dropdown-menu {
  position: absolute !important;
  z-index: 1000 !important;
  display: block;
  visibility: hidden !important;
  transition: visibility 0.5s !important;
}
.nojs-dropdown:focus .dropdown-menu {
  visibility: visible !important;
  transition: opacity 0.1s !important;
}
@media (max-width: 767px) {
  .nojs-dropdown .dropdown-menu {
    top: 0px;
    visibility: visible !important;
  }
}

/* For right-align menus (automatic and override with .nojs-dropdown-right) */
.nojs-dropdown.pull-right .dropdown-menu,
.ml-auto .nojs-dropdown .dropdown-menu,
.nojs-dropdown.nojs-dropdown-right .dropdown-menu {
  left: auto !important;
  right: 0px !important;
}

/* Override default to left-align menus by adding .nojs-dropdown-left */
.nojs-dropdown.nojs-dropdown-left .dropdown-menu {
  left: 0px !important;
  right: auto !important;
}

/**
 *  .nojs-navbar navigation menu
 *  
 * To use, add the following inside your navbar:
 * 
 *  <input class="nojs-navbar-check" id="nojs-navbar-check" type="checkbox" aria-label="Menu"/>
 *  <label tabindex="1" htmlFor="nojs-navbar-check" class="nojs-navbar-label" />
 *  <div class="nojs-navbar"> <!-- Add your Navbar Menu --> </div>
 **/
.nojs-navbar {
  display: flex;
  flex-basis: auto;
  flex-grow: 1;
}
.nojs-navbar-label {
  display: none;
}
.nojs-navbar-check {
  display: none;
}
.nojs-navbar-check:checked ~ .nojs-navbar-label {
  border-bottom: 3px solid transparent;
  transition: border-bottom 0.8s ease-in-out;

  &:before {
    transform: rotate(-405deg) translateY(0px) translateX(-1px);
    transition: transform 0.5s ease-in-out;
  }

  &:after {
    transform: rotate(405deg) translateY(-5px) translateX(-6px); 
    transition: transform 0.5s ease-in-out;
  }
}
.nojs-navbar-check:checked ~ .nojs-navbar {
  max-height: 1000px;
}
@media (max-width: 767px) {
  .nojs-navbar {
    display: block;
    width: 100%;
    max-height: 0px;
    overflow: hidden;
    transition: max-height 0.5s ease-in-out !important;
  }
  .nojs-navbar-label {
    $width: 28px;
  
    display: inline-block;
    outline: 0;
    cursor: pointer;
    border-bottom: 3px solid currentColor;
    width: $width;
    transition: border-bottom 1s ease-in-out;
    
    &::-moz-focus-inner {
      border: 0;
      padding: 0;
    }

    &:before {
      content: "";
      display: block;
      border-bottom: 3px solid currentColor;
      width: 100%;
      margin-bottom: 5px;
      transition: transform 0.5s ease-in-out;
    }

    &:after {
      content: "";
      display: block;
      border-bottom: 3px solid currentColor;
      width: 100%;
      margin-bottom: 5px;
      transition: transform 0.5s ease-in-out;
    }
  }
}