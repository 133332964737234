.newsletter-container {
  background: #292828;
  padding: 1.5rem 0;

  @include max-width (1000px) {
    padding: 1.5rem 1rem;
  }

  .inner {
    width: 1000px;
    max-width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    border: 2px dashed rgba($blue-light, .2);
    border-radius: 3px;

    @include max-width($md) {
      flex-direction: column;
    }


    .text-container {
      padding-right: 1rem;
      margin-right: 1rem;
      border-right: 1px solid rgba(255,255,255,.1); 
      display: flex;

      @include max-width($md) {
        margin-right: 0;
        padding-right: 0;
        border-right: 0;
      }

      .icon {
        margin-right: .5rem;

        i {
          color: rgba($blue-light, .7);
          font-size: 1.6rem;
        }
      }

      h4 {
        font-size: 1.6rem;
        font-weight: 600;
        color: $blue;

        @include max-width($sm) {
          font-size: 1.3rem;
        }
      }

      p {
        margin-bottom: 0;
        font-size: .9rem;
        line-height: 1.2rem;
        color: rgb(37, 37, 37);
        color: whitesmoke;
      }
    }

    .form-container {
      @include max-width($md) {
        margin-top: 1rem;
      }

      .form-container-close {
        position: relative;
        display: flex;  

        &>div {
          &>div {
            background: #fff;
            position: absolute;
            top: 45px;
            padding: .5rem;
            z-index: 999;
            border-radius: 3px;
          }
        }

        .loading {
          color: white;
          position: absolute;
          top: 0;
          left: 0;
          height: 38px;
          padding: .5rem;
          background: rgba(#35434e, .95);
          width: 100%;
          border-radius: 3px;
          display: flex;
          align-items: center;

          p {
            margin-bottom: 0;
            color: white;
          }
        }

        .success {
          margin-top: 1rem;
          border: 1px solid rgba($green, .5);
          border-radius: 3px;
          padding: .5rem;

          p {
            color: $green;
            margin-bottom: 0;
          }
        }

        .error {
          margin-top: 1rem;
          border: 1px solid rgba($red, .5);
          border-radius: 3px;
          padding: .5rem;
          color: $red;
        }
      }

      &>div {
        &>div {
          display: flex;

          input {
            outline: none;
            display: block;
            width: 250px;
            padding: 0.375rem 0.75rem;
            font-size: 1rem;
            line-height: 1.5;
            color: white;
            background-color: #35434e;
            background-clip: padding-box;
            border: 1px solid rgba(#495057, .8);
            border-radius: 0.25rem;
            transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
            margin-right: .3rem;

            @include placeholder {
              color: whitesmoke;
            }

            @include max-width($sm) {
              width: 200px;
            }
          }

          button {
            width: 100px;
            font-size: 14px;
            border: none;
            background-color: $green;
            color: white;
            border-radius: 3px;
            text-transform: uppercase;
            font-weight: 600;
            padding-left: 1rem;
            padding-right: 1rem;

            @include max-width($sm) {
              width: auto;
            }

            &:hover {
              background-color: darken($green, 10%);
            }
          }
        }
      }
    }
  }
}