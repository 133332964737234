@import "./date-picker";

.date-time-picker {
  display: flex;
  justify-content: space-between;
  border: 2px solid rgba(134, 229, 255, 0.15);
  border-top: none;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  transition: .5s all;
  position: relative;
  opacity: .5;

  &.starting-time {
    opacity: 1;
    padding: 0;
    border: none;
    margin: .8rem 0;
    display: flex;
    gap: 1rem;

    .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before {
      border-bottom-color: #229bc1;
    }

    
    .react-datepicker__day, .react-datepicker__day-name {
      @include max-width(767px) {
        width: 1.5rem;
        line-height: 1.5rem;
      }
    }

    @include max-width($md) {
      flex-direction: column; 
    }

    .item {
      &.last {
        @include max-width($md) {
          margin-top: 1rem ;
        }
      }
    }
 

    .react-datepicker {
      display: flex;
      border: 2px solid #229bc1 !important;

      @include max-width($md) {
        // flex-direction: column; 
      }
  
    }
    

    label {
      color: #414141;
      font-weight: 600;
      font-size: .7rem;
      text-transform: uppercase;
    }

    input {
      display: block;
      width: 100%;
      padding: 0.375rem 0.75rem;
      font-size: 1rem;
      line-height: 1.5;
      color: #495057;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #ced4da;
      border-radius: 0.25rem;
      -webkit-transition: border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
      transition: border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
      transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
      transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    }

    .react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
      @include min-width(768px) {
        right: 50%;
      }
    }


    @include max-width($md) {
      display: block;
    }

    .react-datepicker__time-container, .react-datepicker__month-container  {

      @include max-width($md) {
        width: 100% ;
      }
    } 

    .react-datepicker__time-container  {
      width: auto !important;
      border-left: none;
    }
    
    .react-datepicker__time-box {
      padding: .5rem;
      width: auto !important;
      background-color: #fff;

      @include max-width($md) {
        width: 100% !important;
      }

      .react-datepicker__time-list {

        .react-datepicker__time-list-item {
          background: none;
          border: 2px solid rgba(14,188,238,.1);
          border-radius: 2px;
          margin-bottom: 0.5rem;
          width: 100%;
          text-transform: none;
          font-weight: 400;
          padding: 0.5rem 2.0rem !important;
          color: #0a83a6;
          -webkit-transition: all .2s;
          transition: all .2s;
          font-size: .9rem;
          height: auto !important; 

          &.react-datepicker__time-list-item--selected {
            background-color: #229bc1;
          }

          @include max-width($md) {
           
          }
          
        }
      }
    }
  }

  @include max-width($md) {
    display: none;
  }

  &.active {
    display: flex !important;
    opacity: 1;
  }

  .loading-available-times-overlay {
    position: absolute;
    top: 0;
    left: 0; 
    font-size: 15; 
    z-index: 999;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;

    p {
      color: black;
      font-size: 1rem;
      margin-bottom: 0;
    }
  }

  @include max-width(767px) {
    flex-direction: column;

    .date, .time {
      display: none;
    }
  }

  &:hover {
    opacity: 1;
  }

  &.active {
    border-color: #259BC1;
    opacity: 1;

    .date, .time {
      // display: flex !important;
    }

    .shade {
      display: none !important;
    }

    @include max-width(767px) {
      height: auto;
    }
  }

  .react-datepicker {
    border: none;
  }

  .react-datepicker__header {
    background: none;
    border-color: #f1f1f1;
  }

  .react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
    font-weight: 400;
  }

  .react-datepicker__month-container {
    border-right: 1px solid #f1f1f1;
  }

  .react-datepicker__month {
    
  }

  .react-datepicker__week {
    
  }

  .react-datepicker__day--selected {
    background-color: #229bc1;
  }

  .react-datepicker__day--keyboard-selected {
    background: none !important;
    color: #000;
  }

  .react-datepicker__day--disabled {
    color: #ccc !important;
  }

  .react-datepicker__day, .react-datepicker__day-name {
    font-size: .9rem;
    width: 2.5rem;
    line-height: 2.5rem;
    border-radius: 100px;

    @include max-width(767px) {
      width: 2rem;
    line-height: 2rem;
    }

    &:focus {
      box-shadow: none;
      outline: none;
    }
  }

  .date {
    position: relative;

    @include max-width(767px) {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .shade {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(255,255,255,.7);
      z-index: 9999;
      display: none;

      @include max-width(767px) {
        display: block;
      }
    } 
  }

  .time {
    display: none !important;
    margin-left: 1rem;
    flex: 1;
    // position: relative;
    // overflow-y: scroll;
    // padding-right: 1rem;
    align-items: center;
    display: flex;
    height: auto !important;


    h6 {
      display: none;
      margin-bottom: 0;
      margin-right: .5rem;

      @include max-width(767px) {
       display: block;
      }
    }

    .react-datepicker-wrapper {
      @include max-width(767px) {
        width: 100%;
      }
      .react-datepicker__input-container {
        input {
          @include max-width(767px) {
            font-size: 1rem;
            font-weight: 600;
            color: rgb(70, 70, 70);
            width: 100%;
            border: 2px solid rgba(14, 188, 238, 0.15);
            padding: .3rem .5rem;
            border-radius: 3px;
          }
        }
      }
    }

    .react-datepicker__time-container  {
      width: 100%;
      .react-datepicker__time {
        width: 100%;
        .react-datepicker__time-box {
          width: 100% !important;
          .react-datepicker__time-list {
            height: calc(250px + (1.7rem / 2));
            min-width: 200px !important;

            @include max-width(767px) {
              height: 300px;
              width: 200px;
              padding: 1rem;
              border: 2px solid #d8d8d8;
              border-top: none;
            }

            .react-datepicker__time-list-item--disabled {
              color: rgb(184, 184, 184) !important;
              display: none;
            }

            .react-datepicker__time-list-item  {
              width: 100%;
              border: 1px solid rgba($blue, .3);
              color: darken($blue, 10%);
              border-radius: 5px;
              margin-top: .5rem;
              font-weight: 500;
              padding: 0.5rem;
              font-size: .9rem;
              height: auto;

              span {
                font-weight: 600;
              }

              &:hover {
                background-color: rgba($blue, .1);
              }

              &.react-datepicker__time-list-item--selected {
                background-color: #259BC1 !important;
                color: white;

                &.react-datepicker__time-list-item--disabled  {
                  background-color: #fff !important;
                } 
              }
            }
          }
        }
      }
    }
  }
}

.eta-disclaimer {
  display: flex;
  padding: .5rem;
  border: 2px solid rgba(14, 188, 238, 0.15);
  flex-direction: row-reverse;
  border-top: 0;
  border-bottom-left-radius: 3;
  border-bottom-right-radius: 3;
  align-items: center;
  color: $grey;

  img {
    width: 32px;
    height: 32px;
  }

  p {
    line-height: 1.2rem;
    margin-bottom: 0;
    border-right: 1px solid rgb(224, 224, 224);
    padding-right: .5rem;
    margin-right: .5rem;

    @include max-width(767px) {
      font-size: .8rem;
    }
  }
}

.eta-content {
  padding: .5rem;
  border: 2px solid rgba(14, 188, 238, 0.15);
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;

  p {
    margin-bottom: 0;
  }
}

.date-time-option-selection {
  background-color: #fff;
  display: flex;
  flex-direction: column;

  @include max-width($md) {
    margin-top: 1rem;
  }

  .btn {
    background: none;
    text-align: left;
    background: none;
    margin-bottom: 0;
    border-radius: 3px;
    text-transform: none;
    font-size: 1.0em;
    padding: .7rem .7rem;
    margin: 0;
    color: #157392;
    font-weight: 400;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    text-transform: uppercase;
    border: 2px solid rgba(14, 188, 238, 0.15);
    align-items: center;
    font-weight: 600;

    &:hover {
      background-color: #156783 !important;
      color: #fff;
    }

    &:focus {
      background-color: #259BC1 !important;
      color: #fff;  
    }

    br {
      display: none;

      @include max-width(767px) {
        display: block;
      }
    }

    span {
      background: white;
      color: #279bc1;
      padding: .2rem .7rem;
      border-radius: 100px;
      font-size: .8rem;
      text-transform: none;
      font-weight: 600;
      margin-left: 1rem;
      
      i {
        font-size: 1rem;
      }
    }

    i {
      font-size: 1.1rem;
      margin-right: .3rem;
    }


    &.active {
      background-color: #259BC1;
      color: #fff;
    }
  }
}

.time-range-list-container {
  width: 100% !important;
}

.time-range-list {
  padding: 1rem;
  width: 100% !important;
  // border: 1px solid white;

  &.error {
    // border: 2px solid rgb(248, 181, 181);

    .error-disclaimer {
      color: rgb(223, 43, 43);
      background-color: rgba($red, .1);
      padding: .5rem;
      text-align: center;
    }
  }

  h4 {
    font-size: 1rem;
    margin-bottom: 1rem;
  }

  .btn {
    background: none;
    border: 2px solid rgba($blue, .1);
    border-radius: 2px;
    margin-bottom: .5rem;
    width: 100%;
    text-transform: none;
    font-weight: 400;
    padding: .6rem;
    color: darken($blue, 15%);
    transition: .2s all;
    font-size: .9rem;

    span {
      font-weight: 600;
    }

    &:hover {
      color: #fff;
      background-color: darken($blue, 15%);
    }

    &.active {
      color: #fff;
      background-color: darken($blue, 15%);
    }
  }

  .unavailable-time-slot {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    background: #f3f3f3;
    border-radius: 4px;
    padding: 1rem;
    margin-bottom: 1rem;

    p {
      text-align: center;
      line-height: 1.2rem;
      margin-bottom: 0;
    }
  }
}