
.reviews {
    &.text-banner {
        .intro {
            margin: 1rem 0;
            background: linear-gradient(41deg,hsla(0,0%,100%,0),hsla(0,0%,100%,0) 50%,#bfeaf9);
            border-radius: 3px;
            display: flex;
            align-items: center;

            .text {
                text-align: left;
                flex: 1;

                span.p {
                    font-size: .9rem;
                    color: rgb(31, 31, 31);
                    margin-bottom: 0;
                    line-height: 1.4;
    
                    span {
                        font-weight: 600;
                    }
                }
            }

            .logo {
                border-right: 1px solid #d7e1ec;
                margin-right: .7rem;
                a {
                    margin-right: .7rem;
                    img {
                        width: 60px;
                    }
                }
            }
        }
    }

    &.dropdown {
        position: relative;
        border: none;
        padding: .2rem .3rem;
        background-color: #fff;
        border-radius: 5px;
        margin-left: .5rem;
        text-align: center;
        color: rgb(145, 145, 145);

        .star-ratings {
            display: flex !important;
        }

        ul {
            line-height: 1rem;
            list-style-type: none;
            display: flex;
            flex-direction: column;
            margin: 0;
            padding: 0;
            
            li {
                font-size: .80rem;
                font-weight: 400;
                
                span {
                    color: rgb(46, 46, 46);
                    font-weight: 600;
                }
            }
        }

        .overlay {
            z-index: 999;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    &.sidebar {
        @include max-width($lg) {
            display: none;
        }

        border-radius: 3px;
        border: 2px solid #c2ecf8;

        .head {
            padding: 1rem;
            position: relative;
            text-align: center;
            background: linear-gradient(41deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 50%, #bfeaf9 100%);

            h5 {
                margin: 0;
                font-size: 1.2rem;
                color: rgb(44, 44, 44);
            }

            i {
                font-size: 2rem;
                color: #99CB58;
            }
        }

        .intro {
            padding: .7rem 0;
            border-top: 2px solid #bfeaf973;
            border-bottom: 2px solid #bfeaf973;

            .items {
                display: flex;
                justify-content: space-between;
                padding: .2rem 1rem;
                align-items: center;

                &:first-child {
                    
                }

                .item {
                    span {
                        font-size: 1.0rem;
                    }

                    &.condition {
                        span {
                            font-weight: 600;
                            font-size: 1.0rem;
                        }
                    }

                    &.review-stars {
                        svg {
                            width: 20px !important;
                            height: 20px !important;
                        }
                    }
                }
            }
        }

        .body {
            padding: .5rem;
            height: 800px;
            overflow-y: scroll;

            .blockquote-footer {
                font-size: .8rem;
                margin-top: .2rem;
                margin-top: .5rem;

                span {
                    font-size: .85rem;
                }
            }

            blockquote {
                border: none;
                padding: .8rem;
                background-color: #fff;
                margin-bottom: .3rem;
                font-size: .9rem;

                p {
                    line-height: 1.3rem;
                    font-size: rgb(31, 31, 31);
                }
                
                small {
                    font-style: italic;
                    font-size: .85rem !important;
                }
            }
        }

        footer {
            padding: .5rem .5rem;
            margin-top: 0;
            text-align: center;

            p {
                a {
                    font-weight: 600;

                    img {
                        width: 120px;
                        margin-left: .5rem;

                        &:hover {
                            transition: .1s all;
                            opacity: .5;
                        }
                    }
                }
            }
        }
    }

    &.static {
        border: none;
        background: linear-gradient(273deg, rgba(233, 236, 239, 0) 0%, white 50%, rgba(255, 255, 255, 0) 96%);
        box-shadow: 0 0 7px -2px rgba(0, 0, 0, 0.1);
        padding: 2rem 1rem 1rem 1rem;
        margin-bottom: 2rem;

        h3 {
            text-align: center; 
            color:#064A5E;
            font-size: 2rem;
            font-weight: 600;

            @include max-width($lg) {
                font-size: 1.5rem;
                margin-bottom: 0;
                padding-bottom: .5rem;
            } 
        }

        .intro {
            margin: 1rem 0;
            text-align: center;

            span.p {
                font-size: 1.4rem;
                color: #000;

                span {
                    font-weight: 600;
                }
            }
        }

        .text-banner-widget {
            transform: scale(.8);

            @include max-width($lg) {
                transform: scale(1);
            }
        }

        .review-items {
            display: flex;
            
            @include max-width($lg) {
                flex-direction: column;
            }

            .review-item {
                background-color: #fff;
                margin-right: 1rem;
                border-radius: 5px;
                text-align: center;
                box-shadow: 0 0 7px -2px rgba(0, 0, 0, 0.1);
                padding: 1rem;
                flex: 1;

                @include max-width($lg) {
                    margin-right: 0;
                    margin-bottom: .5rem;
                }
                
                &:last-child {
                    margin-right: 0;

                    @include max-width($lg) {
                        margin-bottom: 0;
                    }
                }

                blockquote {
                    font-size: 1rem;
                    padding: 1rem;
                    padding-top: 0;
                    margin: 0;
                    border: none;
                    font-style: italic;

                    @include max-width($lg) {
                        padding: 0;
                    }
                }

                small {
                    font-size:  1rem;
                }
            }
        }

        .view-more {
            text-align: center;
            margin-top: 1rem;

            p {
                font-weight: 600;
                margin-bottom: 0;
                display: flex;
                align-items: center;
                justify-content: center;

                a {
                    margin-left: .5rem;

                    img {
                        width: 100px;
                    }
                }
            }
        }
    }
}