/**
 * Content
 * ----------------------------------------------------------------------------
 */
.post {
  margin-bottom: 3.33333em;
}

.post-header {
  margin-bottom: 2.5rem;
  text-align: center;

  .post-title {
    margin: 0 0 1.66667rem;

    a {
      border: 0;
      color: inherit;

      &:hover,
      &:focus {
        color: _palette(primary);
      }
    }
  }
}

.post-subtitle {
  border-bottom: 1px solid _palette(border);
  font-size: 1.33333rem;
  line-height: 1.5;
  margin-bottom: 1.66667rem;
  padding-bottom: 1.33333rem;
}

.post-thumbnail {
  display: block;
  margin-bottom: 1.66667rem;

  img {
    width: 100%;
  }
}

.post-meta {
  color: _palette(meta);
  font-size: 0.88889rem;
  line-height: 1.5;
  margin-bottom: 1.66667rem;
}

/* Post feed */
.post-feed {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 -0.83333em 1.66667em;

  .post-card {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex: 0 0 33.333%;
    -ms-flex: 0 0 33.333%;
    flex: 0 0 33.333%;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-bottom: 1.66667em;

    @media only screen and (max-width: 900px) {
      -webkit-flex: 0 0 50%;
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
    }

    @media only screen and (max-width: 600px) {
      -webkit-flex: 0 0 100%;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
    }
  }

  .post-card-inside {
    background: #fff;
    border: 1px solid _palette(border);
    border-radius: 3px;
    box-shadow: 0 3px 10px 0 rgba(_palette(primary),.1);
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    margin: 0 0.833333em;
  }

  .post-card-thumbnail {
    border: 0;
    border-radius: 3px 3px 0 0;
    display: block;
    margin: 0;
    position: relative;
    width: 100%;

    &:before {
      content: "";
      display: block;
      padding-top: 66.666%;
    }

    img {
      border-radius: 3px 3px 0 0;
      height: 100%;
      left: 0;
      object-fit: cover;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }

  .post-card-content {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 1.33333rem;
  }

  .post-header {
    margin-bottom: 0.83333rem;
    text-align: left;
  }

  .post-title {
    font-size: 1.33333rem;
    margin: 0;
  }

  .post-excerpt {
    font-size: 16px;
  }

  .post-meta {
    font-size: 14px;
    margin: 0;
  }
}

@media only screen and (max-width: 750px) {
  .post-feed {
    margin-left: -2vw;
    margin-right: -2vw;

    .post-card {
      margin-bottom: 4vw;
    }

    .post-card-inside {
      margin-left: 2vw;
      margin-right: 2vw;
    }
  }
}

/* Post navigation */
.read-next {
  margin-top: 3.33333em;

  .read-next-title {
    font-size: 1.66667rem;
    margin: 0 0 1.6667rem;
    text-align: center;
  }

  @media only screen and (min-width: 601px) {
    .post-card {
      -webkit-flex: 0 0 50%;
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
    }
  }
}

/* Pagination */
.pagination {
  margin: 0 0 1.66667rem;

  .nav-links {
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  }

  .page-number {
    color: _palette(meta);
    -webkit-flex-grow: 1;
    -ms-flex: 1;
    flex-grow: 1;
    font-size: 14px;
    line-height: 1.5;
    text-align: center;
  }

  .newer-posts,
  .older-posts {
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    position: relative;
  }

  .older-posts {
    padding-left: 15px;
    padding-right: 40px;
  }

  .newer-posts {
    padding-left: 40px;
    padding-right: 15px;
  }

  .icon-arrow-left {
    left: 15px;
    margin-left: 0;
  }

  .icon-arrow-right {
    left: auto;
    margin-left: 0;
    right: 15px;
  }

  @media only screen and (max-width: 400px) {
    .nav-links {
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }

    .newer-posts {
      margin-bottom: 0.83333rem;
    }

    .older-posts {
      margin-top: 0.83333rem;
    }
  }
}
