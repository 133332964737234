.header-new {
	box-shadow: 0 0 7px -2px rgba(0, 0, 0, 0.2);
	background-color: #fff;
	ul {
		list-style-type: none;
		margin: 0;
		
		li {
			a {
				align-items: center;
			}
		}
	}

	&.checkout {
		background: #F3F3F3;
		box-shadow: none;
		height: auto;

		.container {
			width: 1100px;
			max-width: 100%;
			display: flex;
			align-items: flex-end;
		}

		.title {
			h1 {
				font-size: 1.3rem;
				font-weight: 600;
				margin-bottom: 0;
				margin-left: 1rem;
				color: #999997;
				text-transform: none;
			}
		}

		.logo-cont {
			border-right: 1px solid rgba(0, 0, 0, 0.1);
			padding-right: 0.7rem;
		}

		.logo {
			width: 150px;
			margin-right: 1rem;
		}
	}

	@include max-width($md) {
		height: 130px;
	}

	@include max-width($sm) {
		height: 180px;
	}

	& > .container {
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;

		& > .row {
			@include max-width($md) {
				flex-direction: column;
			}
		}
	}

	.logo-col {
		@include max-width($xl) {
			flex: 0.5;
		}

		.logo-container {
			display: flex;
			justify-content: space-between;
			align-items: center;

			@include max-width($md) {
				margin-bottom: 0.7rem;
			}

			.with-menu-icon {
				display: flex;

				img {
					width: auto;
					height: 60px;

					@include max-width($md) {
						width: auto;
						height: 60px;
					}
				}
			}

			button {
				border: none;
				background: none;
				display: none;
				color: $primary;
				padding-left: 0;
				padding-right: 0.5rem;

				@include themify($themes) {
					color: themed('colorPrimary');
				}

				@include max-width($lg) {
					display: block;
				}

				i {
					font-size: 1.5rem;
				}
			}

			.cart {
				display: none;
				font-size: 1.1rem;
				color: rgba(black, 0.6);
				background-color: rgba(0, 0, 0, 0.05);
				padding: 0.5rem;
				border-radius: 3px;
				margin-left: 0.5rem;

				a {
					color: rgba(black, 0.6);
					display: flex;
					align-items: center;
					justify-content: center;
				}

				i {
					margin-right: 0.1rem;
				}

				span {
					color: darken($primary, 20%);

					@include themify($themes) {
						color: darken(themed('colorPrimary'), 20%);
					}
				}

				@include max-width($md) {
					display: flex !important;
				}
			}
		}
	}

	.far-right {
		display: flex;
		flex-direction: column;
		align-items: flex-end;

		@include max-width($xl) {
			display: none;
		}
	}

	.need-help-new {
		margin-bottom: 0.5rem;
		background-color: rgba(0, 0, 0, 0.05);
		padding: 0.5rem 0.6rem;
		border-radius: 3px;
		font-size: 0.8rem;

		p {
			margin-bottom: 0;
			font-size: 1rem;
		}

		span {
			color: darken($primary, 10%);

			@include themify($themes) {
				color: darken(themed('colorPrimary'), 10%);
			}
			font-weight: 600;
		}
	}

	.navigation {
		display: flex;
		justify-content: space-between;

		.state {
			margin-left: 0.3rem;
			width: 10px;
		}

		&.desktop {
			.site-related {
				@include max-width($lg) {
					display: none;
				}
			}

			.user-related {
				@include max-width($md) {
					display: none;
				}
			}
		}

		&.user-related-container {
			justify-content: flex-end !important;

			@include min-width(1200px) {
				justify-content: center ;
			}
		}

		ul {
			display: flex;

			li {
				position: relative;
				&:hover {
					.dropdown {
						display: block;
					}
				}

				.dropdown {
					display: none;
					position: absolute;
					z-index: 999;
					top: 28px;
					left: 0;
					background-color: rgb(255, 255, 255);
					border: 1px solid rgb(238, 238, 238);
					border-radius: 3px;
					width: 150px;
					padding: 0.3rem;

					li {
						display: block;
						border-bottom: 1px solid rgb(238, 238, 238);
						padding-bottom: 0.3rem;
						font-size: 0.75rem;

						&.logout {
							a {
								color: rgba($red, 0.8);
							}
						}

						&:last-child {
							border-bottom: none;
							margin-bottom: 0;
							margin-top: 0.3rem;
						}

						a {
							display: flex;
							width: 100%;
						}
					}
				}
			}

			&:last-child {
				li {
					&:last-child {
						a {
							margin-right: 0;
						}
					}
					a {
						font-weight: 400;
					}
				}
			}

			&.temp {
				display: none;
				@include max-width($xl) {
					display: flex;
				}
			}

			li {
				font-size: 0.8rem;

				a {
					display: flex;
					padding: 0.3rem 0.7rem;
					text-transform: uppercase;
					color: rgba(black, 0.6);
					font-weight: 600 !important;
					transition: 0.1s;
					margin-right: 0.2rem;
					border-radius: 3px;

					i {
						margin-right: 0.5rem;
					}

					&:hover {
						background-color: rgba($primary, 0.7);
						text-decoration: none;
						@include themify($themes) {
							background-color: rgba(themed('colorPrimary'), 0.7);
						}
						color: white;
						cursor: pointer;
					}
				}
			}
		}
	}
}

.burger-menu-container {
	@include min-width(992px) {
		display: none;
	}
}

.bm-menu-wrap {
	background-color: #f3f3f3;
	padding: 0.5rem;

	.burger-logo {
		background-color: #fff;
		outline: none;
		padding: 0.5rem;
		text-align: center;

		img {
			height: 40px;
			max-width: 80%;
		}
	}

	.bm-item-list {
		.burger-navigation-items {
			outline: none;

			&.user-related {
				border-bottom: 1px solid rgba(0, 0, 0, 0.1);
				padding-bottom: 0.5rem;
				margin-bottom: 0.5rem;
				display: none !important;

				@include max-width($md) {
					display: block !important;
				}
			}

			li {
				display: block;

				&.logout {
					.menu-item {
						color: rgba($red, 0.8);
					}
				}

				.menu-item {
					background-color: #fff;
					display: block;
					padding: 0.5rem 0.7rem;
					text-transform: uppercase;
					color: rgba(black, 0.6);
					font-weight: 600 !important;
					transition: 0.1s;
					margin-top: 0.2rem;
					font-size: 0.8rem;
					display: block;

					i {
						width: 1.5rem;
					}

					&:hover {
						background-color: rgba($primary, 0.7);
						color: white;

						@include themify($themes) {
							background-color: rgba(themed('colorPrimary'), 0.7);
						}
					}
				}

				.sub-menu-container {
					background-color: #fff;
					padding: 0.1rem 0.3rem;

					h6 {
						margin-top: 0.5rem;
						color: rgba(black, 0.4);
						font-weight: 600;
						text-transform: uppercase;
						font-size: 0.8rem;
						border-bottom: 1px solid rgba(0, 0, 0, 0.1);
						padding-bottom: 0;
						margin-bottom: 0.3rem;
					}

					a {
						background-color: rgba(grey, 0.1);
						border-bottom: 1px solid rgba(grey, 0.2);
						display: block;
						padding: 0.3rem;
						color: darken(grey, 20%);

						&:hover {
							color: $primary;

							@include themify($themes) {
								color: darken(themed('colorPrimary'), 10%);
							}
						}
					}
				}
			}
		}
	}
}

.bm-burger-button {
	display: none;
}

.all-categories-desktop {
	background-color: #f3f3f3;
	border-bottom: 2px solid rgba(0, 0, 0, 0.1);
	padding: 1.5rem 0 1rem 0;
	display: block;
	position: absolute;
	left: 0;
	width: 100%;
	z-index: 999;

	&.hidden {
		visibility: hidden;
		opacity: 0;
		transition: visibility 0s, opacity 0.5s linear;
	}

	&.visible {
		visibility: visible;
		opacity: 1;
	}

	.container {
		position: relative;

		.close {
			position: absolute;
			background-color: rgba(0, 0, 0, 0.1);
			color: #d43c3c;
			top: -10px;
			right: 22px;
			border: none;
			text-shadow: none;
			padding: 0.1rem 0.5rem;
			border-radius: 3px;
		}
	}

	.group {
		margin-bottom: 0.5rem;

		h6 {
			margin-bottom: 0;
			text-transform: uppercase;
			font-weight: 600;
			color: black;
		}

		ul {
			display: flex;
			flex-wrap: wrap;

			li {
				margin-bottom: 0.5rem;
				margin-right: 0.668%;
				width: 16%;

				&:last-child {
					margin-right: 0;
				}

				a {
					padding: 0.5rem;
					color: rgba(0, 0, 0, 0.6);
					background: #fff;
					display: block;
					transition: 0.1s;
					border-radius: 3px;

					&:hover {
						cursor: pointer;
						box-shadow: 0 0 7px -2px rgba(0, 0, 0, 0.4);
						color: darken($green, 10%);
						transform: scale(1.02);

						@include themify($themes) {
							color: darken(themed('colorPrimary'), 10%);
						}
					}
				}
			}
		}
	}
}

.breadcrumb-section {
	background-color: #f7f7f7;
	padding: 0;
	border-top: 1px solid #efefef;
	border-bottom: 1px solid #efefef;

	@include max-width($md) {
		display: none;
	}

	.theme-breadcrumb {
		display: flex;
    justify-content: flex-end;
	}

	.breadcrumb {
		background: none;
		margin-bottom: 0;

		.breadcrumb-item {
			text-transform: uppercase;
			font-size: .9rem;
		}

		a {
			font-weight: 400;
			color: rgb(163, 163, 163);
		}
	}

	.page-title {
		h2, h1 {
			font-size: 1.1rem;
			font-weight: 600;
			margin-bottom: 0;
			padding: .3rem 0;
			text-transform: uppercase;
			color: $grey;
		}
	}
}

.header-simplified {
	background-color: rgba(255, 255, 255, 0.911);
	z-index: 5;
	width: 100%;
	padding: 0.5rem 0;
	position: fixed;
	box-shadow: 1px 1px 12px -9px #484ab7;

	.inner {
		display: flex;
		justify-content: space-between;
		align-items: center;

		.logo-container {
			img {
				width: 180px;
			}
		}

		.menu-items-container {
			ul {
				display: flex;
				margin-bottom: 0;
				list-style-type: none;
				
				li {
					margin-left: 1rem;
					@include max-width($md) {
						display: none;
					}

					a {
						padding: 0.5rem;
						display: block;
						border-radius: 3px;
						font-weight: 400;
						font-size: 0.8rem;
						border: none !important;
						color: rgb(104, 104, 104);
						font-weight: 600;

						&:hover {
							color: rgb(29, 29, 29);
							border: none;
							box-shadow: none;
							text-decoration: none;
						}
					}

					&.highlighted {
						display: block;

						a {
							background-color: none;
							color: #4c942f;
							border: 1px solid #4c942f !important;

							&:hover {
								background-color: #4c942f;
								color: #fff;
							}
						}
					}
				}
			}
		}
	}
}
