.welcome-module {
  p {
    color: $grey;
  }

  .inner {
    display: flex;
    justify-content: space-between;
    padding: .7rem 1rem;
    background-color: whitesmoke;

    .text {
      h3 {
        font-size: 1.0rem;

        span {
          font-size: 1.4rem;
          color: black;
        }
      }
    }

    .button-container {
      align-items: center;
      display: flex;

      @include max-width($md) {
        display: none;
      }

      .button, button {
        color: #505050;
        padding: .3rem 1rem;
        background: rgba(0, 0, 0, 0.05);
        border-radius: 5rem;  
        font-weight: 600;
        border: none;
        font-size: .8rem;
      }
      
    }

    .user-card {
      display: flex;
      align-items: center;

      .icon {
        margin-right: .7rem;


        i {
          font-size: 1.5rem;
          color: $grey;
        }
      }

      .text {
        border-left: 1px solid #e0e0e0;
        padding-left: .7rem;

        h3 {
          margin-bottom: 0;

          &:first-child {
            font-weight: 600;
            margin-bottom: .3rem;
          }
        }
      }

      .button-container {
        display: none;
        margin-top: .5rem;

        @include max-width($md) {
          display: block;
        }
      }
    }
  }
}