// Fonts
$font_0: Lato;
$font_1: sans-serif;
$font-themify: themify;
$font-awesome: 'Font Awesome 5 Pro';
$assetspath: "/static/images";

// Colors
$white: #ffffff;
$purple: #484ab7;
$white-dark: #eeeeee;
$black: #000000;
$dark-font: #333333;
$grey-dark: #575f70;
$font-color: #2e2e2e;
$grey: #777777;
$grey-light: #a1a1a1;
$border: #dddada;
$round-border: #dddddd;
$grey-lighter: #f9f9f9;
$theme-default: #ff4c3b;
$grey-darken: #393230;
$grey-link: #948e8c;
$grey-font: #938d8c;
$dark-footer: #2e2726;
$form-bg: #f5f2f2;
$grey-shade: #7f786d;
$darker-footer: #25221e;
$border-color: #38352f;
$border-grey: #f1f5f4;
$font-grey: #aaaaaa;
$star-yellow: #ffa200;
$border-bottom-g: #525252;
$top-header: #f8f8f8;
$header-font: #999999;
$shadow: #ededed;
$box-border: #ecececa8;
$grey-about: #f7f7f7;
$grey2: #555555;
$grey3: #efefef;
$grey4: #444444;
$grey5: #888888;
$grey6: #6f6f6f;
$sidebar-border: #f5f2f2;
$sidebar-color: #938d8c;
$modal: #ffba00;
$bg-color: #d0edff;
$bg-color1: #f1e7e6;
$bg-color2: #bfbfbf;
$light-grey: #d0edff;
$pink: #f1e7e6;
$blue: #bfbfbf;
$icon: #6f6f6f;
$green: #96cb57;
$orange: #FF9966;
$red: rgb(231, 80, 80);
$blue: #0ebcee;
$blue-light: #64afe4;
// Screen sizes
$xs: 0px;
$sm: 574px;
$md: 767px;
$lg: 991px;
$xl: 1199px;

$primary: $green; 
$secondary: black;