.collection-title {
  padding: .5rem .75rem;
  border-radius: 3px;
  background: linear-gradient(80deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 35%, rgba(255, 255, 255, 0) 96%);
  font-weight: 400;
  font-size: 1.35rem;
  line-height: 1.6rem;
  color: #4a4a4a;
  margin-bottom: 1rem; 
  align-items: center;

  @include max-width($xl) {
    font-size: 1.2rem;
  }

  @include max-width($md) {
    font-size: 1.0rem;
  }

  &>span {
    display: inline !important;
  }

  .info {
    &.success {
      color: darken($green, 20%);
      @include max-width(991px) {
        display: block;
      }

      i {
        margin-right: .5rem;
      }
    }

    &.error {
      color: #4a4a4a;
      
      @include max-width(991px) {
        display: block;
      }

      i {
        margin-right: .5rem;
        color: $red;
      }

    }

    &.helper {
      font-weight: 300;

      @include max-width(991px) {
      }
    }
  }

  .outlined {
    padding-top: .2rem;
    padding-bottom: .2rem;
    
    &.model {
      border-right: none;
      border-left: none;
      font-weight: 600;
      display: flex;
      align-items: center;

      @include max-width($xl) {
        display: inline-block;
      }

      img {
        width: 2.2rem;
      }
    }

    &.location {
      font-weight: 600;
      border: none;
      border-bottom: 2px solid rgb(209, 209, 209);

      @include max-width($xl) {
        border-width: 2px;
        display: block;
      }

      .btn {
        background: none;
        color: rgba(231, 80, 80, 0.6);
        padding: 0;
        // font-size: .9rem;
        font-weight: 600;

        &:hover {
          color: rgba($red, 1);
        }
      }
    }
  }
}

.collection-title-outer {
  // display: flex;
  // justify-content: space-between;
  
  .how-do-we-compare {
    background-color: #fff;
    overflow: hidden;
    padding: .5rem;

    p {
      font-size: 1.1rem;
      color: black;
      white-space: nowrap;
      margin: 0;

      span {
        &.focus {
          font-weight: 600;
        }

        &.item {
          margin-left: .4rem;
          align-items: center;
          
          .sign {
            margin-right: .4rem;
            i {
              color: rgb(211, 211, 211);
            }
          }
        }
      }
    }
  }
}