.category-search {
  &.cover {
    @include max-width($md) {
      background-size: cover !important;
    }

    .inner-wrapper {
      @include max-width($lg) {
        padding: 1.5rem 1rem;
      }

      .container {
        @include max-width($lg) {
          // padding: 1.5rem 1rem;
        }

        @include max-width($md) {
          flex-direction: column;
        }

        .brand {
          @include max-width($md) {
            display: none;
          }
        }

        .text {
          @include max-width($md) {
            border: none;
            padding-left: 0;
          }

          h3 {

            @include max-width($lg) {
              font-size: 1.5rem;
            }

            @include max-width($md) {
              font-size: 1.2rem;
            }
          }

          p {
            @include max-width($md) {
              font-size: .9rem;
            }
          }
        }

        .form {
          @include max-width($md) {
            width: 100%;
          }
        }
      }
    }
  }

  &.horizontal {
    background: none !important;
    padding: 0;

    .inner-wrapper {
      background: none !important;
      padding: 0 !important;
    }

    .form {
      width: 100%;
      border: 3px solid rgb(200, 211, 223) !important;
      background: #fff !important;
      // box-shadow: 0 0 7px -2px rgba(0, 0, 0, 0.1);

      h4 {
        font-size: .9rem;
      }


      .form-items {
        display: flex;
        flex-direction: row;

        @include max-width($md) {
          flex-direction: column;
        }

        .form-item {
          flex: 1;
          margin-right: .5rem;
          margin-bottom: 0;
          transition: .5s all;

          @include max-width($md) {
            margin-bottom: .7rem;
            margin-right: 0;
          }

          &:last-child {
            margin-right: 0;
            margin-bottom: 0;
          }

          &.disabled {
            // opacity: .7;
            filter: blur(1px);
            position: static;
          }

          &.last-index-available {
            flex: 1.1;
          }

          .btn {
            height: 38px;
          }
        }
      }
    }
  }

  .inner-wrapper {
    background: linear-gradient(270deg, rgba(255,255,255,0) 0%, rgb(31, 50, 80) 100%);
    padding: 3rem 1rem;

    .container {
      display: flex;
      align-items: center;

      &>div {
        
      }
    }

    .brand {
      margin-right: 1rem;

      img {
        // transform: rotate(-90deg);
        width: 40px;
        opacity: .5;
      }
    }

    .text {
      border-left: 1px solid rgba(255,255,255,.1);
      margin-right: 1rem;
      padding-left: 1rem;
      flex: 1.8;

      h3 {
        margin-bottom: 1.5rem;
        font-size: 2.1rem;
        color: #e9eff7;
        font-weight: 600;

        span {
          border-bottom: 3px solid $green;
        }
      }

      p {
        font-size: 1rem;
        color: rgb(207, 207, 207);
        line-height: 1.3rem;
      }
    }

    .form {
      flex: 1;
      background-color: rgba(255,255,255,.4);
      border: 3px solid rgba(255,255,255,.2);
      border-radius: 3px;
      height: 100%;
      padding: 1rem;

      .css-yk16xz-control {
        border-radius: 3px;
      }

      h4 {
        text-transform: uppercase;
        font-weight: 600;
        color: #213c64;
        font-size: 1rem;
        margin-bottom: 1rem;
      }

      .form-item {
        margin-bottom: .7rem;
        position: relative;
        
        &:last-child {
          margin-bottom: 0;
        }

        &.disabled {
          // opacity: .7;
          filter: blur(1px);
        }

        &.last-index-available {
          .css-1wa3eu0-placeholder {
            color: darken($blue, 15%);
            font-weight: 600;
          }

          .css-yk16xz-control {
            border-color: $blue;
          }
        }

        .loading-container {
          position: absolute;
          padding: 2px;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 10;
          display: flex;
          transition: .5s all;

          .inner {
            flex: 1;
            height: 100%;
            background: #fff;
            display: flex;
            align-items: center;
            padding: 1rem;
          }
        }

        .css-1wa3eu0-placeholder {
          color: #000;
        }

        input {
          border: none;
        }

        .btn {
          border-radius: 3px;
          width: 100%;
          background-color: #325182;
          color: white;

          &:hover {
            background-color: #284068;
          }
        }
      }
    }
  }
}

@keyframes animatedGradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}
