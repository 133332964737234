.location-required-overlay {
  background: rgb(255,255,255);
  background: linear-gradient(180deg, #f3f3f3d0 0%, rgba(255,255,255,.8) 100%);
  z-index: 999;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 1rem;
  overflow-y: none;

  .inner {
    background-image: url('../../static/images/location-preview.jpg') !important;
    background-size: 100% auto !important;
    margin: 2rem auto;

    display: block;
    width: 500px;
    max-width: 90%;
    background-color: rgb(255, 255, 255);
    box-shadow: 0 0 15px 0px #adadad;
    border-radius: 5px;
    padding: 1rem;
    display: flex;

    .icon-container {
      border-right: 1px solid rgb(233, 233, 233);
      margin-right: 1rem;
      padding-right: 1rem;

      @include max-width($md) {
        display: none;
      }
      
      i {
        font-size: 2.5rem;
        color: rgb(211, 107, 9);
      }
    }

    .content-container {
      h3 {
        font-size: 1.3rem;
        color: rgb(211, 107, 9);
        font-weight: 600;
      }

      p {
        font-size: 1rem;
        line-height: 1.4rem;
        color: #000;
      }
    }
  }
}