.input-container-wrapper {
  .alert {
    display: block;
    margin-top: .5rem;
    padding: .2rem .5rem;
    border: none;
    border-radius: .25rem;
    margin-top: .5rem;
    margin-bottom: .5rem;
    border-radius: 0 !important;
    background: linear-gradient(
    180deg, rgba(255,255,255,0) 0%, rgb(176 0 0 / 16%) 100%);
  }
}

.srv-validation-message {
  display: block;
  margin-top: .5rem;
  background-color: #ffedef;
  padding: .2rem .5rem;
  border-color: #f5c6cb;
  border: none;
  border-radius: .25rem;
  margin-top: .5rem;
  margin-bottom: .5rem;
  border-bottom: 2px solid #ce868e;
  border-radius: 0 !important;

  @include max-width($sm) {
    margin-top: -.5rem;
  }
}