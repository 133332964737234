.account-login-register {
  //background-color: red;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;

  .container {
    display: flex;
    justify-content: center;
    align-self: start;
  }

  h2 {
    text-transform: none;
    font-weight: 600;
    font-size: 1.4rem;
    margin-top: 0;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    text-align: center;
    border-bottom: 2px solid #F3F3F3;
    text-transform: uppercase;
  }

  .btn {
    background-color: $green;
    color: white;
    width: 100%;

    @include themify($themes) {
      background-color: themed('buttonBackgroundColor');
      color: themed('buttonColor');
    }

    min-width: 150px;
    border-radius: 3px;
    text-transform: none;

    &.secondary {
      background-color: #444444;
      color: white;

      &:hover {
        background-color: darken(#444444, 10%);
        color: white !important;
      }
    }

    &:hover {
      background-color: darken($green, 10%);
      color: white;
      
      @include themify($themes) {
        background-color: darken(themed('buttonBackgroundColor'), 10%);
        color: themed('buttonColor');
      }
    }
  }

  .forgot-password {
    font-weight: 600;
    color: rgb(110, 110, 110);
    display: block;
    margin-top: 1rem;

    &:hover {
      color: black;
    }
  }   

  .login-container {
    background-color: #fff;
    padding: 1rem;
    border-radius: 3px;
    box-shadow: 0 0 7px -2px rgba(0, 0, 0, 0.2);
    width: 450px;
    max-width: 100%;

    .new-customer, .login {
      background-color: #F3F3F3;
      padding: 1rem;
      margin-top: 1rem;
      
      p {
        line-height: 1.4rem;
      }

      .btn {
        width: auto;
      }
    }

    .login {
      .btn {
        width: auto;
      }
    }

    &.on-register {
      @include max-width($md) {
        margin-top: 1rem;
      }
    }

    .login-form {
      label {
        font-weight: 400;
        color: black;
        display: none;
      }

      input {
        border-radius: 3px;
      }
    }

    .signed-in {
      p {
        font-size: 1.1rem;
      }

      .btn {
        background: rgba(0,0,0,.1);
        color: black;

        &:hover {
          background: rgba(0,0,0,.15);
        }
      }
    }
  }

  .register-container {
    padding: 1rem;
    //border: 1px solid rgb(139, 139, 139);
    box-shadow: 0 0 7px -2px rgba(0, 0, 0, 0.2);
    height: 100%;
    display: flex;
    transition: .2s;
    // align-items: center;

    @include max-width($md) {
      margin-top: 1rem;
    }

    &:hover {
      background: #fff;
    }

    p {
      font-size: 1rem;
      color: rgb(34, 34, 34);
      line-height: 1.4rem;
    }

    .btn {
      width: 200px;
      background-color: #444444 !important;

      &:hover {
        background-color: darken(#444444, 10%) !important;
      }
    }
  }
}