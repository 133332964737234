.input-variables {
  padding: .75rem;
  border-radius: 3px;
  border: 3px solid #e6f8fd;
  margin-top: 1rem;
  
  @include max-width($md) {
    padding: 0;
    border: 0;
  }

  .variable-element {
    &:last-child {
      margin-bottom: 0;
    }
  }

  .header {
    margin-bottom: 1rem;
    padding-bottom: 0  !important;
    margin-bottom: 0;
    margin-top: 0;
    border: none;

    @include max-width(767px) {
      margin-top: 0;
      margin-bottom: 0;
      padding-bottom: 0;
    }

    h3 {
      font-size: .9rem !important;
      color: rgb(109, 109, 109) !important;
      margin-bottom: 0 !important;
      font-weight: 400 !important;
    }
  }

 
  input {
    width: 100%;
    font-size: 1.05rem;
    padding: 0.375rem 0.75rem;
    border-radius: 4px;
  }

  .body {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    &.mobile-extended {
      @include max-width($md) {
        flex-direction: column;
        width: 100%;
      }
    }

    .row-items {
      @include max-width($md) {
        margin-top: .5rem !important;
      }
    }
    
    .input-element {
      flex: 1;
      min-width: 49%;
      margin-bottom: .5rem;;
      position: relative;

      .inline-fitment-status {
        font-weight: 600;

        .success {
          color: darken($green, 10%);
        }

        .error {
          color: rgb(235, 52, 52);;
          display: none;

          button {
            background-color: darken(rgb(235, 52, 52), 10%);
            color: #fff;
          }
        }

        button {
          background: rgb(207, 207, 207);
          padding: 0;
          border: none;
          font-weight: 600;
          text-transform: uppercase;
          border-radius: 100px;
          padding: 0 .3rem;
        }
      }

      &.color {
        .form-item {
          margin-top: 1rem;
        }
      }

      .css-yk16xz-control {
        height: 45px;
      }

      input {
        margin-bottom: 0 !important;
      }

      @include max-width($md) {
        min-width: 100%;
      }

      &:nth-child(even) {
        margin-left: 2%;

        @include max-width($md) {
          margin-left: 0;
        }
      }
    }

    .react-datepicker {
      border-radius: 0;;
      background-color: #fff;
      color: #000;
      border: none;
      box-shadow: 0px 0 2px #447bbb;

      .react-datepicker__header {
        background-color: #fff;
      }

      .react-datepicker__header {
        border-bottom-color: #fff;
      }
    }

    .option-selection {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      
      .btn {
        background: white;
        display: flex;
        width: 100%;
        justify-content: space-between;
        padding: .3rem .7rem;
        margin-bottom: .7rem;
        border-radius: 3px;
        font-size: .8rem;
        font-weight: 400;
        background-color: rgba(0,0,0,.05);
        align-items: center;
        margin-right: .75rem;
        transition: 0;

        &:last-child {
          margin-right: 0;
        }
  
        &:hover {
          color: #000;
        }
  
        &.active {
          font-weight: 600;
          color: darken($green, 15%);
          background-color: rgba($green, .2);
          border-color: $green;
    
          .status-container {
            i {
              &.checked {
                display: block;
              }
      
              &.unchecked {
                display: none;
              }
            }
          }
        }

        &:hover {
          background-color: rgba($green, .2);
          border-color: $green;
        }
    
        .status-container {
          margin-right: .7rem;
    
          i {
            font-size: 1.2rem;
    
            &.checked {
              display: none;
            }
    
            &.unchecked {
              display: block;
              color: rgb(134, 134, 134);
            }
          }
        }
    
        .text-container {
          flex: 1;
          text-align: left;
        }
      }
    }
    
    .react-datepicker-wrapper {
      width: 100%;

      input {
        border: 1px solid #ced4da;

        &:focus{
          border: 1px solid #ced4da;
          color: #495057;
          background-color: #fff;
          border-color: #80bdff;
          outline: 0;
          box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
        }
      }
    }

    .css-1wa3eu0-placeholder {
      font-size: 1.05rem;
    }
  }
}

.react-datepicker__time-container   {
  width: 100px;

  .react-datepicker__time-box {
    width: 100px !important;
  }
}