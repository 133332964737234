#nprogress {
  .bar-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 150px;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, white 80%);
    z-index: 10;
  }

  .spinner-container {
    width: 100%;
    display: flex;
  }

  .spinner {
    display: flex; 
    align-items: center;
    // background: rgb(181,200,158);
    // background: linear-gradient(93deg, rgba(181,200,158,0) 0%, rgba(255,255,255,1) 100%);
    padding: 1rem 1rem 1rem 5rem;
    right: 0;
    position: static;
    margin: 0 auto;

    @include max-width($lg) {
      padding: 0.5rem;
      width: 100%;
      justify-content: center;
    }

    span {
      font-size: 1.1rem;
      text-transform: uppercase;
      font-weight: 600;
      color: #878885;
    }

    .spinner-icon {
      width: 30px;
      height: 30px;
      border-width: 3px;
      margin-right: 1rem;
      animation: nprogress-spinner 800ms linear infinite;
    }
  }
}