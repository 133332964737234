.starting-time {
  margin-top: 1.5rem;
  margin-bottom: 3rem;
  padding: 0 1rem;

  .inner-container {
    border-radius: 3px;
    box-shadow: 0 0 7px -2px rgba(0, 0, 0, 0.2);
    background-color: #fff;
    width: 400px;
    max-width: 100%;
    margin: 0 auto;
    padding: 1rem;
    position: relative;

    .overlay {
      z-index: 10;;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(#fff, .5);
      flex-direction: column;
      padding: 1rem;

      h5 {
        font-size: 1.2rem;

        i {
          color: $green;
        }
      }

      p {
        color: black;
        font-size: 1rem;
        text-align: center;
        line-height: 1.2rem;

        span {
          font-weight: 600;
        }
      }
    }
  }

  .inner {

    &.has-overlay {
      filter: blur(5px);
    }

    .title {
      display: flex;
      margin-bottom: .5rem;
      padding-bottom: .5rem;
      align-items: center;
      border-bottom: 1px solid rgb(238, 238, 238);

      i {
        font-size: 1.9rem;
        margin-right: 1rem;
        color: grey;
      }
    }

    h3 {
      margin-bottom: 0;
      font-size: 1.2rem;
      color: #098fb4;
      font-weight: 600;

      span {
        font-weight: 400;
        color: grey;
        font-size: 1.0rem;
      }
    }

    .btn {
      height: 35px;;
      width: 100%;
      background-color: #0ebcee;
      color: white;
      border-radius: 3px;
      margin-top: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 1.5rem;
        height: 1.5rem;
      }
    }

    .working-time-selection {
      display: flex;
      align-items: center;
      border-radius: 3px;
      margin-top: 1rem;

      .icon {
        display: flex;
        align-items: center;
        margin-right: .5rem;
        
        i {
          font-size: 1.1rem;
          color: grey;
          margin-right: .2rem;
        }

        .fa-check-circle {
          color: $green;
        }
      }
    

      &.unavailable {
        &.active {
          h6 {
            color: rgb(151, 39, 39) !important;
            font-weight: 600;
          }

          i {
            color: rgb(151, 39, 39) !important;
          }
        }

        .btn {
          border: 1px solid rgba(0,0,0,0);
          width: 100%;
          display: flex;
          background-color: #fff;
          padding: 0;
          align-items: center;

          h6 {
            font-size: .8rem;
            color: #000;
          }
        }
      }

      &.available {
        &.disabled {
          opacity: .5;
        }
      }

      .elements {
        display: flex;
        flex: 1;

        h6 {
          margin-bottom: 0;
        }
      }

      .item {
        flex: 1;

        span {
          text-transform: uppercase;
        }

        &:first-child {
          margin-right: .5rem;
        }

        &:last-child {
          margin-left: .5rem;
        }
      }
    }

    .reminder {
      margin-bottom: 0;
      text-align: center;
      margin-top: 1rem;
    }
  }
}


.nextAvailabilityRowContainer{
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 10px;
}

.nextAvailabilityCustomInput{
  background-color: inherit;
  border:  1px solid rgb(204, 204, 204);
  border-radius: 4px;
  padding : 5px;
  white-space: nowrap;
}




#nextAvailabilityContainer {
  display: flex;
  flex-flow: column;
}

.nextAvailabilityRowContainerTimes {
  display: flex;
  flex-flow: column;
  align-items: center;
}

.nextAvailableTimeContainer{
  display:  flex;
  justify-content: space-between;
  width: 80%;
  align-items: center;
  margin-bottom: 10px;
}

// .wireFrameBorderOne{
//   border : 1px solid red
// }

// .wireFrameBorderTwo{
//   border : 1px solid green
// }

// .wireFrameBorderThree{
//   border : 1px solid blue
// }

