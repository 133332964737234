.order-success {
  padding-top: 2rem;
  padding-bottom: 1rem;
  overflow: hidden;

  .print-btn-wrapper {
    @include max-width($md) {
      display: none;
    }
  }

  // .print-btn-container {
  //   margin-bottom: .7rem;
  //   border-bottom: 2px solid rgb(219, 219, 219);
  //   display: flex;
  //   justify-content: flex-end;

  //   .print-btn {
  //     background-color: #dbdbdb;
  //     padding: 5px 10px 3px 10px;
  //     font-size: .8rem;
  //     color: #383838;
  //     text-transform: none;
  //   }
  // }

  .intro {
    text-align: left;
    padding: 1rem;
    display: flex;
    border-radius: 3px;
    border: 2px solid rgba(46, 46, 46, 0.5);

    &.payment-missing {
      border: 2px solid rgba(203, 163, 88, 0.9);
      
      i {
        color: rgb(203, 163, 88);
      }
    }

    &.success {
      border: 2px solid #7db73863;

      i {
        color: rgba(83, 163, 63, 0.5) !important;;
      }
    }

    &.cancelled {
      border: 2px solid rgba(203, 163, 88, 0.8);

      i {
        color: rgba(203, 163, 88, 0.5);
      }
    }

    i {
      font-size: 2.5rem;
      margin-right: 1rem;
      color: rgb(53, 53, 53);
    }

    .btn {
      border-radius: 100px;
      font-size: .8rem;

      @include max-width($md) {
        margin: 0.5rem 0;
      }

      i {
        font-size: 1rem;
        margin-right: .5rem;
      }

      &.add-payment {
        background-color: $orange;
        color: white; 

        i {
          color: #ffffff;
        }

        &:hover {
          background-color: darken($orange, 10%);
          border-color: $orange;
        }
      }
    }

    .call-us {
      border-bottom: 2px solid rgb(212, 212, 212);
      color: black;

      &:hover {
        border-bottom: 2px solid rgb(170, 170, 170);
      }
    }

    .text-container {
      h1 {
        text-transform: none;
        font-size: 1.6rem;
        font-weight: 400;
        margin-top: 0;

        @include max-width($md) {
          font-size: 1.2rem;
        }
      }
  
      p {
        font-size: 1.1rem;
        line-height: 1.5rem;
        margin-bottom: 0;
        color: rgb(29, 29, 29);
  
        code {
          color: black;
          border-bottom: 2px solid rgb(223, 223, 223);
        }
      }
    }

    ul {
      margin-top: .5rem;
      padding: 0;

      li {
        display: block; 
        margin-top: .15rem;
      }
    }
  }

  .order-results-container {
    .section-title {
      font-weight: 400;
      text-transform: none;
      font-size: 1.3rem;
      margin-bottom: .8rem;
    }

    .section {
      margin-bottom: 1.5rem;

      @include max-width($md) {
        margin-bottom: 0;
        margin-top: 1.5rem;
      }
    
      .inner {
        background: rgba(255, 255, 255, 0.6);
        border-radius: 5px;
        padding: .7rem;
        box-shadow: 0 0 15px 3px #e5e5e5;
        
        &.cards {
          display: flex;
          justify-content: center;
        }

        &.contains-warranty {
          display: flex;
          justify-content: space-between;

          #warranty {
            margin-left: 1rem;
          }
        }

        .rccs {
          margin: 0;
          margin-right: 1rem;
        }
      }

      .total-price {
        padding: .6rem 0 0 0;
        display: flex;
        flex-direction: row;
        text-align: right;
        justify-content: flex-end;

        table {
          tr {
            td {
              font-size: 1rem;
              margin-right: 1rem;
              padding-bottom: .7rem;
              padding-top: .7rem;
              border-bottom: 1px dashed rgb(219, 219, 219);

              &:first-child {
                padding-right: 1rem;
              }
            }

            &:last-child {
              td {
                font-weight: 600;
                padding-bottom: 0;
                border-color: black;
              }
            }
          }
        }

        ul {
          margin-bottom: .5rem;

          &.final-price {
            li {
              font-weight: 600;
            }
          }

          &:last-child {
            margin-bottom: 0;
          }
          
          li {
            font-size: 1.1rem;

            &:first-child {
              margin-right: 1rem;
            }
          }  
        }

        p {
          font-size: 1.4rem;
          // font-weight: 600;
          width: 100%;
        }
      }

      &.products {
        .item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 1px solid rgb(221, 221, 221);
          padding-bottom: .5rem;
          padding-top: .5rem;

          .column {
            display: flex;
            align-items: center;

            .img-container {
              margin-right: 1rem;
            }
            
            &:last-child {
              justify-content: space-between;
            }
          }

          .btn {
            &.get-warranty {
              background: none;
              text-transform: none;
              padding: .1rem .5rem;
              margin: 0;
              font-size: .8rem;
              font-weight: 400;
              background-color: rgb(240, 240, 240);
              margin-top: .5rem;
              border-radius: 3px;
              color: rgb(54, 54, 54);

              &:hover {
                background-color: rgb(197, 197, 197);
              }

              i {
                // color: rgb(167, 110, 4);
              }
            }
          }

          &>div {
            flex: 1;
          }

          &:first-child {
            padding-top: 0;
          }

          &:last-child {
            border-bottom: none;
            padding-bottom: 0;
          }

          .title {
            p {
              font-weight: 600;

              small {
                color: grey;
                font-size: .7rem;
                line-height: .7rem;
                display: block;
              }
            }
          }

          .price {
            text-align: right;  
          }


          img {
            width: 40px;
          }

          p {
            font-size: 1.1rem;
            margin-bottom: 0;
            color: rgb(59, 59, 59);
          }
        }
      }

      &.list {
        table {
          width: 100%;

          tr {
            td {
              font-size: .9rem;
              margin-right: 1rem;
              padding-bottom: .7rem;
              padding-top: .7rem;
              border-bottom: 1px solid rgb(230, 230, 230);
    
              &:first-child {
                padding-right: 1rem;  
              }
            }

            &:first-child {
              td {
                padding-top: 0;
              }
            }

            &:last-child {
              td {
                padding-bottom: 0;
                border-bottom: none;
              }
            }
          }
        }
      }

      &.addresses {
        // display: flex;


        .inner-items-container {
          display: flex;
          flex-direction: row;

          @include max-width($md) {
            flex-direction: column;
          }
        }

        .inner {
          flex: 1;
          margin-right: 1rem;

          @include max-width($md) {
            margin-right: 0;
            margin-bottom: 1rem;
          }

          h3 {
            font-weight: 600;
            text-transform: none;
            font-size: 1rem;
            margin-bottom: .8rem;
            color: #222222;
          }

          &:last-child {
            margin-right: 0;
          }

          ul {
            list-style-type: none;
            padding: 0;

            li {
              display: block;
            }
          }
        }

        .buttons {
          margin-top: .5rem;

          .btn {
            font-weight: 400;
            text-transform: none;
            background-color: rgba(0,0,0,.05);
            border-radius: 100px;
            margin-right: .5rem;
            padding: .2rem 1.3rem;

            &:hover {
              background-color: rgba(0,0,0,.1);
            }
          }
        }
      }

      &.payment {
        table {
          width: 100%;

          tr {
            td {
              font-size: .9rem;
            }
          }
        }
      }
    }
  }

  .alert {
    max-width: 100% !important;
  }
}

.styles_modal__gNwvD {
  border-radius: 3px;   
}

.update-address-form {
  h6 {
    font-size: 1.2rem;
    color: rgb(43, 43, 43);
    padding-bottom: .7rem;
    margin-bottom: 1rem;
    border-bottom: 1px solid rgb(226, 226, 226);
  }

  label {
    margin-bottom: .3rem;
    text-transform: uppercase;
    font-weight: 600;
    font-size: .8rem;
    color: rgb(46, 46, 46);
  }

  input, select {
    margin-bottom: 1rem;
    border-radius: 3px !important;
  }

  .buttons-container {
    display: flex;
    justify-content: space-between;
  }

  .btn {
    text-transform: none;
    border-radius: 3px;
    border: none;
    margin-top: .5rem;

    &.btn-success {
      background-color: $green;
      color: white;

      &:hover {
        background-color: darken($green, 10%);
      }
    }
  }
}

.order-edit {
  background-color: red;
}

.timeline-modal {
  .react-responsive-modal-modal {
    width: 400px;
    max-width: 90%;
    padding: 0;

    .modal-header {
      background-color: #efefef;
    }

    .modal-body {
      padding: 1rem;

      .btn-cancel-order {
        display: inline;
        text-align: right;
        width: 100%;
        font-weight: 400;
        font-size: .9rem;
        color: #4e4e4e;
        padding: 0;
      }

      .cancelation-form {
        padding: 1rem;
        border-radius: .5rem;
        background-color: rgb(240, 240, 240);
      }

      .actions {
        justify-content: space-between;
        .btn {
          font-weight: 400;
          font-size: .9rem;

          &.cancel-submit {
            background-color: #dc2626;
            color: #fff;
          }
        }
      }
    }
  }
}