@mixin gradient-horizontal($start-color: #555, $end-color: #333, $start-percent: 0%, $end-percent: 100%) {
	background-image: -webkit-linear-gradient(left, $start-color $start-percent, $end-color $end-percent); // Safari 5.1-6, Chrome 10+
	background-image: -o-linear-gradient(left, $start-color $start-percent, $end-color $end-percent); // Opera 12
	background-image: linear-gradient(to right, $start-color $start-percent, $end-color $end-percent); // Standard, IE10, Firefox 16+, Opera 12.10+, Safari 7+, Chrome 26+
	background-repeat: repeat-x;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{ie-hex-str($start-color)}', endColorstr='#{ie-hex-str($end-color)}', GradientType=1); // IE9 and down
}

// Vertical gradient, from top to bottom
//
// Creates two color stops, start and end, by specifying a color and position for each color stop.
// Color stops are not available in IE9 and below.
@mixin gradient-vertical($start-color: #555, $end-color: #333, $start-percent: 0%, $end-percent: 100%) {
	background-image: -webkit-linear-gradient(top, $start-color $start-percent, $end-color $end-percent); // Safari 5.1-6, Chrome 10+
	background-image: -o-linear-gradient(top, $start-color $start-percent, $end-color $end-percent); // Opera 12
	background-image: linear-gradient(to bottom, $start-color $start-percent, $end-color $end-percent); // Standard, IE10, Firefox 16+, Opera 12.10+, Safari 7+, Chrome 26+
	background-repeat: repeat-x;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{ie-hex-str($start-color)}', endColorstr='#{ie-hex-str($end-color)}', GradientType=0); // IE9 and down
}

@mixin gradient-directional($start-color: #555, $end-color: #333, $deg: 45deg) {
	background-repeat: repeat-x;
	background-image: -webkit-linear-gradient($deg, $start-color, $end-color); // Safari 5.1-6, Chrome 10+
	background-image: -o-linear-gradient($deg, $start-color, $end-color); // Opera 12
	background-image: linear-gradient($deg, $start-color, $end-color); // Standard, IE10, Firefox 16+, Opera 12.10+, Safari 7+, Chrome 26+
}

@mixin gradient-horizontal-three-colors($start-color: #00b3ee, $mid-color: #7a43b6, $color-stop: 50%, $end-color: #c3325f) {
	background-image: -webkit-linear-gradient(left, $start-color, $mid-color $color-stop, $end-color);
	background-image: -o-linear-gradient(left, $start-color, $mid-color $color-stop, $end-color);
	background-image: linear-gradient(to right, $start-color, $mid-color $color-stop, $end-color);
	background-repeat: no-repeat;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{ie-hex-str($start-color)}', endColorstr='#{ie-hex-str($end-color)}', GradientType=1); // IE9 and down, gets no color-stop at all for proper fallback
}

@mixin gradient-vertical-three-colors($start-color: #00b3ee, $mid-color: #7a43b6, $color-stop: 50%, $end-color: #c3325f) {
	background-image: -webkit-linear-gradient($start-color, $mid-color $color-stop, $end-color);
	background-image: -o-linear-gradient($start-color, $mid-color $color-stop, $end-color);
	background-image: linear-gradient($start-color, $mid-color $color-stop, $end-color);
	background-repeat: no-repeat;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{ie-hex-str($start-color)}', endColorstr='#{ie-hex-str($end-color)}', GradientType=0); // IE9 and down, gets no color-stop at all for proper fallback
}

@mixin gradient-radial($inner-color: #555, $outer-color: #333) {
	background-image: -webkit-radial-gradient(circle, $inner-color, $outer-color);
	background-image: radial-gradient(circle, $inner-color, $outer-color);
	background-repeat: no-repeat;
}

@mixin gradient-striped($color: rgba(255,255,255,.15), $angle: 45deg) {
	background-image: -webkit-linear-gradient($angle, $color 25%, transparent 25%, transparent 50%, $color 50%, $color 75%, transparent 75%, transparent);
	background-image: -o-linear-gradient($angle, $color 25%, transparent 25%, transparent 50%, $color 50%, $color 75%, transparent 75%, transparent);
	background-image: linear-gradient($angle, $color 25%, transparent 25%, transparent 50%, $color 50%, $color 75%, transparent 75%, transparent);
}

@mixin max-width($point) {
	@media (max-width: $point) { @content ; }
}

@mixin min-width($point) {
	@media (min-width: $point) { @content ; }
}

@mixin placeholder {
	&::-webkit-input-placeholder {@content}
	&:-moz-placeholder           {@content}
	&::-moz-placeholder          {@content}
	&:-ms-input-placeholder      {@content}
}

.landing, .product {
  &.city {
    &.alt {
      .cover {
        padding-top: 5rem;

        @include max-width($md) {
          padding-top: 2rem;
        }
      }
    }

    .cover-container-inner {
      background: url('../../static/images/landing/cover-merged.jpg') center right;
      background-size: cover;

      @include max-width($md) {
        background: url('../../static/images/landing/cover-mobile-2.jpg') center center;
        background-size: cover !important;
      }
    }

    .cover {
      .bottom {
        background-color: #232323;
        border-bottom: 2px solid #383838;
      }

      &.has-products {
        padding-bottom: 9rem;
      }
    }

    .product-collection {
      background-color: #1E1E1E;
      padding-bottom: 2rem;

      .collection-content {
        background-color: #F3F3F3;
        padding-top: 15px;
        border-radius: 5px;
        margin-top: -7rem;
      }
    }

    .search-form {
      margin-bottom: 0;

      .checklist {
        p {
          font-size: .9rem;
          margin-bottom: 0;
          color: white;
          line-height: 1.2rem;
        }
      }
    }

    .call-us {
      text-align: center;
      margin: 1rem 0 3rem 0;

      a {
        color: white;
        span {
          font-weight: 600;
          border-bottom: 2px solid rgba(255,255,255,.3);
        }

        &:hover {
          span {
            border-color: #fff;
          }
        }
      }
    }

    .affirm-disclaimer-border {
      width: 400px;
      max-width: 100%;
      background: radial-gradient(circle,#0F9DE6 0,rgba(0,0,0,0) 100%);
      height: 1px;
      margin: 0 auto;
    }
    
    .affirm-disclaimer-container {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      background: radial-gradient(circle,#232323 0,rgba(0,0,0,0) 100%);
      padding: 1rem;
      width: 400px;
      max-width: 100%;
      margin: 0 auto;

      &:hover {
        background: radial-gradient(circle,#080808 0,rgba(0,0,0,0) 100%);
      }

      img {
        height: 20px;
      }

      .affirm-disclaimer {
        color: #ffffff;
        margin-bottom: 0 !important;
        border-right: 1px solid #585858;
        margin-right: .7rem;
        padding-right: .7rem;
      }
    }

    .where-to-find {
      text-align: left;
      margin-bottom: 0;
      margin-top: 0 !important;

      &.light {
        a {
          color: rgb(0, 0, 0);

          &:hover {
            border-color: black;
          }
        }
      }

      a {
        color: rgb(44, 44, 44);
        padding: .5rem;
        font-size: 1rem;
        background-color: red;
        border-radius: 3px;

        &:hover {
          border-color: rgb(0, 0, 0);
          text-decoration: underline;
        }

        span {
          font-weight: 600;
        }
      }
    }
  }

  &.hybrid-electric, &.alt {
    .cover {
      padding: 8rem 0 0 0;
      margin: 0 auto;
      background: rgb(255,255,255);
      background: radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 35%, rgba(222,222,222,0) 100%);
      max-width: 100%;

      @include max-width($md) {
        padding-top: 6rem;
      }

      .item-element {
        display: flex;
        align-items: flex-end;

        @include max-width($md) {
          flex-direction: column;
        }

        .text-container {
          flex: 1;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          padding-bottom: 1.5rem;

          h1 {
            font-size: 2.5rem;

            @include max-width($md) {
              font-size: 1.5rem;
              line-height: 2.0rem;
              margin-bottom: 0;
              text-align: left;
            }

            span {
              font-size: inherit;
            }
          }

          p {
            color: #000;
          }

          .search-form {
            margin-bottom: 0;

            @include max-width($md) {
              margin-top: 1rem;
            }    
          }
        }

        .image-container {
          overflow: hidden;
          position: relative;
         
          img {
            width: 500px;
            max-width: 100%;
            margin-bottom: 0;
          }

          @keyframes disclaimers {
            from {
              opacity: 0;
            }
            to {
              opacity: 1
            }
          }

          .disclaimers {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            animation-name: disclaimers;
            animation-fill-mode: forwards;
            animation-duration: 1s;
            animation-delay: 1s;
            opacity: 0;

            display: flex;
            justify-content: space-between;

            .disclaimer {
              flex: 1;
              display: flex;
              text-align: center;
              justify-content: center;
              align-items: center;

              .inner {
                display: flex;;
                text-align: center;
                justify-content: center;
                align-items: center;
                background-color: rgba(255, 255, 255,1);
                padding: .5rem 1rem;
                border-top-left-radius: 3px;
                border-top-right-radius: 3px;

                i {
                  color: $green;
                  font-size: 1.1rem;
                }
  
                h6 {
                  margin-bottom: 0;
                  font-size: 1rem;
                  color: #000;
                  text-align: left;
                  line-height: 1.2rem;
                  border-left: 1px solid rgb(223, 223, 223);
                  margin-left: .5rem;
                  padding-left: .5rem;
                }
              }
            }
          }
        }
      }
    }

    .bottom-container {
      background-repeat: no-repeat !important;
      background-size: cover !important;
    }

    .bottom {
      flex-direction: column-reverse;
      padding: 3rem 0;
      background: radial-gradient(circle, white 0%, #F9F9FB 100%, 100%);

      @include max-width($md) {
        padding: 0;
      }

      &>p {
        margin-bottom: 0;
        font-size: 2rem;
        text-align: center;
        line-height: 2.8rem;
        margin-bottom: 3rem;
        color: #000;

        @include max-width(991px) {
          font-size: 1.5rem;
          line-height: 2rem;
        }
      }

      .items-container {
        display: flex;
        border: none;
        padding-right: 0;

        @include max-width(991px) {
          flex-direction: column;
        }

        .item {
          flex: 1;

          @include max-width(991px) {
            margin-bottom: .7rem;
          }
        }
      }
    }
  }

  &.on-homepage {
    .container-alt {
      width: 900px;
      max-width: 100%;
    }

    .cover-container-inner {
      background-size: auto 110% !important;

      @include max-width(767px) {
        background-size: cover !important;
      }
    }

    .cover {
      padding: 4rem 0;

      .items-element {
        flex-direction: column !important;

        .item-element {
          text-align: center;

          .text-container {            
            @include max-width(767px) {
              // background: radial-gradient(circle, rgba(255, 255, 255, 0.781) 0%, rgba(255, 255, 255, 0.712) 0%, rgba(255,255,255,1) 28%, rgba(255,255,255,1) 51%, rgba(255,255,255,0) 100%);
              padding: 1rem 0 1rem 0;
            }

            h1 {
              font-size: 3rem;

              @include max-width(767px) {
                font-size: 2rem;
              }

              span {
                font-size: inherit;
              }
            }
          }

          .landing-checklist {
            flex-wrap: wrap;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            margin-top: 1.5rem;

            .item {
              min-width: 300px;
              padding: .3rem;
              margin-top: .5rem;
            }
          }
        }
      }
    }
    
    .action-buttons {
      .inner {
        justify-content: center;

        .btn {
          border: 2px solid rgba($green, .1);
  
          &.book {
            background-color: $green;
            color: white;
          }
  
          &.text-us {
            background: none;
            color: black;
  
            @include max-width($lg) {
              background-color: rgba(255,255,255,.5);
            }
          }
  
          &:hover {
            border-color: darken($green, 10%);
          }
        }
      }
    }
  }

  .action-buttons {
    max-width: 100%;
    margin: 0 auto;
    // background-color: #fff;
    // border-radius: 5px;
    // border: 3px solid #d4d4d4;
    display: flex;
    flex-direction: column;

    &.sidebar {
      .inner {
        flex-direction: column;
        margin-bottom: 1rem;

        @include max-width($sm) {
          flex-direction: row;

        }

        .text-us {
          margin-left: 0 !important;
          margin-top: .5rem;

          @include max-width($sm) {
            margin-top: 0;
            margin-left: .5rem !important;
          }
        }
      }
    }

    .inner {
      width: 100%;
      display: flex;

      p {
        margin: 0 1rem;

        @include max-width($sm) {
          display: none;
        }
      }

      .btn {
        border-radius: 3px;
        padding: .5rem 1,2rem;
        font-size: .8rem;
        text-transform: uppercase;
        border: 2px solid rgba(#4c942f, .1);

        @include max-width($md) {
          padding: 0.5rem;
        }

        &.book {
          background-color: #4c942f;
          color: white;
          margin-right: .5rem;
        }

        &.text-us {
          background: none;
          color: black;
          width: auto;
          padding-right: .8rem;
          padding-left: .8rem;
          margin-left: .5rem;
          text-transform: none;

          span {
            font-weight: 600;
          }

          @include max-width($lg) {
            margin-left: .1rem;
            background-color: rgba(255,255,255,.5);
          }
        }

        &:hover {
          border-color: #4c942f;;
          cursor: pointer;
        }
      }
    }
  }

  .cover-container {
    background-size: cover !important;

    @include max-width($sm) {
      // background-size: auto 100% !important;
      // background-position: center right;
    }
  }

  .cover-container-inner {
    background-size: auto 100% !important;
    background-repeat: no-repeat !important;
  }

  .cover {
    // background: #fff;
    background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
    padding: 10rem 0 5rem 0;

    @include max-width($md) {
      padding: 6rem 0 1rem 0;
      background-color: rgba(255, 255, 255, 0.5);
    }

    .items-element {
      display: flex;

      @include max-width(991px) {
        flex-direction: column;
      }

      .item-element {
        flex: 1;
      }
    }
  
    .text-container {
      max-width: 100%;
      margin: 0 auto;

  
      h1 {
        color: rgb(0, 0, 0);
        font-size: 2.2rem;
        font-weight: 400;
        margin-bottom: 1.5rem;
        padding-right: 1rem;
        text-transform: none;

        @include max-width($md) {
          font-size: 1.7rem;
        }
  
        span {
          font-weight: 600;
          font-size: 2.2rem;
          color: rgb(0, 0, 0);

          @include max-width($md) {
            font-size: 1.7rem;
          }
        }
      }
  
      p {
        font-size: 1rem;
        display: inline-block;
        color: whitesmoke;
        margin-bottom: 0;
        border-bottom: 2px solid rgba(255,255,255,.1);
        padding-bottom: .5rem;
        margin-bottom: .5rem; 
        margin-top: 1rem;


        i {
          color: #4c942f;;
        }
        
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }  

  .bottom-container {
    background: #ebebf0;
    padding: 2rem 0;
    box-shadow: inset 0px 7px 13px 0px #e0e0f3;
  }

  .bottom {
    border-radius: 5px;
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-start;

    @include max-width($md) {
      flex-direction: column-reverse;
    }

    h3 {
      text-transform: uppercase;
      font-size: 1rem;
      font-weight: 600;
      color: #0d0d0e;
    }

    .tagline-container {
      flex: 1;

      @include max-width($md) {
        border-left: none;
        padding-left: 0;
        margin-top: 1.5rem;
      }


      .tagline {
        font-size: 1.5rem;
        line-height: 2.3rem;
        color: #000;
      }

      .sub {
        font-size: 1rem;
        margin-bottom: 0;
        color: #313131;
        line-height: 1.2rem;
      }
    }

    @include max-width($md) {
      // display: none;
    }

    .items-container {
      flex: 1;
      border-right: 2px solid #dcddf7;
      padding-right: 1rem;
      margin-right: 1rem;

      @include max-width($md) {
        border-right: none;
        padding-right: 0;
      }
    }

    .item {
      padding: .8rem 1rem;
      display: flex;
      height: 100%;
      align-items: center;
      color: white;
      border-radius: 3px;
      background: linear-gradient(90deg, rgba(#fff, 1) 0%, rgba(255,255,255,0) 100%);

      i {
        font-size: 2rem;
        color: rgba(#4c942f, .5);

        @include max-width($md) {
          font-size: 1.2rem;
        }
      }

      .text {
        color: rgb(68, 68, 68);
        padding-left: .7rem;

        h5 {
          font-size: 1rem;
          text-transform: uppercase;
          color: rgb(43, 43, 43);
          margin-bottom: 0 !important;
          font-weight: 600;
        }

        p {
          font-size: .9rem;
          margin-bottom: 0;
          color: rgb(49, 49, 49);
          line-height: 1.2rem;
        }
      }
    }
  }

  .search-form {
    margin: 1.5rem 0 6rem 0;

    @include max-width($lg) {
      margin: 2rem 0 2rem 0;
    }

    &.bottom {
      background-color: #fff;
      box-shadow: 0 0 7px -2px rgba(0, 0, 0, 0.1);
      padding: 2rem 0;
      margin: 1rem 0 0 0;
      border-radius: 4px;

      .css-yk16xz-control {
        border: 1px solid rgb(187, 187, 187);
      }

      @include max-width($md) {
        padding: 1rem;
      }
    }

    .css-yk16xz-control {
      border-radius: 5rem;
      border: none;

      @include max-width($md) {
        border-radius: .5rem;
        margin-bottom: .2rem;
        border-bottom: 1px solid #c1c1c1;
      }

      &:hover {
        background-color: rgb(233, 233, 233);
      }
    }

    .css-1pahdxg-control {
      background-color: rgb(233, 233, 233);
      border-radius: 5rem;
      border: none;

      @include max-width($md) {
        border-radius: .5rem;
        margin-bottom: .2rem;
        border-bottom: 1px solid #c1c1c1;
      }
    }

    .css-1wa3eu0-placeholder {
      color: rgb(44, 44, 44);
      font-weight: 600;
    }
  
    &.promo-banner {
      margin: 0;
  
      .where-to-find {
        margin-bottom: 0;
      }
    }
  
    .check-text {
      margin: 0 auto 1.0rem auto;
  
      ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        @include max-width($md) {
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
        }
  
        li {
          color: #fff;
          text-transform: uppercase;
          font-size: .85rem;
          margin:  0 .5rem;
          padding-left: 20px;
          display: flex;
          align-items: center;
          text-shadow: 2px 2px 3px #000000;

          @include max-width($md) {
            margin: 0;
            padding-left: 0;
            margin-bottom: .5rem;
            align-items: flex-start;

            i {
              margin-top: 3px;
            }

            &:last-child {
              margin-bottom: 0;
            }
          }
  
          i {
            color: $green;
            margin-right: .5rem;
          }
        }
      }
    }
  
    form {
      position: relative;
      background-color: #fff;
      width: 650px;
      margin: 0 auto;
      padding: .3rem .3rem;
      border-radius: 5rem;
      max-width: 100%;

      @include max-width($md) {
        border-radius: .5rem;
        background-color: rgba(#fff, .5);
      }
    }
  
    .form-group {
      margin-bottom: 0;

      @include max-width($md) {
        border-radius: .5rem;
      }
  
      &.invalid-form {
        input {
          border: 1px solid red;
          @include placeholder {
  
          }
        }
      }
    }
  
    input {
      border: none;
      background: none;
      border-radius: 5rem;
    }
  
    .btn {
      width: 100%;
      border-radius: 5rem;
      font-weight: 600;
      height: 100%;
    }
  
    p {
      margin-top: 1rem;
      &.where-to-find {
        text-align: left;

        a {
          color: rgb(44, 44, 44);
        }
      }
    }
  
    .invalid-vin {
      background-color: #e0e0e0;
      width: 650px;
      margin: 0 auto;
      margin-top: -25px;
      padding: 40px 10px 10px 10px;
      border-bottom-right-radius: 5px;
      border-bottom-left-radius: 5px;
      display: flex;
      border-bottom: 2px solid $red;
  
      .text {
        padding-left: 10px;
  
        h5 {
          margin-bottom: 0;
          font-size: 1.1rem;
          color: $red;
        }
  
        p {
          margin-bottom: 0;
          margin-top: .5rem;
          font-size: .9em;
        }
      }
  
      .img {
        img {
          width: 64px;
        }
      }
    }
  }

  .banner-with-sample-report {
    background-color: #79AC45;
    
  
    .inner {
      padding: 3rem 0 8rem 0;

      @include max-width($sm) {
        padding: 2rem 0;
      }
    }
  
    .row {
      align-items: center;
      @include max-width($md) {
        flex-direction: column;
      }
      
     .col {
      @include max-width($md) {
        flex-basis: auto;
      }
     }
    }
  
    .text {
      h3 {
        color: rgb(51, 51, 51);
        font-size: 2.2rem;
        font-weight: 400;
        margin-bottom: 1.5rem;
        padding-right: 1rem;
        text-transform: none;

        @include max-width($md) {
          font-size: 1.5rem;
        }
      }
  
      p {
        color: rgb(32, 32, 32);
        font-size: 1.3em;
        font-weight: 500;
        line-height: 1.6rem;
      }
    }
  
    .image {
      text-align: right;
      justify-content: flex-end;
      position: relative;

      @include max-width($md) {
        text-align: left;
        justify-content: flex-start;
      }

      button {
        background: none;
        outline: none;
        padding: 0;
        margin: 0;
        border: none;
      }
  
      img {
        width: 500px;
      }
  
      .btn {
        padding: 10px;
        background: none;
        border: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
  
        &:hover {
          background: rgba(255,255,255,1);
        }
  
        i {

        }
      }
    }
  }

  .steps {
    .inner {
      margin-top: -6rem;

      @include max-width($md) {
        margin-top: 1rem;
      }

      .row {
        @include max-width($md) {
          flex-direction: column;
        }

        .col {
          @include max-width($md) {
            flex-basis: auto;
          }
        }
      }
    }
    
    .item {
      text-align: center;
      padding: 1rem 1rem;
      background-color: #fff;
      border-radius: 5px;
      box-shadow: 0 0 7px -2px rgba(0,0,0,.1);
      position: relative;
      height: 100%;
      transition: .2s all;
      overflow: hidden;

      .start-here {
        position: absolute;
        width: 300px;
        background: #4c942f;
        transform: rotate(45deg);
        right: -112px;
        top: 27px;
        color: #fff;
        padding-top: 0.3rem;
        padding-bottom: 0.3rem;
      }

      &:hover {
        // background-color: #232324;
        cursor: pointer;
        box-shadow: 0px 0px 13px 0px #c2d7f8;

        h4 {
          color: #4c942f;;
        }
      }

      @include max-width($md) {
        margin-bottom: 1rem;
      }
      
      .col {
        &:last-child {
          @include max-width($md) {
            margin-bottom: 0;
          }
        }
      }
  
      &.no-border {
        border: none;
      }
  
      img {
        margin-bottom: .8rem;
        height: 64px;
        
        &.pointer.left {
          position: absolute;
          bottom: 20px;
          left: -65px;
          width: 90px;
          height: auto;
          z-index: 999;
        }
  
        &.pointer.right {
          position: absolute;
          bottom: 20px;
          right: -65px;
          width: 90px;
          height: auto;
          z-index: 999;
        }
      }
  
      h4 {
        margin-bottom: .5rem;
        text-transform: uppercase;
        font-size: 1.0rem;
        color: #555555;
      }

      h6 {
        font-size: 1.2rem;
        color: #000
      }
  
      p {
        font-size: 1.0rem;
        font-weight: 500;
        margin-bottom: 0;
        color: #231b1f;
        line-height: 1.3rem;
      }
    }
  }

  .join {
    margin: 3rem 0;
    //background-color: #F3F3F3;
    @include max-width($md) {
      margin: 2rem 0;
    }

    h4 {
      font-size: 2.2rem;
      font-weight: 400;

      @include max-width($md) {
        font-size: 1.5rem; 
        line-height: 1.8rem;
      }

      span {
        font-weight: 500;
      }
    }

    p {
      margin-bottom: 0;
      font-size: 1.1rem;
      line-height: 1.5rem;
    }
  }

  .nearby-cities {
    background: linear-gradient(273deg, rgba(233, 236, 239, 0) 0%, white 50%, rgba(255, 255, 255, 0) 96%);
    box-shadow: 0 0 7px -2px rgba(0, 0, 0, 0.1);
    padding: 1rem;

    h3 {
      font-weight: 600;
      font-size: 1rem;
      margin-bottom: 1rem;
      text-transform: uppercase;
      color: #064a5e;
    }

    ul {
      display: flex;
      flex-wrap: wrap;

      li {
        width: 16.6%;
        margin-top: .5rem;

        a {
          color: rgb(59, 59, 59);

          &:hover {
            color: black;
            text-decoration: underline;
          }
        }
      }
    }
  }

  .dynamic-content {
    margin-top: 2rem;
    
    @include max-width($sm) {
      margin-top: 1rem;
    }

    article {
      border: none;
      background: #fff;
      padding: 1rem;
      margin-bottom: 2rem;
      box-shadow: 0 0 7px -2px rgba(0, 0, 0, 0.1);

      @include max-width($sm) {
        margin-bottom: 1rem;
      }
    }

    h2 {
      text-transform: none;
      font-size: 1.6rem;
      margin-bottom: 1rem;
      margin-top: 2rem;
      font-weight: 400;
      color: #363636;
    }

    p {
      line-height: 1.5rem;
      color: rgb(12, 12, 12);
      font-size: 1rem;
    }

    ul {
      margin-bottom: 1rem;

      li {
        font-size: 1rem;
        line-height: 1.6rem;
        margin-bottom: 1rem;
        color: rgb(12, 12, 12);

        strong {
          color: #0e0e0e;
        }

        &:last-child {
          margin-bottom: 0;
        }

        a {
          color: #064A5E;
          font-weight: 600;
          border-bottom: 2px solid #f7f7f7;
        }
      }
    }
  }

  .landing-checklist {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    border-radius: 5px;
    min-height: 100%;

    &.in-sidebar {
      min-height: auto;

      .item {
        padding: .5rem;
        margin-bottom: 0;
        border-radius: 0;
      }
    }

    @include max-width(991px) {
      margin-top: 1rem;
      box-shadow: none;
    }

    h6 {
      font-size: 1rem;
      font-weight: 600;
      color: #000;
    }

    .item {
      padding-left: 1rem;
      flex: 1;
      margin-bottom: .4rem;
      border-radius: 5px;
      display: flex;
      align-items: center;
      padding-left: .5rem;
      justify-content: flex-start;
      background: rgb(235,235,240);
      background: linear-gradient(280deg, rgba(235,235,240,0) 0%, rgba(255,255,255,1) 100%);

      @include max-width(991px) {
        padding: .5rem; 
      }

      &:last-child {
        margin-bottom: 0;
      }

      // &.odd {
      //   justify-content: flex-end;
      // }

      span {
        font-size: .95rem;
        text-align: left;
        color: rgb(48, 48, 48);

        i {
          color: #FF7849;
          font-size: .95rem;
          margin-right: 0;
        }

        img {
          display: inline;
          height: 18px;
          margin-top: -10px;
        }

        @include max-width($md) {
          font-size: .8rem;
        }
      }

      i {
        font-size: 1.3rem;
        color: $green;
        margin-right: .5rem;
      }
    }

    .break {
      width: 100%;
    }
  }

  .reviews.static {
    background: #ebebf0;

    .review-items {
      .review-item {
        blockquote {
          font-size: 1rem;
          font-style: italic;
        }
      }
    }
  }

  .banner-with-sample-report {
    background-color: #ffffff;

    .image {
      &:hover {
        opacity: .8;
        cursor: pointer;
      }
    }

    &.has-products {
      background-color: #333333;
    }
  }
}

.landing-nearby-cities {
  ul {
    display: inline-block;

    li {
      margin-right: 1rem;

      a {
        color: #000;
      }
    }
  }
}

.reviews-sidebar {
  @include max-width($md) {
    display: none;
  }
}

.commercial {
  border-radius: 5px;
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;

  .inner {
    display: block;
    padding: 3rem 1rem;
    background-color: rgba(255, 255, 255, 0.678);
    width: 100%;
    text-align: center;

    @include max-width(767px) {
      padding: 1.5rem .5rem;
    }

    p {
      margin-bottom: 0;
      color: #fff;
    } 

    .tv {
      border: 10px solid rgb(48, 48, 48);
      border-bottom-width: 20px;
      display: inline-block;
      border-radius: 5px;
      position: relative;

      .icon-container {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        span {
          &:hover {
            cursor: pointer;
            color: #fff;

            i {
              color: rgb(41, 41, 41);
            }
          }
        }

        i {
          font-size: 5rem;
          color: rgb(0, 0, 0);
          filter: drop-shadow(0 0 15px rgba(255, 255, 255, 0.9));

          @include max-width(767px) {
            font-size: 2.5rem;
          }
        }
      }

      img {
        max-width: 100%;
        width: 350px;
      }
    }

    p {
      font-size: 1.25rem;
      margin-top: 1rem;
      text-transform: uppercase;
      font-weight: 600;
      color: #000;
    }
  }
}

.cta-wrapper {
  @include max-width(767px) {
    width: 100%;
  }
}