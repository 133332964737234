.faq-elements {
  .header {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);

    @include max-width($md) {
      margin-top: 1rem;
      margin-bottom: 1rem;
      padding-bottom: 1rem;
    }

    h1 {
      margin: 0;
      font-size: 1.9rem;
      color: #5a5a5a;

      @include max-width($md) {
        font-size: 1.4rem;
      }
    }
  }

  .content {
    margin-bottom: 1rem;

    .pairs-container {
      background-color: #fff;
      padding: 1rem;
      border-radius: 3px;
      box-shadow: 0 0 7px -2px rgba(0, 0, 0, 0.1);
      
      .pair {
        margin-bottom: .5rem;
        border-bottom: 1px solid rgb(228, 228, 228);
        padding-bottom: .5rem;

        &:last-child {
          margin-bottom: 0;
          padding-bottom: 0;
          border: none;
        }

        li {
          display: block;

          &:first-child {
            font-weight: 600;
          }
        }
      }
    }
  }

  .additional-questions {
    border: 2px solid rgba($blue, .2);
    border-radius: 3px;
    padding: 1rem;
    text-align: left;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;

    @include max-width($md) {
      margin-top: 1rem;
    }

    i {
      font-size: 3rem;
      color: $blue;
    }
    
    p {
      margin-left: 1rem;
      margin-bottom: 0;
      font-size: 1.2rem;
      color: rgb(58, 58, 58);
      line-height: 1.7rem;
      font-weight: 300;

      span {
        font-weight: 400;
      }
    }
  }
}