@keyframes fadeIn {
  0%   {opacity: 0;}
  100%  {opacity: 1;}
}

.empty-alert {
  border: 2px dashed rgb(252, 200, 105);
  width: 400px;
  max-width: 90%;
  margin: 2rem auto;
  text-align: center;
  padding: 1.5rem;
  border-radius: 3px;


  &.empty-results {
    opacity: 0;
    animation-name: fadeIn;
    animation-duration: 1s;
    animation-delay: 1s;
    animation-fill-mode:forwards;
  }

  i {
    color: grey;
  }

  h3 {
    color: black;
    margin-top: .7rem;
    font-size: 1.5rem;

    @include max-width($md) {
      font-size: 1.3rem;
      margin-top: .5rem;
    }
  }

  h4 {

  }

  p {
    color: black;
  }

  .btn {
    padding: .7rem 1rem;
    min-width: 200px;
    border-radius: 3px;
    font-size: 1rem;
    text-transform: none;
    background-color: $green;
    color: white !important;
    margin-top: .7rem;

    i {
      color: white;
    }

    &:hover {
      background-color: darken($green, 10%);
    }
  }
}