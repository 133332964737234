.home-categories-wrapper {
}

.home-categories-container {
	.intro {
		padding: .7rem;
		display: block;
		text-align: center;
		background: linear-gradient(
			273deg,
			rgba(233, 236, 239, 0) 0%,
			rgba(255, 255, 255, 1) 50%,
			rgba(255, 255, 255, 0) 96%
		);
		position: relative;
		margin: 3rem 0;
		box-shadow: 0 0 7px -2px rgba(0, 0, 0, 0.1);

		@include max-width(767px) {
			margin: 2rem 0 3rem 0;
		}

		h1,
		h2 {
			font-size: 2.5rem;
			font-weight: 600;
			position: relative;
			padding: 0;
			text-transform: uppercase;
			margin-bottom: 0;
			color: rgb(77, 77, 77);

			// @include themify($themes) {
			// 	color: darken(themed('colorSecondary'), 30%);
			// }

			@include max-width($lg) {
				font-size: 1.5rem;
				margin-bottom: 0;
				padding-bottom: 0.5rem;
			}
		}

		.sub {
			position: absolute;
			bottom: -2.0rem;
			right: 0;
			width: 100%;

			p {
				font-size: 1.1rem;
				font-weight: 400;
				text-transform: uppercase;
				display: inline-block;
				margin: 0;
				color: #9e9e9e;
				padding: 0 1rem;

				// @include themify($themes) {
				// 	background-color: themed('colorSecondary');
				// }

				@include max-width($md) {
					font-size: 1rem;
				}
			}
		}
	}
}

.category-items-container {
	display: flex;
	flex-wrap: wrap;
	padding-bottom: 2rem;

	&.centered {
		justify-content: center;
	}

	.category-item-container {
		margin-bottom: 1%;
		background-color: #fff;
		text-align: center;
		border-radius: 3px;
		width: 24%;
		margin-right: 1.33%;
		transition: 0.2s;
		box-shadow: 0 0 12px -2px rgb(0 0 0 / 31%);
		border-radius: 5px;

		&:nth-child(4n) {
			margin-right: 0;
		}

		&.all-categories {
			background-color: #f3f3f3;

			.inner {
				padding-top: 1rem;
				padding-bottom: 1rem;

				@include max-width($lg) {
					justify-content: space-evenly;
				}
			}
		}

		@include max-width($lg) {
			width: 33%;
			margin-right: 0.5%;

			&:nth-child(4n) {
				margin-right: 0.5%;
			}

			&:nth-child(3n) {
				margin-right: 0;
			}

			.inner {
				p {
					font-size: 0.9rem;
					text-align: left;
				}
			}
		}

		@include max-width($md) {
			width: 48%;
			margin-right: 4%;
			margin-bottom: 1rem;

			&:nth-child(4n) {
				margin-right: 4%;
			}

			&:nth-child(3n) {
				margin-right: 4%;
			}

			&:nth-child(2n) {
				margin-right: 0 !important;
			}

			.inner {
				p {
					text-align: center !important;
				}
			}
		}

		&:hover {
			border-color: #64afe4;

			@include themify($themes) {
				border-color: themed('colorSecondary');
			}

			.inner {
				p {
					color: darken($primary, 10%);

					@include themify($themes) {
						color: darken(themed('colorPrimary'), 10%);
					}
				}
			}
		}

		.inner {
			display: flex;
			align-items: center;
			height: 100%;
			padding: 0.5rem;

			&:hover {
				text-decoration: none;
			}

			@include max-width($xl) {
				flex-direction: column;
				padding: 0.7rem;

				img {
					margin-bottom: 0.5rem;
				}
			}

			.image-container {
				width: 120px;
				margin-right: 1rem;

				@include max-width($xl) {
					margin: 0;
				}
			}

			img {
				width: auto;
				height: 60px;
			}

			i {
				font-size: 2.5rem;
				color: #8fa1a5;
			}

			p {
				margin-bottom: 0;
				font-weight: 600;
				color: rgb(75, 75, 75);
				font-size: 1rem;
				text-align: left;
			}
		}
	}
}
