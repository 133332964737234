.category-elements-wrapper {
  display: flex;

  .category-elements {
    flex: 5;
  }

  .sidebar {
    flex: 1;
  }
}
.category-elements-container {
  // background-color: red;
}

.category-elements {
  background-color: #F3F3F3;

  h1 {
    border-radius: 3px;
    font-weight: 400;
    font-size: 1.6rem;
    color: #292929;
    margin-bottom: 0;
    
    @include max-width(767px) {
      font-size: 1.3rem;
    }
    
}

  .please-select {
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: .5rem;
    color: #5c5c5c;

    @include max-width(767px) {
      font-size: .9rem;
      margin-bottom: 0;
    }
  }

  .categories-grid-container {
    position: relative;

    .shortened {
      max-height: 1000px;
      overflow-y: hidden;
      filter: blur(2px);
      overflow: hidden;
    }
  }


  &.internal {
    .category-search {
      &.horizontal {
        @include max-width($md) {
          display: none;
        }
      }
    }
  }

  .alphabetical-selection-group {
    // background-color: red;
    margin-bottom: 1rem;

    .alphabetical-selection-title {
      margin-top: 1rem;

      h5 {
        font-weight: 600;
        font-size: .9rem;
      }
    }

    .header {
      // background-color: #fff;
      margin-bottom: 1rem;
      display: flex;
      flex-wrap: wrap;
      padding: .7rem;
      border: 3px solid #C8D3DF;
      border-radius: 3px;

      .letter {
        flex: 1;
        max-width: 20%;
        text-align: center;
        font-size: 1rem;
        font-weight: 600;
        padding: .2rem .2rem;
        border-radius: 3px;
        color: #213c64;
        background-color: rgba(#213c64, .05);
        margin-right: .1rem;

        @include max-width($md) {
          width: 7%;
          flex: auto;
          margin-bottom: 2px;
        }


        &:last-child {
          margin-right: 0;
        }

        &:hover {
          background-color: rgba(#213c64, .1);
        }
      }
    }

    .alphabetical-selection-wrapper {
      display: flex;
    }
  }

  .category-elements-group {

    &.letter-specific {
      margin-bottom: 1.5rem;
      padding: 0.5rem 0 2rem 0;
      border-bottom: 1px solid #8593a8; 
      
      .category-elements-wrapper {
        display: flex;

        .key-container {
          width: 20%;
          display: flex;
          justify-content: center;
          align-items: center;
          background: rgb(255,255,255);
          background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255, 255, 255, 0) 100%);
          padding: 1rem 0;

          @include max-width($md) {
            align-items: flex-start;
          }

          h3 {
            font-size: 6rem;
            font-weight: 600;
            color: #8593a8;

            @include max-width($md) {
              font-size: 2rem;
            }
          }
        }

        .elements-container {
          width: 80%;
          display: flex;
          flex-wrap: wrap;
          align-items: flex-start;
          
          @include max-width(767px) {
            padding: .5rem 0;
          }

          .category-element {
            flex: 1;
            // height: 50px;
            min-width: 40%;
            margin-right: 1%;

            &:nth-child(2n) {
              margin-right: 0;
            }

            @include max-width($md) {
              min-width: 60%; 
              margin-right: 0;

              &:nth-child(1n) {
                margin-right: 0;
              }
            }

            &:last-child {
              margin-right: 0;

              @include max-width(767px) {  
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }
    .category-elements-wrapper {
      display: flex;
      flex-wrap: wrap;
    }

    .category-elements-title {
      margin-top: 1rem;

      h5 {
        font-weight: 600;
      }
    }

    .category-element {
      width: 32.6%;
      margin-right: 1%;
      margin-bottom: 1%;
      background-color: #fff;
      padding: 1rem;
      // height: 100%;
      text-align: center;
      border-radius: 3px;
      box-shadow: 0 0 7px -2px rgba(0,0,0,0.1);
      transition: .2s;
      display: flex;
      align-items: center;

      &:nth-child(3n) {
        @include min-width(992px) {
          margin-right: 0;
        }
      }

      @include max-width(767px) {
        padding: 1rem .5rem;
      }

      &.image-only {
        padding: 0;


        a {
          width: 100%;
          overflow: hidden;
        }

        img {
          width: auto;
          width: 50%;
          display: block;
          margin: 0 auto;
        }

        h5 {
          display: none;
        }
      }

      a {
        display: flex;
        align-items: center;

        &:hover {
          text-decoration: none;
        }

        img {
          width: 3rem;
          margin-right: 1rem;
          margin-top: .3rem;
          margin-bottom: .3rem;

          @include max-width(767px) {
            width: 2rem;
            margin-right: .5rem;
          }
        }
      }

      @include max-width($lg) {
        &:nth-child(2n) {
          margin-right: 0;
        }

        width: 47.5%;
        margin-right: 5%;
        margin-bottom: 1rem;
      }

      h5 {
        margin-bottom: 0;
        text-transform: none;
        color: #31445f;
        font-weight: 600;
        font-size: 1rem;

        @include max-width(767px) {
          font-size: .85rem;
          text-align: left;
        }
      }

      &:hover {
        box-shadow: 0 0 7px -2px rgba(0, 0, 0, 0.4);

        @include min-width(768px) {
          cursor: pointer;
          transform: scale(1.02);

          h5 {
            color: $green;
          }
        }
      }
    }
  }

  .btn {
    &.view-all {
      width: 24.0%;
      background-color: #fff;
      padding: 1rem;
      box-shadow: 0 0 7px -2px rgba(0, 0, 0, 0.1);
      border-radius: 3px;
      color: $blue;

      @include max-width($lg) {
        width: 100%;
      }

      &:hover {
        background-color: $blue;
        color: white;
      }
    }
  }
}